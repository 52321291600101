var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modalShow),expression:"modalShow"}],attrs:{"id":"modal"}},[_c('div',{attrs:{"id":"overlay"}},[_c('div',{staticClass:"modal-content addedit"},[(_vm.errors.length)?_c('div',{staticClass:"errorList"},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('dl',[_c('dt',[_vm._v("略称")]),_c('dd',[_c('v-text-field',{staticClass:"input",attrs:{"name":"iptAbbreviation","id":"iptAbbreviation","outlined":"","dense":""},model:{value:(_vm.item.abbreviation),callback:function ($$v) {_vm.$set(_vm.item, "abbreviation", $$v)},expression:"item.abbreviation"}})],1),_c('dt',[_vm._v("会社名")]),_c('dd',[_c('v-text-field',{staticClass:"input",attrs:{"name":"iptCompany","id":"iptCompany","outlined":"","dense":""},model:{value:(_vm.item.company_name),callback:function ($$v) {_vm.$set(_vm.item, "company_name", $$v)},expression:"item.company_name"}})],1),_c('dt',[_vm._v("契約期間")]),_c('dd',[_c('v-container',{attrs:{"pt-0":""}},[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.item.contract_period_start),callback:function ($$v) {_vm.$set(_vm.item, "contract_period_start", $$v)},expression:"item.contract_period_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStart),callback:function ($$v) {_vm.dateStart=$$v},expression:"dateStart"}},[_c('v-date-picker',{attrs:{"locale":"jp-ja","day-format":function (date) { return new Date(date).getDate(); }},on:{"input":function($event){_vm.dateStart = false}},model:{value:(_vm.item.contract_period_start),callback:function ($$v) {_vm.$set(_vm.item, "contract_period_start", $$v)},expression:"item.contract_period_start"}})],1)],1),_c('v-col',{staticStyle:{"padding-left":"0.5em","padding-top":"1.4em"},attrs:{"cols":"1"}},[_vm._v(" ～ ")]),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.item.contract_period_end),callback:function ($$v) {_vm.$set(_vm.item, "contract_period_end", $$v)},expression:"item.contract_period_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEnd),callback:function ($$v) {_vm.dateEnd=$$v},expression:"dateEnd"}},[_c('v-date-picker',{attrs:{"locale":"jp-ja","day-format":function (date) { return new Date(date).getDate(); }},on:{"input":function($event){_vm.dateEnd = false}},model:{value:(_vm.item.contract_period_end),callback:function ($$v) {_vm.$set(_vm.item, "contract_period_end", $$v)},expression:"item.contract_period_end"}})],1)],1)],1)],1)],1),_c('dd',[_c('v-container',{attrs:{"pt-0":""}},[_c('v-row',[_c('v-col',[_c('div',[_vm._v("計画日数")]),_c('v-text-field',{attrs:{"name":"item.contract_days","id":"item.contract_days","outlined":"","dense":""},model:{value:(_vm.item.contract_days),callback:function ($$v) {_vm.$set(_vm.item, "contract_days", $$v)},expression:"item.contract_days"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_vm._v("   ")]),_c('v-col',[_c('div',[_vm._v("背景色")]),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"dense":"","outlined":"","background-color":"white"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"hide-mode-switch":"","flat":""},model:{value:(_vm.item.bg_color),callback:function ($$v) {_vm.$set(_vm.item, "bg_color", $$v)},expression:"item.bg_color"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.item.bg_color),callback:function ($$v) {_vm.$set(_vm.item, "bg_color", $$v)},expression:"item.bg_color"}})],1)],1)],1)],1)]),_c('div',{staticClass:"flex jcSB"},[_c('v-btn',{attrs:{"depressed":"","color":"success","width":"100"},on:{"click":_vm.submitAddEdit}},[_vm._v("OK")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","width":"100"},on:{"click":_vm.modalClose}},[_vm._v("CANCEL")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }