<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="showDetail"
        :class="{staff: pntPage == 'staff'}"
      >
        <span v-if="pntItem.kind == 1">{{ showDetailSetting }}</span>
        <span v-if="pntItem.kind == 2">{{ pntItem.task_abb }}</span>
      </span>
    </template>
    <div class="showTooltip">{{ showPlaceSetting }}</div>
  </v-tooltip>
</template>

<script>
export default {
  name: "TopDetail",
  props: {
    pntItem: {
      type: Object,
      required: false,
    },
    pntPage: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    showDetailSetting: function(){
      var ret = this.pntItem.abbreviation;
      if(this.pntItem.client_visit == 2){
        ret = ret.toLowerCase();
      }
      return ret;
    },
    showPlaceSetting: function(){
      var ret = "";

      if(this.pntItem.kind == 1){
        ret = this.pntItem.fullname;
        if(this.pntItem.client_visit == 2){
          ret = ret.toLowerCase();
        }
      } else if(this.pntItem.kind == 2){
        ret = this.pntItem.task_full;
      }
      return ret;
    },

  },
  filters: {
    chkClientVisit: function(str, client_visit){
      var ret = str;
      if(client_visit == 2){
        ret = ret.toUpperCase();
      }
      return ret;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.showTooltip{
  white-space: pre-wrap;
}
.showDetail{
  font-size: 75%;
  display: block;
  padding: 3px 0;
}
.showDetail.staff{
  font-size: 100% !important;
  padding: 0;
}
</style>
