<template>
  <div class="client" :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="クライアント別"></Header>
    
    <div id="contents">
      <div id="main" class="mb-10">
        <h2 class="title">クライアント別</h2>

        <v-card
          id="control"
          class="d-flex justify-space-between mb-4"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul class="flex jcFS pl-0">
              <li><v-select class="selectScail" v-model="selectedDisplayYear" :items="_getYears()"  @change="chgDate('selYear')" dense outlined></v-select></li>
              <li><v-select class="selectScail" v-model="selectedDisplayMonth" :items="MONTHS" @change="chgDate('selMonth')" dense outlined></v-select></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('toDay')">今日</v-btn></li>
            </ul>
            <ul class="flex jcFS pl-0">
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('lastMonth')">前月</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('lastWeek')">前週</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('dayBefore')">前日</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextDay')">翌日</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextWeek')">翌週</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextMonth')">翌月</v-btn></li> 
            </ul>
          </v-card>
          <v-card
            flat
            tile
          >
            <ul class="flex jcFE">
              <li v-if="$store.state.user.authority!=3"><router-link to="/Top"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">全体</v-btn></router-link></li>
              <li><router-link to="/Staff"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">スタッフ別</v-btn></router-link></li>
              <!-- <li v-if="$store.state.user.authority!=3"><BtnEdit @edit="edit"/></li>
              <li v-if="$store.state.user.authority!=3"><BtnAdd @add="add"/></li>
              <li v-if="$store.state.user.authority!=3"><BtnDel @del="del"/></li> -->
              <li><BtnAdd @add="add" :disabled="(isReadOnly || (this.addEditMode == 1) || ($store.state.locked == 1))"/></li>
              <li><BtnEdit @edit="edit" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
              <li><BtnDel @del="del" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
            </ul>
          </v-card>
        </v-card>

        <div id="field">
          <v-card
            id="control"
            class="d-flex justify-space-between"
            flat
            tile
          >
            <v-card
              flat
              tile
            >
              <v-row>
                <v-col cols="auto">
                  <v-select
                    v-model="selClient"
                    :items="optClient"
                    :item-text="item => item.abbreviation +'（'+ item.company_name +'）'"
                    item-value="id"
                    class="select-input-none selClient"
                    dense
                    outlined
                    hide-details="false"
                    @change="chgClient"
                  >
                  </v-select>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <div>
                    計画日数&nbsp;&nbsp;{{ contract_days }} 日 ( {{ start_days }} ～ {{ end_days }} )
                    アサイン日数&nbsp;&nbsp;{{ assign_days }}日
                  </div>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              flat
              tile
            >
              <v-row>
                <v-col>
                    <v-select
                      v-if="$store.state.user.authority!=3"
                      v-model="selectedRegion"
                      :items="optRegion"
                      item-value="id"
                      class="selRegion"
                      dense
                      outlined
                      hide-details="false"
                      @change="chgRegion"
                    >
                    </v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          <div class="fieldList">
            <table class="dateList">
              <colgroup>
                <col>
                <col>
                <col>
                <col v-for="staff in theadStaffs" :key="staff.id">
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th v-for="staff in theadStaffs" :key="staff.id">
                    {{ staff.name }}
                  </th>
                  <th>作業内容</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(item, itemIdx) in items" :key="item.index" 
                  :class="item.isHol === 0 ? 'sun' : item.isHol === 6 ? 'sat' : item.isHol === 7 ? 'hol' : ''" 
                >
                  <th>{{ item.targetDate | showYearMonth(itemIdx) }}</th>
                  <th>{{ item.targetDate | showDay }}</th>
                  <th>{{ item.targetDate | showDayOfWeek }}</th>
                  <td
                    v-for="(field, fieldIdx) in item.staff" :key="field.index"
                    :id="'td_'+ itemIdx +'_fields_'+ fieldIdx"
                    :data-item_idx="itemIdx"
                    :data-field_idx="fieldIdx"
                    :data-date="item.date"
                    :class="{tgtSelected: field.isSelected}"
                    @mousedown="onTgtClick(item, field)" 
                  >
                    <div 
                      v-if="field.abbreviation != ''" 
                      :class="{handle: isHandle, tentative_regist: (field.tentative_regist == 1)}"
                    >
                      <ClientDetail :pntItem="field"></ClientDetail>
                    </div>
                  </td>
                  <td>{{ item.work_text }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div><!-- /#field -->
        
        <ContractOver ref="ContractOver"></ContractOver>

      </div>

       <!-- モーダルウィンドウ  -->
      <ModalAddEdit ref="ModalAddEdit" pntMethodName="pntMethod" @pntMethod="GetClient"></ModalAddEdit>

      <!-- ロック時の編集不可ダイアログ -->
      <v-snackbar
        v-model="lockMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        ロック中のため、編集できません。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="lockMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- 編集失敗ダイアログ -->
      <v-snackbar
        v-model="errMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        編集に失敗しました。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="errMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalAddEdit from '@/components/ModalAddEdit.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
import ClientDetail from '@/components/ClientDetail.vue';
import ContractOver from '@/components/ContractOver.vue';
import axios from 'axios';

export default {
  name: 'Client',
  components:{
    Header,
    ModalAddEdit,
    ClientDetail,
    ContractOver,
    BtnAdd,
    BtnEdit,
    BtnDel,
  },

  // サインインチェック
  beforeMount() {
    // if(this.$route.query.auth !== 'authenticated'){
    //   this.$route.push({ name: 'Login', query: { next: 'Client' } });
    // }
  },
  
  data() {
    return {
      start_days: null,
      end_days: null,
      contract_days: null,
      assign_days: null,
      items: [],
      selClient: 0,
      optClient: [],
      
      // 所属プルダウン用
      selectedRegion: 0,
      optRegion: [
        { id: 0, text: "全ての所属" },
        { id: 1, text: "京都" },
        { id: 2, text: "東京" },
        { id: 3, text: "福岡" },
      ],

      // 表示日数
      setDays: 31,
      // 始まりの年月
      startDate: "",
      startYM: "",
      // クリア設定
      clear: {
        "id": "",
        "name": "",
        "time": "",
        "dragGroup": "",
      },

      sendAddEdit: {
        date: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },
      // スタッフ名表示用
      theadStaffs: [],
      isHandle: true,
      // 0：追加, 1：編集
      addEditMode: 0,
      snackbar: false,
      // 西暦・月選択用
      selectedDisplayYear: 2000,
      selectedDisplayMonth: 1,
      // ボタン有効無効制御用
      isReadOnly:true,

      // ロック時の編集不可ダイアログ
      lockMsgBar: false,
      // 編集失敗ダイアログ
      errMsgBar: false,
    }
  },
  async mounted() {
    // this.items = client;
    // console.log(this.items);

    // 年設定
    var date = new Date();
    // this.startDate = this._formatDate(date);
    date.setDate(1);
    this.startDate = this.$store.state.date ? this.$store.state.date : this._formatDate(date, "yyyymm") + "-01";
    this.dateYM = this._formatDate(date, "yyyymm");
    this.selectedDisplayYear = date.getFullYear();
    this.selectedDisplayMonth = date.getMonth() + 1;

    console.log(this.startDate);
    // console.log(this.dateYM);

    // スタッフプルダウン生成用
    // データ取得
    await axios.post(this.URL +'management/GetClient', {
        token: this.$store.state.token,
      },
      { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
    )
    .then(function(res){
      // ログインチェック
      this._loginChk(res.data, this.$store.state.token);

      //デバッグ用にconsoleに出力
      console.log(res);

      this.optClient = res.data.data;
      console.log(this.optClient);
    }.bind(this))
    .catch(function(error){
        console.log(error);
    });

    // 画面遷移した際にクライアントが選択されていれば一覧表示
    if(this.$store.state.client){
      this.GetClient();
    } else {
      this.GetLocked();
    }
  },
  beforeDestroy: function(){
    // イベントをクリア
    document.removeEventListener('keydown', this.onKeyDown)
  },
  filters: {
    // 年月表示用
    showYearMonth: function(val, index){
      var ret = "";
      var dateSplit = val.split("-");

      // 日で判定 ※indexの最初は必ず表示
      if(dateSplit[2] != 1 && index != 0){
        ret =  "";
      } else {
        ret = dateSplit[0] + "年" + Number(dateSplit[1]) + "月";
      }

      return ret;
    },
    // 日を表示
    showDay: function(val){
      var dateSplit = val.split("-");
      return Number(dateSplit[2]) + "日";
    },
    // 曜日表示
    showDayOfWeek: function(val){
      // Dateオブジェクトは曜日情報を0から6の数値で保持しているため、翻訳する
      var dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ] ;
      // 指定日付で初期化したDateオブジェクトのインスタンスを生成する
      var date = new Date(val);

      return dayOfWeekStrJP[date.getDay()];
    }
  },
  methods: {
    GetLocked: async function(){
      // ロック判定
      axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    // 一覧取得
    GetClient: async function(){
      console.log("GetClient");

      // ロック判定
      this.GetLocked();

      this.selClient = this.$store.state.client;
      this.$store.dispatch("selStaffClient", {
        staff: "",
        client: this.$store.state.client,
        date: "",
      });

      // 契約期間オーバー情報の取得
      this.$refs.ContractOver.GetContractOver();
      
      await axios.post(this.URL +'GetClient', {
          date: this.startDate,
          dateYM: this.dateYM,
          cstm_id: this.selClient,
          token: this.$store.state.token,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        // this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        console.log(res);

        // 所属が全選択でない場合
        if(this.selectedRegion != 0){
          // 選択している所属のデータ以外を除外
          let selRegion = this.selectedRegion
          res.data.dayList.forEach(function(day){
            if(day.staff != null){
              let tmpStaff = []
              for(var i=0; i<day.staff.length; i++){
                if(day.staff[i].region_id == selRegion){
                  tmpStaff.push(day.staff[i])
                }
              }
              day.staff.splice(0,day.staff.length)
              day.staff = tmpStaff
            }
          })
        }
        this.items = res.data.dayList;

        this.start_days = res.data.start_days.substr(0, 10);
        this.end_days = res.data.end_days.substr(0, 10);
        this.contract_days = res.data.contract_days;
        this.assign_days = res.data.assign_days;
        // this.optClient = res.data.client;

        if(!this._ChkAuthority()){
          this.isHandle = false;
        }

        console.log(this.items);

        // スタッフ名表示用
        this.theadStaffs = this.items[0].staff;

        var self = this;
        this.items.forEach(function(elm){
          if(elm.staff){
            for(var i=0; i<elm.staff.length; i++){
              // dragGroupに代入（ドラッグできる個所を設定）
              elm.staff[i].dragGroup = elm.staff[i].abbreviation != "" ? "fixed" : "noFixed";
              self.$set(elm.staff[i], 'isSelected', false);
              if((self.sendAddEdit.date == elm.targetDate) && (self.sendAddEdit.user_id == elm.staff[i].id)){
                // DBデータが更新されている場合があるため入れ直し
                let data = elm.staff[i];
                self.sendAddEdit.assign_time = data.time;
                self.sendAddEdit.kind = data.kind;
                self.sendAddEdit.cstm_id = data.cstm_id;
                self.sendAddEdit.location = data.client_visit;
                self.sendAddEdit.temp_flg = data.tentative_regist;
                self.sendAddEdit.work_text = elm.work_text;
                self.sendAddEdit.assign_cstm_id = data.assign_cstm_id;
                if(self.isReadOnly == false){
                  self.$set(elm.staff[i], 'isSelected', true);
                }
              }
            }
          }
        });
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },

    chgDate: function(chgMode){
      var date = this.startDate;
      var dt = new Date(date);

      console.log(this.startDate);

      if(chgMode == "lastMonth"){
        console.log("前月");
        dt.setDate(dt.getDate() -30);
      } else if(chgMode == "lastWeek"){
        console.log("前週");
        dt.setDate(dt.getDate() -7);
      } else if(chgMode == "dayBefore"){
        console.log("前日");
        dt.setDate(dt.getDate() -1);
      } else if(chgMode == "nextDay"){
        console.log("翌日");
        dt.setDate(dt.getDate() +1);
      } else if(chgMode == "nextWeek"){
        console.log("翌週");
        dt.setDate(dt.getDate() +7);
      } else if(chgMode == "nextMonth"){
        console.log("翌月");
        dt.setDate(dt.getDate() +30);
      } else if(chgMode == "toDay"){
        console.log("今日");
        dt = new Date();
        dt.setDate(1);
      } else if(chgMode == "selYear"){
        console.log("年選択");
        dt.setFullYear(this.selectedDisplayYear);
        dt.setDate(1);
      } else if(chgMode == "selMonth"){
        console.log("月選択");
        dt.setMonth(this.selectedDisplayMonth - 1);
      } else {
        console.log("chgDateError");
      }
      this.selectedDisplayYear = dt.getFullYear();
      this.selectedDisplayMonth = dt.getMonth() + 1;
      this.startDate = this._formatDate(dt);
      console.log("change_startDate:"+ this.startDate);
      this.GetClient();
    },
    chgClient: function(){
      this.$store.state.client = this.selClient;
      this.GetClient();
      // ボタン無効
      this.isReadOnly = true
    },
    // 所属絞り込み変更
    chgRegion: async function(){
      await this.GetClient();
      // 更新後、選択していたスタッフが存在するか確認
      let find = false
      for(let i = 0;i< this.theadStaffs.length;i++){
        if(this.theadStaffs[i].id == this.sendAddEdit.user_id){
          find = true
          break
        }
      }
      // 選択していたスタッフが存在しない場合
      if(find == false){
        // ボタン無効
        this.isReadOnly = true
      }
    },
    add: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 1){ return false; }
      
      console.log("add");
      this.$set(this.$refs.ModalAddEdit, "mode", "add");
      this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
      this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
      this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
      this.$set(this.$refs.ModalAddEdit.item, "no", 0);
      this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", this.sendAddEdit.temp_flg);
      this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
      this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
      this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
      this.$set(this.$refs.ModalAddEdit.item, "kind", 1);
      this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", 0);
      this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
      // エラーをリセット
      this.$set(this.$refs.ModalAddEdit.item, "errors", []);
      // this.$refs.ModalAddEdit.$refs.form.resetValidation();
      this.$refs.ModalAddEdit.modalShow = true;
    },
    edit: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 0){ return false; }

      console.log("edit");
      if(this.sendAddEdit.date){
        console.log("sendAddEdit.cstm_id:"+ this.sendAddEdit.cstm_id);

        // 実行
        let sendTempFlg = false
        if (this.sendAddEdit.temp_flg == 1) sendTempFlg = true
        this.$set(this.$refs.ModalAddEdit, "mode", "edit");
        this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
        this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
        this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
        this.$set(this.$refs.ModalAddEdit.item, "no", this.sendAddEdit.koma);
        this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
        this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", sendTempFlg);
        this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
        this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
        this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
        this.$set(this.$refs.ModalAddEdit.item, "kind", 1);
        this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
        this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", 0);
        this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
        // エラーをリセット
        this.$set(this.$refs.ModalAddEdit.item, "errors", []);
        // モーダルウィンドウ表示
        this.$refs.ModalAddEdit.modalShow = true;
      }
    },
    del: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 0){ return false; }

      console.log("del");
      axios
        .post(this.URL +'DelPlan', {
            mode: "del",
            assign_cstm_id: this.sendAddEdit.assign_cstm_id,
            task_assign_id: 0,
            token: this.$store.state.token,
          },
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // 成功処理
            console.log("OK");
            // 再読み込み
            this.GetClient();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログ表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
          }
        }
      );
    },
    // スタッフ、リーダー編集OKか
    chkStaffLeaderAuthority: function(user_id){
      let ret = true
      // 権限がリーダーの場合
      if (this.$store.state.user.authority==2)
      {
        // 対象の所属ID取得
        let targetRegionId = 0
        for(let i = 0; i< this.items.length;i++){
          for(let j = 0;j< this.items[i].staff.length;j++)
          {
            if(user_id == this.items[i].staff[j].id){
              targetRegionId = this.items[i].staff[j].region_id
              break
            }
          }
        }
        // ログインユーザの所属と対象の所属が不一致の場合Falseを返す
        if(this.$store.state.user.region_id != targetRegionId){
          ret = false
        }
      }
      // 権限がスタッフの場合
      if ((this.$store.state.user.authority==3) && (this.$store.state.user.id != user_id))
      {
        ret = false
      }
      return ret;
    },
    // ドラッグ系
    // クリック時
    onTgtClick: function(item, detail){
      // if(!this._ChkAuthority()){ return false; }

      console.log("ontgtClick");
      console.log(item);
      console.log(detail);

      var self = this;
      this.items.map(function(item){
        item.staff.map(function(elm){
          self.$set(elm, 'isSelected', false);
        });
      });
      this.$set(detail, 'isSelected', true);

      this.addEditMode = detail.dragGroup == "noFixed" ? 0 : 1;

      this.sendAddEdit.date = item.targetDate;
      this.sendAddEdit.assign_time = detail.time;
      this.sendAddEdit.user_id = detail.id;
      this.sendAddEdit.cstm_id = this.selClient;
      this.sendAddEdit.location = detail.client_visit;
      this.sendAddEdit.temp_flg = detail.tentative_regist;
      this.sendAddEdit.koma = detail.koma;
      this.sendAddEdit.free_task = detail.task_full;
      this.sendAddEdit.work_text = item.work_text;

      // this.sendAddEdit.kind = detail.kind;

      this.sendAddEdit.assign_cstm_id = detail.assign_cstm_id;
      this.sendAddEdit.task_assign_id = detail.task_assign_id;
      
      console.log("sendAddEdit");
      console.log(this.sendAddEdit);

      this.$store.dispatch("selStaffClient", {
        staff: detail.id,
        client: this.selClient,
        date: item.targetDate,
      });
      // ボタン有効無効状態更新
      this.chgIsReadOnly(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id))
    },
    chgIsReadOnly: function(disabled) {
      this.isReadOnly = disabled;
    },
  },
  // components: {
  //   Login
  // }
}
</script>

<style scoped>
  #control ul > li{
    padding: 2px 4px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }

  #control > div:nth-child(2) {
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 7px;
  }
  #control .boldfont
  {
    font-weight: 1000;
  }
  #field div.fieldList{
    overflow: scroll;
    height: 70vh;
    margin-top: 0.5em;
  }
  #field table{
    width: 100%;
    /* min-width: 1480px; */
    /* min-width: 100%; */
    table-layout: fixed;
    box-sizing: border-box;
    border-collapse: separate;
    border-top: 1px solid #ccc;
    /* border-left: 1px solid #ccc; */
  }
  #field table thead th{
    background-color: #BBDEFB !important;
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
  }

  #field table colgroup col:nth-child(1){ width: 6.0em; }
  #field table colgroup col:nth-child(2){ width: 3.0em; }
  #field table colgroup col:nth-child(3){ width: 2.0em; }
  #field table colgroup col:nth-child(n+4){ width: 8.0em; }
  #field table colgroup col:last-child{ width: 40.0em; }
  #field table th{
    position: sticky;
    top: 0;
    /* background-color: black;
    color: white; */
  }
  #field table th,
  #field table td
  {
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    /* border: 1px solid #ccc; */
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 3px;
    height: 37px;
  }
  #field table thead tr th:nth-child(1),
  #field table tbody tr th:nth-child(1)
  {
    border-left: 1px solid #ccc;
  }

  #field table .sun,
  #field table .sat,
  #field table .hol,
  #field table .sun th,
  #field table .sat th,
  #field table .hol th,
  #field table .sun td,
  #field table .sat td,
  #field table .hol td
  {
    background-color: #eee !important;
  }
  #field table .sun th,
  #field table .hol th{
    color: #f00;
  }
  #field table .sat th{
    color: #00f;
  }
  #field table tbody tr th:nth-child(1){
    color: #000 !important;
  }


  #field table thead tr:nth-child(1) th:nth-child(1),
  #field table thead tr:nth-child(1) th:nth-child(2),
  #field table thead tr:nth-child(1) th:nth-child(3)
  {
    position: sticky;
    left: 0;
    z-index: 4;
  }
  #field table thead tr:nth-child(1) th:nth-child(2){
    left: 6.0em;
  }
  #field table thead tr:nth-child(1) th:nth-child(3){
    left: 9.0em;
  }

  /* #field table tbody tr:nth-child(1) th:nth-child(1){
    z-index: 2;
  } */

  #field table tbody tr th{
    background: #fff;
  }
  #field table tbody tr th:nth-child(1),
  #field table tbody tr th:nth-child(2),
  #field table tbody tr th:nth-child(3)
  {
    position: sticky;
    left: 0;
    z-index: 3;
  }
  #field table tbody tr th:nth-child(2){
    left: 6.0em;
  }
  #field table tbody tr th:nth-child(3){
    left: 9.0em;
  }




  #field .handle:hover{
    cursor: grab;
  }
  #field .handle:active{
    cursor: grabbing;
  }
  #field .sortable-chosen {
    background-color: #4caf50 !important;
  }

  .tentative_regist{
    color: #00f;
  }
  .selClient{
    width: 500px;
  }
  .selRegion{
    width: 200px;
  }
</style>
