import 'material-design-icons-iconfont/dist/material-design-icons.css'; // css-loaderを使用していることを確認してください
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
      themes: {
        light: {
          primary: "#0ab6ff",
          makeBlue:"0000ff",
          editcolor:"3f85ff",
          addcolor:"2ad530",
          deletecolor:"ff876a",

        },
        dark: {
          primary: "#0ab6ff",
          makeBlue:"0000ff",
          editcolor:"3f85ff",
          addcolor:"2ad530",
          deletecolor:"ff876a",
        },
      },
    },
});