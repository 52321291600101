<template>
  <div class="client" :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="ユーザ管理画面"></Header>
    
    <div id="contents">
      <div id="main">
        <h2 class="title">ユーザ管理画面</h2>
        <v-card
          id="control"
          class="d-flex justify-end"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul>
              <li><BtnAdd @add="add"/></li>
            </ul>
          </v-card>
        </v-card>

        <div id="field">
          <div>
            <table class="dataList">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
              </colgroup>
              <thead>
                <tr class="color">
                  <th>&nbsp;</th>
                  <th>ユーザ名</th>
                  <th>権限</th>
                  <th>所属</th>
                  <th colspan="3">チーム</th>
                </tr>
              </thead>
              <draggable v-model="items" tag="tbody" :options="{ handle: '.handle', animation: 150 }" @end="onDragEnd">
                <tr v-for="item, index in items" :key="item.d_id">
                  <td><v-icon class="handle">mdi-menu</v-icon></td>
                  <td class="tL">{{ item.d_name }}</td>
                  <td class="tL">
                    <span v-if="item.d_authority == 1">マネージャー</span>
                    <span v-else-if="item.d_authority == 2">リーダー</span>
                    <span v-else-if="item.d_authority == 3">スタッフ</span>
                    <span v-else-if="item.d_authority == 9">管理者</span>
                  </td>
                  <td class="tL">
                    <span v-if="item.d_region_id == 1">京都</span>
                    <span v-else-if="item.d_region_id == 2">東京</span>
                    <span v-else-if="item.d_region_id == 3">福岡</span>
                  </td>
                  <td><BtnEdit @edit="edit(item)"/></td>
                  <td><BtnDel @del="del(item, index)"/></td>
                </tr>
              </draggable>
            </table>
          </div>
        </div><!-- /#field -->
      </div>

       <!-- モーダルウィンドウ  -->
      <ModalUserAddEdit ref="ModalUserAddEdit" :pntItems="items" @GetUser="GetUser"></ModalUserAddEdit>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalUserAddEdit from '@/components/management/ModalUserAddEdit.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
import draggable from 'vuedraggable';
import axios from 'axios';

export default {
  name: 'Client',
  components:{
    Header,
    ModalUserAddEdit,
    BtnAdd,
    BtnEdit,
    BtnDel,
    draggable,
  },

  // サインインチェック
  beforeMount() {
    // if(this.$route.query.auth !== 'authenticated'){
    //   this.$route.push({ name: 'Login', query: { next: 'Client' } });
    // }
  },
  
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.GetUser();
  },
  methods: {
    GetUser: function(){
      // ロック判定
      axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });

      // this.items = user;
      // データ取得
      console.log("management/GetUser");
      console.log(this.URL);
      console.log(this.$store.state.token);
      axios.post(this.URL +'management/GetUser', 
        { token: this.$store.state.token },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        // this._loginChk(res.data, this.$store.state.token);
        
        //デバッグ用にconsoleに出力
        console.log(res);
        this.items = res.data.data;
        // console.log(this.items);
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    add: function(){
      console.log("add");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_id', null);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_name', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_login_id', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_chk_password', true);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_password', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_authority', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_position', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_team', []);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_region_id', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'm_id', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'm_text', "");

      this.$set(this.$refs.ModalUserAddEdit, 'tmpLoginId', "");
      this.$set(this.$refs.ModalUserAddEdit, 'isChkPassword', false);
      this.$set(this.$refs.ModalUserAddEdit, 'iptAuthority', "");
      // エラーをリセット
      this.$set(this.$refs.ModalUserAddEdit, 'errors', []);
      this.$refs.ModalUserAddEdit.$refs.form.resetValidation();
      // モーダルウィンドウ表示
      this.$refs.ModalUserAddEdit.modalShow = true;
    },
    edit: function(item){
      console.log("edit");
      console.log(item);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_id', item.d_id);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_name', item.d_name);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_login_id', item.d_user_id);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_chk_password', false);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_password', item.d_password);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_authority', item.d_authority);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_position', item.d_position);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_team', item.d_team);
      this.$set(this.$refs.ModalUserAddEdit.item, 'd_region_id', item.d_region_id);
      this.$set(this.$refs.ModalUserAddEdit.item, 'm_id', "");
      this.$set(this.$refs.ModalUserAddEdit.item, 'm_text', "");

      this.$set(this.$refs.ModalUserAddEdit, 'tmpLoginId', item.d_user_id);
      this.$set(this.$refs.ModalUserAddEdit, 'isChkPassword', true);
      // this.$set(this.$refs.ModalUserAddEdit, 'iptAuthority', {val: item.d_authority, text: item.m_text});
      // エラーをリセット
      this.$set(this.$refs.ModalUserAddEdit, 'errors', []);
      this.$refs.ModalUserAddEdit.$refs.form.resetValidation();
      // モーダルウィンドウ表示
      this.$refs.ModalUserAddEdit.modalShow = true;
    },
    del: function(item, index){
      console.log("del");
      console.log(index);
      if(window.confirm("削除しますか？")){
        axios
          .post(this.URL +'management/EditUser', {
              mode: "del",
              userId: this.$store.state.user.id,
              item: item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              this.items.splice(index, 1);
            } else {
              // エラー処理
            }
          }
        );
      }
    },
    // ドラッグ並び替え
    onDragEnd: function($event){
      // console.log(this.items);
      console.log($event);

      // ドラッグ先が同じ場合は処理しない
      if($event.newIndex != $event.oldIndex){
        // 入れ替えの配列の最初と最後を設定
        var idxStart = null;
        var idxEnd = null;
        if($event.newIndex > $event.oldIndex){
          idxStart = this.items[$event.newIndex].d_display_order;
          idxEnd = this.items[$event.newIndex-1].d_display_order;
        } else {
          idxStart = this.items[$event.newIndex].d_display_order;
          idxEnd = this.items[$event.newIndex+1].d_display_order;
        }

        // console.log("Drag");
        // console.log(this.items);
        // console.log("Start:"+ idxStart);
        // console.log("End:"+ idxEnd);

        // 送るデータを生成
        var sendData = [];
        for(var i=idxStart; i<=idxEnd; i++){
          sendData.push(this.items[i]);
        }
        // console.log(sendData);
        axios
          .post(this.URL +'management/EditUser', {
              mode: "change_display",
              userId: this.$store.state.user.id,
              start: idxStart,
              end: idxEnd,
              items: sendData,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // this.items.splice(index, 1);
              axios.post(this.URL +'management/GetUser', 
                this.$store.state.token,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
              )
              .then(function(res){
                // ログインチェック
                // this._loginChk(res.data, this.$store.state.token);
                
                //デバッグ用にconsoleに出力
                console.log(res);
                for(var i=0; i<res.data.data.length; i++){
                  this.$set(this.items[i], 'd_display_order', res.data.data[i].d_display_order);
                }
                // console.log(this.items);
              }.bind(this))
              .catch(function(error){
                  console.log(error);
              });
            } else {
              // エラー処理
            }
          }
        );
      }

      // console.log(idxStart, idxEnd);
    }
  },
  // components: {
  //   Login
  // }
}
</script>

<style scoped>
  #main{
    width: 600px;
  }
  #control ul > li{
    padding: 2px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }

  #field table.dataList{
    width: auto;
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
    margin-top: 0.5em;

  }
  #field table.dataList colgroup col:nth-child(1){ width: 3.0em; }
  #field table.dataList colgroup col:nth-child(2){ width: 10.0em; }
  #field table.dataList colgroup col:nth-child(3){ width: 10.0em; }
  #field table.dataList colgroup col:nth-child(4){ width: 7.0em; }
  #field table.dataList th{
    /* position: sticky;
    top: 0;
    background-color: black;
    color: white; */
  }
  #field table th,
  #field table td
  {
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #ccc;
    padding: 3px 10px;
  }
  #field table thead th{
    font-weight: 900 !important;
  }
  #field table tbody tr > td:nth-child(4){ max-width: 1px; }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  #field .handle:hover{
    cursor: grab;
  }
  #field .handle:active{
    cursor: grabbing;
  }
  #field .sortable-chosen {
    background-color: #4caf50;
  }
</style>
