<template>
  <div class="client" :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="クライアント管理画面"></Header>
    
    <div id="contents">
      <div id="main">
        <h2 class="title">クライアント管理画面</h2>
        <v-card
          id="control"
          class="d-flex justify-space-between"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul class="flex jcFS aiC pl-0 pagination">
              <li><v-btn depressed color="primary white--text" @click.prevent="onPrev">&lt;</v-btn></li>
              <li class="px-2">{{ page }}/{{ totalPage }}</li>
              <li><v-btn depressed color="primary white--text" @click.prevent="onNext">&gt;</v-btn></li>
            </ul>
          </v-card>
          <v-card
            flat
            tile
          >
            <ul class="flex jcFE">
              <li><BtnAdd @add="add"/></li>
            </ul>
          </v-card>
        </v-card>

        <div id="field">
          <div>
            <table class="dataList">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
              </colgroup>
              <thead>
                <tr class="color">
                  <th>略称</th>
                  <th>会社名</th>
                  <th colspan="3">契約期間</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item, index in filterItems" :key="index">
                  <td>{{ item.abbreviation }}</td>
                  <td class="tL">{{ item.company_name }}</td>
                  <td class="tL">{{ item.contract_period_start }} ～ {{ item.contract_period_end }}（{{ item.contract_days }}日 <span class="bgColor" :style="{backgroundColor: item.bg_color}"></span>）</td>
                  <td><BtnEdit @edit="edit(item)"/></td>
                  <td><BtnDel @del="del(item)"/></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div><!-- /#field -->
      </div>

       <!-- モーダルウィンドウ  -->
      <ModalAddEdit ref="ModalAddEdit" :pntItems="items" @GetClient="GetClient"></ModalAddEdit>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalAddEdit from '@/components/management/ModalAddEdit.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
import axios from 'axios';

export default {
  name: 'Client',
  components:{
    Header,
    ModalAddEdit,
    BtnAdd,
    BtnEdit,
    BtnDel,
  },

  // サインインチェック
  beforeMount() {
    // if(this.$route.query.auth !== 'authenticated'){
    //   this.$route.push({ name: 'Login', query: { next: 'Client' } });
    // }
  },
  
  data() {
    return {
      items: [],
      // ページング
      page: 1,
      perPage: 10,  // 1ページ毎の表示件数
      totalPage: 1, // 総ページ数
      // totalPage: Math.ceil(this.items.length / this.perPage), // 総ページ数
      // count: 0, // データの総数
      sendData: {},
    }
  },
  mounted() {
    this.GetClient();
  },
  computed: {
    filterItems(){
      var sort = this.items.slice().sort(function(a, b) {
        if (a.contract_period_start < b.contract_period_start) {
          return 1;
        } else {
          return -1;
        }
      });

      // return this.items;
      return sort.filter(
        (item, i) =>
          i >= (this.page - 1) * this.perPage &&
          i < this.page * this.perPage
      );
      // this.items.slice((this.page - 1) * this.perPage, this.page * this.perPage);
    }
  },
  methods: {
    GetClient: function(){
      // ロック判定
      axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });

      // データ取得
      axios.post(this.URL +'management/GetClient', 
        { token: this.$store.state.token },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        console.log(res);
        this.items = res.data.data;
        this.totalPage = Math.ceil(this.items.length / this.perPage); // 総ページ数
        // console.log(this.items);
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    add: function(){
      console.log("add");

      this.$set(this.$refs.ModalAddEdit.item, 'id', null);
      this.$set(this.$refs.ModalAddEdit.item, 'abbreviation', '');
      this.$set(this.$refs.ModalAddEdit.item, 'company_name', '');
      this.$set(this.$refs.ModalAddEdit.item, 'contract_period_start', '');
      this.$set(this.$refs.ModalAddEdit.item, 'contract_period_end', '');
      this.$set(this.$refs.ModalAddEdit.item, 'contract_days', '');
      this.$set(this.$refs.ModalAddEdit.item, 'bg_color', '#ffffff'); // 透明度を表示させないため設定

      // this.$refs.ModalAddEdit.item.id = null;
      // this.$refs.ModalAddEdit.item.abbreviation = "";
      // this.$refs.ModalAddEdit.item.company = "";
      // this.$refs.ModalAddEdit.item.contractPeriod.start = "";
      // this.$refs.ModalAddEdit.item.contractPeriod.end = "";
      // this.$refs.ModalAddEdit.item.contractPeriod.severalDays = "";
      // this.$refs.ModalAddEdit.item.bgColor = "";
      this.$refs.ModalAddEdit.modalShow = true;
    },
    edit: function(item){
      console.log("edit");
      // console.log(item);
      this.$set(this.$refs.ModalAddEdit.item, 'id', item.id);
      this.$set(this.$refs.ModalAddEdit.item, 'abbreviation', item.abbreviation);
      this.$set(this.$refs.ModalAddEdit.item, 'company_name', item.company_name);
      this.$set(this.$refs.ModalAddEdit.item, 'contract_period_start', item.contract_period_start);
      this.$set(this.$refs.ModalAddEdit.item, 'contract_period_end', item.contract_period_end);
      this.$set(this.$refs.ModalAddEdit.item, 'contract_days', item.contract_days);
      this.$set(this.$refs.ModalAddEdit.item, 'bg_color', item.bg_color);

      // this.$refs.ModalAddEdit.item.id = item.id;
      // this.$refs.ModalAddEdit.item.abbreviation = item.abbreviation;
      // this.$refs.ModalAddEdit.item.company = item.company;
      // this.$refs.ModalAddEdit.item.contractPeriod.start = item.contractPeriod.start;
      // this.$refs.ModalAddEdit.item.contractPeriod.end = item.contractPeriod.end;
      // this.$refs.ModalAddEdit.item.contractPeriod.severalDays = item.contractPeriod.severalDays;
      // this.$refs.ModalAddEdit.item.bgColor = item.bgColor;
      this.$refs.ModalAddEdit.modalShow = true;
    },
    del: function(item){
      console.log("del");
      // console.log(item);
      // console.log(index);
      // console.log("token:"+ this.$store.state.token);
      if(window.confirm("削除しますか？")){
        axios
          .post(this.URL +'management/EditClient', {
              mode: "del",
              userId: this.$store.state.user.id,
              item: item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              this.GetClient();
              // this.items.splice(index, 1);
            } else {
              // エラー処理
            }
          }
        );
      }
    },
    // ページング
    onPrev: function(){
      // console.log("onPrev");
      if(this.page > 1){ this.page--; }
    },
    onNext: function(){
      // console.log("onNext");
      if(this.page < this.totalPage){ this.page++; }
    }
  },
  // components: {
  //   Login
  // }
}
</script>

<style scoped>
  #main{
    width: 900px !important;
  }
  #control ul > li{
    padding: 2px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }

  #field table.dataList{
    width: auto;
    min-width: 100%;
    /* table.dataList-layout: fixed; */
    border-collapse: collapse;
    box-sizing: border-box;
    margin-top: 0.5em;
  }
  #field table.dataList colgroup col:nth-child(1){ width: 4.0em; }
  #field table.dataList colgroup col:nth-child(2){ width: 18.5em; }
  /* #field table.dataList colgroup col:nth-child(3){ width: 2.0em; } */
  #field table.dataList colgroup col:nth-child(4),
  #field table.dataList colgroup col:nth-child(5){ width: 5.0em; }
  #field table.dataList th{
    /* position: sticky;
    top: 0;
    background-color: black;
    color: white; */
  }
  #field table th,
  #field table td
  {
    vertical-align: middle;
    text-align: center;
    border: 1px solid #ccc;
    padding: 3px 10px;
  }
  #field table thead th{
    font-weight: 900 !important;
  }

  #field table tbody td span.bgColor{
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid #000;
    margin-right: 2px;
    margin-bottom: -2px;
  }

</style>
