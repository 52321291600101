<template>
  <div :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="全体"></Header>

    <div id="contents">
      <div id="main">
        <h2 class="title">全体</h2>

        <v-card
          id="control"
          class="d-flex justify-space-between  mb-4"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul class="flex jcFS pl-0">
              <li><v-select class="selectScail" v-model="selectedDisplayYear" :items="_getYears()"  @change="chgDate('selYear')" dense outlined></v-select></li>
              <li><v-select class="selectScail" v-model="selectedDisplayMonth" :items="MONTHS" @change="chgDate('selMonth')" dense outlined></v-select></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('toDay')">今日</v-btn></li>
            </ul>
            <ul class="flex jcFS pl-0">
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('lastMonth')">前月</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('lastWeek')">前週</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('dayBefore')">前日</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextDay')">翌日</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextWeek')">翌週</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="chgDate('nextMonth')">翌月</v-btn></li> 
              <li><v-select class="selectScail" v-model="selectedDisplayDays" :items="optDisplayDays" item-value="val" @change="chgDisplayDays" dense outlined hide-details="false"></v-select></li>
            </ul>
            <ul class="flex jcFS pl-0 mt-3">
              <li><v-select v-model="selClient" :items="optClient" :item-text="item => item.text" item-value="id" class="select-input-none selClient" dense outlined hide-details="false" @change="chgClient"></v-select></li>
            </ul>
          </v-card>

          <v-card
            flat
            tile
          >
            <ul class="flex jcFE">
              <li><router-link to="/Client"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">クライアント別</v-btn></router-link></li>
              <li><router-link to="/Staff"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">スタッフ別</v-btn></router-link></li>
              <li v-if="$store.state.user.authority!=3"><BtnAdd @add="add" :disabled="(isReadOnly || (this.addEditMode == 1) || ($store.state.locked == 1))"/></li>
              <li v-if="$store.state.user.authority!=3"><BtnEdit @edit="edit" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
              <li v-if="$store.state.user.authority!=3"><BtnDel @del="del" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
              <input style="display:none" ref="input" type="file" accept=".xlsx" @change="selectedImportFile()"/>
            </ul>
            <ul class="flex jcFE">
              <li><v-btn v-if="$store.state.user.authority!=3" class="boldfont" depressed color="primary white--text" @click="Copy()" v-bind:disabled="(isReadOnly || ($store.state.locked == 1))" >コピー</v-btn></li>
              <li><v-btn v-if="$store.state.user.authority!=3" class="boldfont" depressed color="primary white--text" @click="Paste()" v-bind:disabled="(isReadOnly || ($store.state.locked == 1))">貼付</v-btn></li>
            </ul>
            <ul class="flex jcFE">
              <li><v-btn class="boldfont" depressed color="primary white--text" width="110" @click="Export()">エクスポート</v-btn></li>
              <li v-if="$store.state.user.authority==9"><v-btn class="boldfont" depressed color="primary white--text" width="110" @click="Import()">インポート</v-btn></li>
              <li v-if="$store.state.locked == 0 && $store.state.user.authority==9"><v-btn class="boldfont locked" depressed color="primary white--text" width="210" @click="UpdateLocked()">編集を禁止します</v-btn></li>
              <li v-if="$store.state.locked == 1 && $store.state.user.authority==9"><v-btn class="boldfont locked" depressed color="primary white--text" width="210" @click="UpdateLocked()">編集禁止を解除します</v-btn></li>
            </ul>
            <ul class="flex jcFE mt-3">
              <li><v-select v-if="$store.state.user.authority!=3" class="selRegion" v-model="selRegion" :items="optRegion" item-value="id" @change="chgRegion" dense outlined hide-details="false"></v-select></li>
            </ul>
          </v-card>
        </v-card>

         <div id="field">
           <div id="svgScroll" @scroll="svgScroll">
              <svg id="svgHeaderRow" xmlns="http://www.w3.org/2000/svg"></svg>
              <svg id="svgHeaderCol" xmlns="http://www.w3.org/2000/svg"></svg>
              <svg id="svgLeftTop" xmlns="http://www.w3.org/2000/svg"></svg>
              <svg id="svg" xmlns="http://www.w3.org/2000/svg"
              @mouseup="svgMouseUp"
              @mousedown="svgMouseDown"
              @mousemove="svgMouseMove">
                <rect id="svgSelectRect" xmlns="http://www.w3.org/2000/svg"></rect>
                <rect id="svgCopyRect" xmlns="http://www.w3.org/2000/svg"></rect>
              </svg>
              <svg id="svgToolTip" xmlns="http://www.w3.org/2000/svg">
              </svg>
           </div>
        </div>

        <ContractOver ref="ContractOver"></ContractOver>

      </div>

      <!-- モーダルウィンドウ  -->
      <ModalAddEdit ref="ModalAddEdit" :pntItems="items" pntMethodName="pntMethod" @pntMethod="GetTop"></ModalAddEdit>
      <!-- モーダルウィンドウ(インポート用)  -->
      <ModalImport ref="ModalImport" :pntItems="items" pntMethodName="pntMethod" @pntMethod="GetTop"></ModalImport>
    </div>
      <!-- ロック時の編集不可ダイアログ -->
      <v-snackbar
        v-model="lockMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        ロック中のため、編集できません。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="lockMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- 編集失敗ダイアログ -->
      <v-snackbar
        v-model="errMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        編集に失敗しました。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="errMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalAddEdit from '@/components/ModalAddEdit.vue';
import ModalImport from '@/components/ModalImport.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
// import TopDetail from '@/components/TopDetail.vue';
import ContractOver from '@/components/ContractOver.vue';
// import draggable from 'vuedraggable';
// import Enumerable from 'linq';
import axios from 'axios';
import moment from "moment"

export default {
  name: 'Top',
  components:{
    Header,
    ModalAddEdit,
    ModalImport,
    ContractOver,
    BtnAdd,
    BtnEdit,
    BtnDel,
  },

  // サインインチェック
  beforeMount() {
  },
  
  data() {
    return {
      item: {
        master: '',
      },
      showDay: [
        {
          start: 20210701,
          end: 20210731,
          term: 31
        }
      ],

      items: [],
      // 上部の日付用
      days: [],
      startDate: "",
      // 初期値：30日
      selectedDisplayDays: 30,
      optDisplayDays: [
        //{val: 5, text: "▼5日"},
        //{val: 10, text: "▼10日"},
        {val: 30, text: "▼30日"},
        {val: 60, text: "▼60日"},
        {val: 90, text: "▼90日"},
        {val: 180, text: "▼180日"},
        {val: 270, text: "▼270日"},
        {val: 365, text: "▼365日"},
        // {val: 365, text: "▼365日"},
      ],

      // 追加・編集のボタンをクリックしたときに使用
      addEditTarget: {
        
      },

      // クリア設定
      clear: {
        "client": {
            "abbreviation": "",
            "bg_color": "",
            "contract_period_days": null,
            "contract_period_end": null,
            "contract_period_start": null,
        },
        "client_visit": "",
        "dragGroup": "",
        "task": "",
        "tentative_regist": "",
        "time": "",
        "work_content": "",
      },
      
      sendAddEdit: {
        date: "",
        // time: "",
        id: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },

      sendData: {},
      isHandle: true,
      // 0：追加, 1：編集
      addEditMode: 0,
      // 削除用
      assign_cstm_id: null,
      task_assign_id: null,

      // svgヘッダスクロール用
      prevScrollX:0,
      prevScrollY:0,

      dragItem: null,

      // svg描画用
      height:30,  // データセル高さ
      width:50, // データセル幅
      padding:3,  // データセル背景色余白
      adjust:0.5, // 配置調整
      monthYearHeight:40, // 年月行の高さ
      headerRowHeight:30, // ヘッダ1行分の高さ
      headerRowCount:2, // ヘッダ行数
      headerColWidth:90,  // ヘッダ1列分の幅
      headerColCount:2, // ヘッダ列数
      nameHeight:90, // ヘッダ行1列分の幅
      regionColWidth:50,  // 所属1列分の幅

      // 西暦・月選択用
      selectedDisplayYear: 2000,
      selectedDisplayMonth: 1,

      // コピー用
      copyItem: null,

      // クライアントプルダウン用
      selClient: 0,
      optClient: [{
        id: 0,
        text:"",
      }],

      // 所属プルダウン用
      selRegion: 0,
      optRegion: [
        { id: 0, text: "全ての所属" },
        { id: 1, text: "京都" },
        { id: 2, text: "東京" },
        { id: 3, text: "福岡" },
      ],

      // ボタン有効無効制御用
      isReadOnly:true,

      // ロック有無フラグ
      locked:0,
      
      // ロック時の編集不可ダイアログ
      lockMsgBar: false,
      // 編集失敗ダイアログ
      errMsgBar: false,
    }
  },
  mounted() {
    // スタッフは全体画面表示禁止
    if (this.$store.state.user.authority == 3) {
      this.$router.push("/Staff");
    } else {


      // 年設定
      var date = new Date();
      date.setDate(1);
      this.startDate = this.$store.state.date ? this.$store.state.date : this._formatDate(date);
      document.addEventListener('mouseup', this.documentMouseUp);
      this.selectedDisplayYear = date.getFullYear();
      this.selectedDisplayMonth = date.getMonth() + 1;
      
      this.GetTop();
    }
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy: function(){
    // イベントをクリア
    document.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    yearMonthShow: function(){
      var arrDays = [];
      for(var i=0; i<this.days.length; i++){
        var splitDate = this.days[i].date.split("-");

        var idx = arrDays.findIndex(elm => elm.date === splitDate[0] +"-"+ splitDate[1]);
          console.log(idx);
        if(idx != -1){
          arrDays[idx].count++;
        } else {
          arrDays.push(
            {
              "date": splitDate[0] +"-"+ splitDate[1],
              "count": 1
            }
          );
        }
      }

      console.log(arrDays);
      return arrDays;
    }
  },
  filters: {
  },
  methods: {
    GetTop: function(){
      console.log("GetTop");
      this.$store.dispatch("selStaffClient", {
        staff: "",
        client: "",
        date: "",
      });

      // 契約期間オーバー情報の取得
      this.$refs.ContractOver.GetContractOver();
      // プルダウン用クライアントリスト取得
      this.GetClientList();

      // ロック判定
      axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        console.log("GetLocked");
        console.log(res);
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });

      axios.post(this.URL +'GetTop2', {
        startDate: this.startDate,
        selectedDisplayDays: this.selectedDisplayDays,
        token: this.$store.state.token,
        cstmId: this.selClient,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);

        // 受信した日付リストを設定
        this.days = res.data.days;
        // console.log(this.days);

        //--- 受信したユーザ毎データを加工し、GetTopで取得する形式に変換 ---
        // テンポラリItems
        let tmpItems = [];
        if(res.data.data != null){
          for (let dataIdx=0; dataIdx<res.data.data.length; dataIdx++) {
            // テンポラリfields
            let tmpfields = [];
            // 所属が全選択でない且つ、選択所属と異なる場合はデータに含めない
            if(this.selRegion != 0 && this.selRegion != res.data.data[dataIdx]["rid"]){
              continue;
            }
            for (let dayIdx=0; dayIdx<this.days.length; dayIdx++) {
              // テンポラリfield
              let tmpfield = {};
              tmpfield["date"] = this.days[dayIdx]["date"];
              tmpfield["isHol"] = this.days[dayIdx]["isHol"];
              // 受信データから該当ユーザ、該当日付のデータを検索
              let findFsIdx = -1;
              if (res.data.data[dataIdx].fs != null) {
                for (let fsIdx=0; fsIdx<res.data.data[dataIdx].fs.length; fsIdx++) {
                  if (tmpfield["date"] == res.data.data[dataIdx].fs[fsIdx]["dt"]) {
                    findFsIdx = fsIdx;
                    break;
                  }
                }
              }
              for (let komaIdx=0; komaIdx<3; komaIdx++) {
                // 受信データから該当ユーザ、該当日付、該当コマのデータを検索
                let findTsIdx = -1;
                if (0 <= findFsIdx) {
                  if (res.data.data[dataIdx].fs[findFsIdx].ts != null) {
                    for (let tsIdx=0; tsIdx<res.data.data[dataIdx].fs[findFsIdx].ts.length; tsIdx++) {
                      if ((komaIdx + 1) == res.data.data[dataIdx].fs[findFsIdx].ts[tsIdx]["km"]) {
                        findTsIdx = tsIdx;
                        break;
                      }
                    }
                  }
                }
                // テンポラリコマ
                let tmpNo = {};
                if (0 <= findTsIdx) {
                  tmpNo["abbreviation"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["ab"];
                  tmpNo["assign_cstm_id"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["ad"];
                  tmpNo["bg_color"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["bg"];
                  tmpNo["client_visit"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["cv"];
                  tmpNo["cstm_id"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["ci"];
                  tmpNo["dragGroup"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["dg"];
                  tmpNo["fullname"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["fl"];
                  tmpNo["isOver"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["io"];
                  tmpNo["kind"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["kd"];
                  tmpNo["task_abb"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["tb"];
                  tmpNo["task_assign_id"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["td"];
                  tmpNo["task_full"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["tf"];
                  tmpNo["tentative_regist"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["tr"];
                  tmpNo["time"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["tm"];
                  tmpNo["work_text"] = res.data.data[dataIdx].fs[findFsIdx].ts[findTsIdx]["wt"];
                }
                else {
                  tmpNo["abbreviation"] =  "";
                  tmpNo["assign_cstm_id"] =  0;
                  tmpNo["bg_color"] =  "";
                  tmpNo["client_visit"] =  0;
                  tmpNo["cstm_id"] =  0;
                  tmpNo["dragGroup"] =  "";
                  tmpNo["fullname"] =  "";
                  tmpNo["isOver"] =  0;
                  tmpNo["kind"] =  0;
                  tmpNo["task_abb"] =  "";
                  tmpNo["task_assign_id"] =  0;
                  tmpNo["task_full"] =  "";
                  tmpNo["tentative_regist"] =  0;
                  tmpNo["time"] =  0;
                  tmpNo["work_text"] =  "";
                }
                tmpfield["no" + (komaIdx + 1)] = tmpNo;
              }
              tmpfields.push(tmpfield);
            }
            // テンポラリItem
            let tmpItem = {};
            tmpItem["fields"] = tmpfields;
            tmpItem["id"] = res.data.data[dataIdx]["id"];
            tmpItem["name"] = res.data.data[dataIdx]["nm"];
            tmpItem["position"] = res.data.data[dataIdx]["ps"];
            tmpItem["region_id"] = res.data.data[dataIdx]["rid"];
            tmpItems.push(tmpItem);
          }
        }
        this.items = tmpItems;
        // console.log("加工後データ");
        // console.log(this.items);
        // // console.log(this.days);

        if(!this._ChkAuthority()){
          this.isHandle = false;
        }
        // this.tableSizeWidth = this.days.length * this.tableGridSizeWidth;
        // this.tableSizeHeight = this.items.length * this.tableGridSizeHeight;
        setTimeout(() => {
          this.drawSvg();
        }, 10);
        // dragGroupに代入（ドラッグできる個所を設定）
        var self = this;
        this.items.forEach(function(elm){
          for(var i=0; i<elm.fields.length; i++){
            // if(elm.fields[i].no1.company != ""){
            //   this.$set(elm.fields[i].no1, 'dragGroup', "fixed");
            // } else {
            //   this.$set(elm.fields[i].no1, 'dragGroup', "noFixed");
            // }
            // if(elm.fields[i].no2.company != ""){
            //   this.$set(elm.fields[i].no2, 'dragGroup', "fixed");
            // } else {
            //   this.$set(elm.fields[i].no2, 'dragGroup', "noFixed");
            // }
            // if(elm.fields[i].no3.company != ""){
            //   this.$set(elm.fields[i].no3, 'dragGroup', "fixed");
            // } else {
            //   this.$set(elm.fields[i].no3, 'dragGroup', "noFixed");
            // }
            
            elm.fields[i].no1.dragGroup = elm.fields[i].no1.kind != "" ? "fixed" : "noFixed";
            elm.fields[i].no2.dragGroup = elm.fields[i].no2.kind != "" ? "fixed" : "noFixed";
            elm.fields[i].no3.dragGroup = elm.fields[i].no3.kind != "" ? "fixed" : "noFixed";
            self.$set(elm.fields[i].no1, 'isSelected', false);
            self.$set(elm.fields[i].no2, 'isSelected', false);
            self.$set(elm.fields[i].no3, 'isSelected', false);
          }
        });
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    GetClientList: function(){
      // クライアントプルダウン生成用
      // データ取得
      axios.post(this.URL +'management/GetClient', {
          token: this.$store.state.token,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        console.log(res);

        this.optClient.splice(0,this.optClient.length);
        // 未選択用の項目
        this.optClient.push({id:0,text:""})
        for(let i = 0;i < res.data.data.length;i++){
          let id = res.data.data[i].id
          let text = res.data.data[i].abbreviation +"（"+ res.data.data[i].company_name +"）"
          this.optClient.push({id:id,text:text})
        }
        console.log(this.optClient);
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });

    },
    chgDate: function(chgMode){
      var date = this.startDate;
      var dt = new Date(date);

      console.log(this.startDate);

      if(chgMode == "lastMonth"){
        // console.log("前月");
        dt.setDate(dt.getDate() -30);
      } else if(chgMode == "lastWeek"){
        // console.log("前週");
        dt.setDate(dt.getDate() -7);
      } else if(chgMode == "dayBefore"){
        // console.log("前日");
        dt.setDate(dt.getDate() -1);
      } else if(chgMode == "nextDay"){
        // console.log("翌日");
        dt.setDate(dt.getDate() +1);
      } else if(chgMode == "nextWeek"){
        // console.log("翌週");
        dt.setDate(dt.getDate() +7);
      } else if(chgMode == "nextMonth"){
        // console.log("翌月");
        dt.setDate(dt.getDate() +30);
      } else if(chgMode == "toDay"){
        // console.log("今日");
        dt = new Date();
        dt.setDate(1);
      } else if(chgMode == "selYear"){
        // console.log("年選択");
        dt.setFullYear(this.selectedDisplayYear);
        dt.setDate(1);
      } else if(chgMode == "selMonth"){
        // console.log("月選択");
        dt.setMonth(this.selectedDisplayMonth - 1);
      } else {
        // console.log("chgDateError");
      }
      this.selectedDisplayYear = dt.getFullYear();
      this.selectedDisplayMonth = dt.getMonth() + 1;
      this.startDate = this._formatDate(dt);
      this.GetTop();
    },
    // 表示日数変更
    chgDisplayDays: function(){
      this.GetTop();
    },
    // 所属絞り込み変更
    chgRegion: function(){
      this.GetTop();
    },
    chgClient: function(){
      this.$store.state.client = this.selClient;
      this.GetTop();
      this.isReadOnly = true;
    },
    add: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkLeaderAuthority(this.sendAddEdit.user_id))return
      if(this.addEditMode == 1){ return false; }
      
      console.log("add");
      this.$set(this.$refs.ModalAddEdit, "mode", "add");
      this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
      this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
      this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
      this.$set(this.$refs.ModalAddEdit.item, "no", 0);
      this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", this.sendAddEdit.temp_flg);
      this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
      this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
      this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
      this.$set(this.$refs.ModalAddEdit.item, "kind", this.sendAddEdit.kind);
      this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", this.sendAddEdit.task_assign_id);
      this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
      // エラーをリセット
      this.$set(this.$refs.ModalAddEdit.item, "errors", []);
      // this.$refs.ModalAddEdit.$refs.form.resetValidation();
      // モーダルウィンドウ表示
      this.$refs.ModalAddEdit.modalShow = true;
    },
    edit: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkLeaderAuthority(this.sendAddEdit.user_id))return
      if(this.addEditMode == 0){ return false; }

      console.log("edit");
      // 選択されていれば実行
      // 実行
      let sendTempFlg = false
      if (this.sendAddEdit.temp_flg == 1) sendTempFlg = true
      this.$set(this.$refs.ModalAddEdit, "mode", "edit");
      this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
      this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
      this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
      this.$set(this.$refs.ModalAddEdit.item, "no", this.sendAddEdit.koma);
      this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", sendTempFlg);
      this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
      this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
      this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
      this.$set(this.$refs.ModalAddEdit.item, "kind", this.sendAddEdit.kind);
      this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", this.sendAddEdit.task_assign_id);
      this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
      // エラーをリセット
      this.$set(this.$refs.ModalAddEdit.item, "errors", []);
      // モーダルウィンドウ表示
      this.$refs.ModalAddEdit.modalShow = true;
    },
    del: function(){
      // if(!this._ChkAuthority()){ return false; }
      if(!this.chkLeaderAuthority(this.sendAddEdit.user_id))return
      if(this.addEditMode == 0){ return false; }

      // 選択されていれば実行
      console.log("del");
      console.log("assign_cstm_id:"+ this.sendAddEdit.assign_cstm_id);
      console.log("task_assign_id:"+ this.sendAddEdit.task_assign_id);

      // 実行
      axios
        .post(this.URL +'DelPlan', {
            mode: "del",
            assign_cstm_id: this.sendAddEdit.assign_cstm_id,
            task_assign_id: this.sendAddEdit.task_assign_id,
            token: this.$store.state.token,
          },
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // 成功処理
            console.log("OK");
            // 再描画
            this.GetTop();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログ表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
            this.GetTop();
          }
        }
      );

      // this.$set(this.items[objDt.id].fields[objDt.index], objDt.no, this.clear);
      // dragTarget初期化
      // this.dragTarget = {};
    },
    onTgtClick: function(item, detail, tgt){
      if(!this._ChkAuthority()){ return false; }
      
      // console.log("ontgtClick");
      // console.log(item);

      var self = this;
      this.items.map(function(item){
        item.fields.map(function(elm){
          self.$set(elm.no1, 'isSelected', false);
          self.$set(elm.no2, 'isSelected', false);
          self.$set(elm.no3, 'isSelected', false);
        });
      });
      this.$set(detail[tgt], 'isSelected', true);

      console.log(detail[tgt].isSelected);

      this.addEditMode = detail[tgt].dragGroup == "noFixed" ? 0 : 1;

      this.sendAddEdit.date = detail.date;
      this.sendAddEdit.assign_time = detail[tgt].time;
      this.sendAddEdit.user_id = item.id;
      this.sendAddEdit.kind = detail[tgt].kind;
      this.sendAddEdit.cstm_id = detail[tgt].cstm_id;
      this.sendAddEdit.location = detail[tgt].client_visit;
      this.sendAddEdit.temp_flg = detail[tgt].tentative_regist;
      this.sendAddEdit.koma = parseInt(tgt.split("no")[1]);
      this.sendAddEdit.free_task = detail[tgt].task_full;
      this.sendAddEdit.work_text = detail[tgt].work_text;

      this.sendAddEdit.assign_cstm_id = detail[tgt].assign_cstm_id;
      this.sendAddEdit.task_assign_id = detail[tgt].task_assign_id;
      
      console.log("sendAddEdit");
      console.log(this.sendAddEdit);

      this.$store.dispatch("selStaffClient", {
        staff: item.id,
        client: detail[tgt].cstm_id,
        date: detail.date,
      });

      // console.log("staff:"+ this.$store.state.staff);
      // console.log("client:"+this.$store.state.client);
    },
    onDragStart: function(item, $event){
      if(!this._ChkAuthority()){ return false; }

      console.log("start");
      // console.log(index);
      // console.log(item);
      // console.log($event);

      this.$set(item.fields[$event.from.dataset.field_idx][$event.to.dataset.no], "dragGroup", "noFixed");
    },
    onDragEnd: function(index, item, $event){
      if(!this._ChkAuthority()){ return false; }
      console.log("end");
      // console.log(index);
      // console.log(item);
      // console.log($event);

      // 同じ場所以外にDragした場合に実行
      if($event.from.id != $event.to.id){
        // 移動後へ反映
        // ディープコピー
        // var deepCopy = JSON.parse(JSON.stringify(item.fields[$event.from.dataset.field_idx][$event.target.dataset.no]));
        // console.log(deepCopy);
        // this.$set(this.items[$event.to.dataset.item_idx].fields[$event.to.dataset.field_idx], $event.to.dataset.no, deepCopy);

        // 移動前の値を削除、dragGroupをnoFixedにする
        // console.log($event.to.dataset.item_idx, $event.to.dataset.field_idx, $event.to.dataset.no);
        // console.log($event.from.dataset.item_idx, $event.from.dataset.field_idx, $event.from.dataset.no);
        // this.$set(item.fields[$event.from.dataset.field_idx], $event.from.dataset.no, this.clear);
        // this.$set(item.fields[$event.from.dataset.field_idx][$event.from.dataset.no], "dragGroup", "noFixed");

        // console.log("fromAssignCstmId:"+ this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].assign_cstm_id);
        // console.log("fromTaskAssignId:"+ this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].task_assign_id);
        // console.log("fromKoma:"+ $event.from.dataset.no.split("no")[1]);
        // console.log("fromDate:"+ $event.from.dataset.date);
        // // console.log("toAssignCstmId:"+ this.items[$event.to.dataset.item_idx].fields[$event.to.dataset.field_idx][$event.to.dataset.no].assign_cstm_id);
        // console.log("toKoma:"+ $event.to.dataset.no.split("no")[1]);
        // console.log("toDate:"+ $event.to.dataset.date);

        var dSendData = {
          token: this.$store.state.token,
          userId: this.$store.state.user.id,
          from: {
            drag_user_id: this.items[$event.from.dataset.item_idx].id,
            cstm_id: this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].cstm_id,
            assign_cstm_id: this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].assign_cstm_id,
            task_assign_id: this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].task_assign_id,
            koma: parseInt($event.from.dataset.no.split("no")[1]),
            date: $event.from.dataset.date,
            temp_flg: this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].tentative_regist,
            work_text: this.items[$event.from.dataset.item_idx].fields[$event.from.dataset.field_idx][$event.from.dataset.no].work_text,
          },
          to: {
            drag_user_id: this.items[$event.to.dataset.item_idx].id,
            koma: parseInt($event.to.dataset.no.split("no")[1]),
            date: $event.to.dataset.date,
          },
        }

        console.log(dSendData);

        // 実行
        axios
          .post(this.URL +'DragDrop', dSendData,
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            // console.log(res);
            if(res.data.ret){
              // 成功処理
              console.log("OK");
              // 再描画
              this.GetTop();
            } else {
              // エラー処理
              console.log("NG");
              if(res.data.errCode == 2){
                alert("既にデータが存在するコマへは移動できません");
                this.items.splice(0, this.items.length);
              }
              this.GetTop();
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }

      // 移動後のにする
      // ドラッグ後の場所を固定するためdragGroupをfixedに設定 = 他の要素からドラッグされないために設定
      // console.log($event.to.dataset.item_idx, $event.to.dataset.field_idx, $event.to.dataset.no);
      this.$set(this.items[$event.to.dataset.item_idx].fields[$event.to.dataset.field_idx][$event.to.dataset.no], "dragGroup", "fixed");
      // this.$set(this.items[$event.to.dataset.field_idx].fields[$event.to.dataset.item_idx][$event.to.dataset.no], "dragGroup", "fixed");
      // this.$set(this.items[0].fields[0][$event.to.dataset.no], "dragGroup", "fixed");

      // // this.$forceUpdate();
      // console.log(this.items);
    },
    // 年月表示用
    getYearMonth: function(val){
      var dateSplit = (String(val)).split('-');
      return dateSplit[0] + "年" + ('000' + Number(dateSplit[1])).slice(-2) + "月";
    },
    // 日を表示
    getDay: function(val){
      var dateSplit = val.split("-");
      return Number(dateSplit[2]);
    },
    // 曜日表示
    getDayOfWeek: function(val){
      // Dateオブジェクトは曜日情報を0から6の数値で保持しているため、翻訳する
      var dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ] ;
      // 指定日付で初期化したDateオブジェクトのインスタンスを生成する
      var date = new Date(val);
      return dayOfWeekStrJP[date.getDay()];
    },
    // Svg上のマウス座標を取得
    getMousePos(e)
    {
      const rect = e.currentTarget.getBoundingClientRect();
      // ブラウザ上での座標を求める
      const viewX = e.clientX - rect.left;
      const viewY = e.clientY - rect.top;
      // 表示サイズと実サイズの比率を求める
      const svg =document.getElementById("svg")
      const scaleWidth =  svg.clientWidth / svg.getAttribute("width");
      const scaleHeight =  svg.clientHeight / svg.getAttribute("height");
      // ブラウザ上でのマウス座標をSVG上に変換
      const svgX = Math.floor( viewX / scaleWidth );
      const svgY = Math.floor( viewY / scaleHeight );
      return {x:svgX , y:svgY}
    },
    // 指定した座標のデータのインデックスを取得
    getDataIdxFromPos: function(xPos,yPos)
    {
      const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
      const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の高さ
      const x = Math.floor((xPos - headerAllColWidth) / this.width)
      const calY = Math.floor((yPos - headerAllRowHeight) / this.height)
      const y = Math.floor(calY / 3)
      let no = calY
      if(y != 0)  no = calY % (y * 3)
      // 描画範囲
      const rectXPos1 = ((this.width * x) + headerAllColWidth);
      const rectYPos1 = ((this.height * (y * 3))+ (this.height * no) + headerAllRowHeight)
      const rectXPos2 = (this.width);
      const rectYPos2 = (this.height);
      const domRect = new DOMRect(rectXPos1,rectYPos1,rectXPos2,rectYPos2)
      return {x:x, y:y, no:no, domRect:domRect}
    },
    // 指定したx,y,noからデータ取得
    getDataFromIdx:function(x,y,no)
    {
      if(this.items[y] == null) return null
      if(this.items[y].fields[x] == null) return null
      let data = this.items[y].fields[x].no1;
      if(no == 0) data = this.items[y].fields[x].no1;
      if(no == 1) data = this.items[y].fields[x].no2;
      if(no == 2) data = this.items[y].fields[x].no3;
      return data
    },
    // 選択中データの枠線絵画
    setSelData:function()
    {
      if (!this.sendAddEdit.data)
      {
        for(let i=0; i<this.items.length; i++)
        {
          if (this.items[i].id == this.sendAddEdit.user_id)
          {
            for(let j=0; j<this.items[i].fields.length; j++)
            {
              if(this.items[i].fields[j].date == this.sendAddEdit.date)
              {
                // DBデータが更新されている場合があるため入れ直し
                let data = this.items[i].fields[j].no1;
                if(this.sendAddEdit.koma == 1) data = this.items[i].fields[j].no1;
                if(this.sendAddEdit.koma == 2) data = this.items[i].fields[j].no2;
                if(this.sendAddEdit.koma == 3) data = this.items[i].fields[j].no3;
                this.sendAddEdit.assign_time = data.time;
                this.sendAddEdit.kind = data.kind;
                this.sendAddEdit.cstm_id = data.cstm_id;
                this.sendAddEdit.location = data.client_visit;
                this.sendAddEdit.temp_flg = data.tentative_regist;
                this.sendAddEdit.free_task = data.task_full;
                this.sendAddEdit.work_text = data.work_text;
                this.sendAddEdit.assign_cstm_id = data.assign_cstm_id;
                this.sendAddEdit.task_assign_id = data.task_assign_id;
                this.$store.dispatch("selStaffClient", {
                  staff: this.items[i].id,
                  client: data.cstm_id,
                  date: this.days[j].date,
                });
                if (data.kind != 0) {
                  this.addEditMode = 1; // 編集モード
                }
                else {
                  this.addEditMode = 0; // 追加モード
                }

                // 描画範囲
                const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
                const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-chosen") // 選択項目枠線色
                const svg =document.getElementById("svg") // データ描画svg
                const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の高さ
                const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
                const rectXPos1 = ((this.width * j) + headerAllColWidth);
                const rectYPos1 = ((this.height * (i * 3))+ (this.height * (this.sendAddEdit.koma-1)) + headerAllRowHeight)
                const rectXPos2 = (this.width);
                const rectYPos2 = (this.height);
                const domRect = new DOMRect(rectXPos1,rectYPos1,rectXPos2,rectYPos2)
                // 選択項目枠線が既に存在する場合は削除する
                if(document.getElementById("svgSelectRect") != null) svg.removeChild(document.getElementById("svgSelectRect"));
                // 選択項目枠線を描画
                const svgSelectRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                svgSelectRect.setAttribute('id', "svgSelectRect");
                svgSelectRect.setAttribute('x', domRect.x + 2);
                svgSelectRect.setAttribute('y', domRect.y + 2);
                svgSelectRect.setAttribute("width", domRect.width - 3);
                svgSelectRect.setAttribute("height", domRect.height - 3);
                svgSelectRect.setAttribute('stroke', sortableChosen);
                svgSelectRect.setAttribute('stroke-width', 2);
                svgSelectRect.setAttribute('fill-opacity', 0);
                svgSelectRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
                svg.appendChild( svgSelectRect );
                return
              }
            }
          }
        }
      }
      // 編集データをリセット
      this.sendAddEdit = {
        date: "",
        id: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },
      this.$store.dispatch("selStaffClient", {
        staff: "",
        client: "",
        date: "",
      });
      console.log("選択中データなし");
    },
    // コピー選択中の枠線絵画
    setCopyData:function()
    {
      if (this.copyItem != null)
      {
        for(let i=0; i<this.items.length; i++)
        {
          if (this.items[i].id == this.copyItem.user_id)
          {
            for(let j=0; j<this.items[i].fields.length; j++)
            {
              if(this.items[i].fields[j].date == this.copyItem.date)
              {

                // DBデータが更新されている場合があるため入れ直し
                let data = this.items[i].fields[j].no1;
                if(this.copyItem.koma == 1) data = this.items[i].fields[j].no1;
                if(this.copyItem.koma == 2) data = this.items[i].fields[j].no2;
                if(this.copyItem.koma == 3) data = this.items[i].fields[j].no3;
                // DBデータが更新されている場合があるため入れ直し
                this.copyItem.assign_time = data.time;
                this.copyItem.kind = data.kind;
                this.copyItem.cstm_id = data.cstm_id;
                this.copyItem.location = data.client_visit;
                this.copyItem.temp_flg = data.temp_flg;
                this.copyItem.free_task = data.task_full;
                this.copyItem.work_text = data.work_text;
                this.copyItem.assign_cstm_id = data.assign_cstm_id;
                this.copyItem.task_assign_id = data.task_assign_id;

                // 描画範囲
                const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
                const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-copy") // コピー選択項目枠線色
                const svg =document.getElementById("svg") // データ描画svg
                const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の高さ
                const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
                const rectXPos1 = ((this.width * j) + headerAllColWidth);
                const rectYPos1 = ((this.height * (i * 3))+ (this.height * (this.copyItem.koma-1)) + headerAllRowHeight)
                const rectXPos2 = (this.width);
                const rectYPos2 = (this.height);
                const domRect = new DOMRect(rectXPos1,rectYPos1,rectXPos2,rectYPos2)
                // 選択項目枠線が既に存在する場合は削除する
                if(document.getElementById("svgCopyRect") != null) svg.removeChild(document.getElementById("svgCopyRect"));
                // 選択項目枠線を描画
                const svgCopyRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                svgCopyRect.setAttribute('id', "svgCopyRect");
                svgCopyRect.setAttribute('x', domRect.x + 2);
                svgCopyRect.setAttribute('y', domRect.y + 2);
                svgCopyRect.setAttribute("width", domRect.width - 3);
                svgCopyRect.setAttribute("height", domRect.height - 3);
                svgCopyRect.setAttribute('stroke', sortableChosen);
                svgCopyRect.setAttribute('stroke-width', 2);
                svgCopyRect.setAttribute('fill-opacity', 0);
                svgCopyRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
                svg.appendChild( svgCopyRect );
                return;
              }
            }
          }
        }
      }
      this.copyItem = null;
      console.log("コピー選択中データなし");
    },
    // 領域外へ選択セルがはみ出している場合スクロール
    selectCellScroll: function()
    {
      const field =document.getElementById("field")
      const svgHeaderCol =document.getElementById("svgHeaderCol") // ヘッダ列
      const svgHeaderRow =document.getElementById("svgHeaderRow") // ヘッダ行
      const svgScrollBar = document.getElementById("svgScroll");  // スクロールバー

      const HeaderColRect = svgHeaderCol.getBoundingClientRect();
      const HeaderRowRect = svgHeaderRow.getBoundingClientRect();
      const scrollRect = svgScrollBar.getBoundingClientRect()

      const svgSelectRect =  document.getElementById("svgSelectRect")
      const selectRect = svgSelectRect.getBoundingClientRect()
      
      const strokeWidth = Number(svgSelectRect.getAttribute('stroke-width'));

      // svg領域外(上)の場合のみ処理
      if(selectRect.top < HeaderColRect.bottom)
      {
        // 上へスクロール
        svgScrollBar.scrollTop -= HeaderColRect.bottom-selectRect.top + strokeWidth;
      }
      // svg領域外(下)の場合のみ処理
      if(selectRect.bottom  > scrollRect.bottom - (field.clientHeight - svgScrollBar.clientHeight))
      {
        // 下へスクロール
        svgScrollBar.scrollTop += selectRect.bottom - (scrollRect.bottom - (field.clientHeight - svgScrollBar.clientHeight)) + strokeWidth;
      }
      // svg領域外(左)の場合のみ処理
      if(selectRect.left < HeaderRowRect.right)
      {
        // 左へスクロール
        svgScrollBar.scrollLeft -= HeaderRowRect.right-selectRect.left + strokeWidth;
      }
      // svg領域外(右)の場合のみ処理
      if(selectRect.right  > scrollRect.right - (field.clientWidth - svgScrollBar.clientWidth))
      {
        // 右へスクロール
        svgScrollBar.scrollLeft += selectRect.right - (scrollRect.right - (field.clientWidth - svgScrollBar.clientWidth)) + strokeWidth;
      }
    },
    // svg自動改行文字列表示
    // val:表示文字列 domRect:矩形情報 svgText:text要素 parent:親要素
    autoLineBreak: function(val,domRect,svgText,parent)
    {
      // canvasを用いて文字サイズ取得
      const ctxCal = document.createElement("canvas").getContext("2d");
      ctxCal.font = svgText.getAttribute("font-weight") + " " + svgText.getAttribute("font-size") + "px" + " " + svgText.getAttribute("font-family");
      const metrics = ctxCal.measureText(String(val));  // 文字サイズ
      const textHeight = (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent) // 文字列高さ
      const lineSpaceHeight = textHeight / 2; // 行間高さ
      let allTextHeight = textHeight;  // 全体の文字列高さ

      let lineText = ""   // 1行分の文字列
      let lineItems = []; // 1行毎の文字列リスト
      // 1文字ずつ増やして改行位置を判断
      for(let i = 0;i< String(val).length; i++)
      {
        const metrics = ctxCal.measureText(lineText + String(val)[i]); // 1行分の文字サイズ
        const textWidth = metrics.width; // 1行分の文字列幅
        // 指定した幅を超える場合は改行
        if(textWidth > domRect.width)
        {
          lineItems.push(lineText)  // リストに追加
          if(allTextHeight + lineSpaceHeight + textHeight > domRect.height) break;  // 指定した高さを超える場合は中断
          lineText = "";  // 1行分の文字列リセット
          allTextHeight += textHeight + lineSpaceHeight;  // 全体の高さを追加
        }
        lineText += String(val)[i]; // 1行分の文字列に文字を追加
        // 最後の文字の場合
        if(i == String(val).length - 1){
          lineItems.push(lineText)  // リストに追加
          allTextHeight += textHeight;  // 全体の高さを追加
        }
      }
      // 分割した文字列のリストを元に、文字列を改行表示
      this.lineBreak(lineItems,domRect,svgText,parent)
    },
    // svg改行文字列表示
    // lineItems:行毎に分割した文字列のリスト domRect:矩形情報 svgText:text要素 parent:親要素
    lineBreak: function(lineItems,domRect,svgText,parent)
    {
      // 文字列が存在しない場合中断
      if(lineItems.length == 0) return;

      // canvasを用いて文字サイズ取得
      const ctxCal = document.createElement("canvas").getContext("2d");
      ctxCal.font = svgText.getAttribute("font-weight") + " " + svgText.getAttribute("font-size") + "px" + " " + svgText.getAttribute("font-family");
      const metrics = ctxCal.measureText(lineItems[0]);
      const textHeight = (metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent) // 文字列高さ
      const lineSpaceHeight = textHeight / 2; // 行間高さ
      const allTextHeight = (textHeight * lineItems.length) + (lineSpaceHeight * (lineItems.length - 1));  // 全体の文字列高さ

      let startXPos = domRect.x + this.padding; // 文字列開始位置(X)
      const startYPos = domRect.y + (domRect.height - allTextHeight) / 2; // 文字列開始位置(Y)
      // X位置調整
      if(svgText.getAttribute("text-anchor") == "middle") startXPos = domRect.x + (domRect.width / 2);
      // 1行ずつ描画
      for(let i = 0;i < lineItems.length;i++)
      {
        let text = svgText.cloneNode(true); // 対象のtext要素のディープコピーを作成
        text.textContent = lineItems[i];  // 文字列設定
        text.setAttribute('x', startXPos);  // 文字列開始位置(X)
        // 文字列開始位置(Y) (先頭行以外は行間の高さを考慮)
        if(i != 0) text.setAttribute('y', startYPos + ((textHeight + lineSpaceHeight) * i) + (textHeight / 2));
        else text.setAttribute('y', startYPos + (textHeight * i) + (textHeight / 2));

        parent.appendChild(text); // 親要素に追加
      }
    },
    // データセル描画
    drawSvg: function(){
      const start_time = Date.now();  // 描画速度確認用
      console.log("描画開始");

      // ヘッダ左上描画
      this.drawSvgLeftTop()
      console.log("左上描画終了 " + Math.abs(start_time - Date.now())/1000 + '秒経過');

      // ヘッダ行描画
      this.drawSvgHeaderRow()
      console.log("ヘッダ行描画終了 " + Math.abs(start_time - Date.now())/1000 + '秒経過');

      // fieldスタイル
      const field = document.getElementById( "field" ) ;
      const cssStyleDeclaration = getComputedStyle( field, null ) ;

      const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount); // ヘッダ行全体の高さ
      const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の幅

      const svgScrollBar = document.getElementById("svgScroll");  // スクロールバー
      // 日数に合わせてセル幅設定
      if(this.selectedDisplayDays > 30) this.width = (svgScrollBar.clientWidth - headerAllColWidth - 1) / 30
      else this.width = (svgScrollBar.clientWidth - headerAllColWidth - 1) / this.selectedDisplayDays

      // データセル表示svg
      const svg =document.getElementById("svg")
      svg.setAttribute("width", ((this.selectedDisplayDays) * this.width + headerAllColWidth + 1));
      svg.setAttribute("height", ((this.items.length * 3) * this.height + headerAllRowHeight + 1));
      svg.setAttribute('style', 'cursor:default;')

      while (svg.firstChild) svg.removeChild(svg.firstChild); // 子オブジェクトの削除
      if(document.getElementById("svgToolTip") != null) document.getElementById("svgToolTip").remove(); // ツールチップ削除

      // 背景色描画
      const holidayColor = cssStyleDeclaration.getPropertyValue("--holiday-color") // 休日(灰)
      for(let day=0; day< this.days.length; day++)
      {
        let isHoliday = this.days[day].isHol; // 曜日
        // 休日の場合
        if((isHoliday == 0) || (isHoliday == 6) || (isHoliday == 7))
        {
          // 背景描画範囲
          const backRectXPos1 = ((this.width * day) + headerAllColWidth);
          const backRectYPos1 = this.monthYearHeight;
          const backRectXPos2 = this.width;
          const backRectYPos2 = ((this.items.length * 3) * this.height + headerAllRowHeight + 1);
          // 背景描画
          const backRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          backRect.setAttribute('x', backRectXPos1);
          backRect.setAttribute('y', backRectYPos1);
          backRect.setAttribute('width', backRectXPos2);
          backRect.setAttribute('height', backRectYPos2);
          backRect.setAttribute('fill', holidayColor);
          svg.appendChild( backRect );
        }
      }

      // データ表示
      const fontSize = 12 // データフォントサイズ
      const standardForeColor = cssStyleDeclaration.getPropertyValue("--standard-forecolor")  // 文字色(黒)
      const isOverColor = cssStyleDeclaration.getPropertyValue("--is-over-color") // 超過時文字色(赤)
      const tentativeRegistColor = cssStyleDeclaration.getPropertyValue("--tentative-regist-color") // 仮設定時文字色(青)
      for (let y=0; y<this.items.length; y++)
      {
        for(let x = 0; x < this.items[y].fields.length; x++)
        {
          for(let no = 0; no < 3; no++)
          {
            const data = this.getDataFromIdx(x,y,no); // 表示データ
            if(data == null) return
            // セル描画範囲
            const cellRectXPos1 = (this.width * x) + headerAllColWidth;
            const cellRectYPos1 = (this.height * (y * 3)) + (this.height * no) + headerAllRowHeight
            const cellRectXPos2 = this.width;
            const cellRectYPos2 = this.height;
            // データが存在する場合
            if(data.kind != 0)
            {
              // 文字色
              let foreColor = standardForeColor
              if(data.isOver == 1) foreColor = isOverColor  // 超過時赤
              if(data.tentative_regist == 1) foreColor = tentativeRegistColor // 仮設定時青
              // セル背景描画範囲
              const cellBackRectXPos1 = cellRectXPos1 + this.padding
              const cellBackRectYPos1 = cellRectYPos1 + this.padding
              const cellBackRectXPos2 = cellRectXPos2 - (this.padding * 2) + 1
              const cellBackRectYPos2 = cellRectYPos2 - (this.padding * 2) + 1
              const cellBackDomRect = new DOMRect(cellBackRectXPos1,cellBackRectYPos1,cellBackRectXPos2,cellBackRectYPos2)
              // 表示文字列
              let dispText = data.abbreviation
              // 顧客表示
              if(data.kind == 1)
              {
                // 背景描画
                const cellBackRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                cellBackRect.setAttribute('id', ("rect" + "x" + x + "y" + y + "no" + no));
                cellBackRect.setAttribute('x', cellBackRectXPos1);
                cellBackRect.setAttribute('y', cellBackRectYPos1);
                cellBackRect.setAttribute('width', cellBackRectXPos2);
                cellBackRect.setAttribute('height', cellBackRectYPos2);
                cellBackRect.setAttribute('fill', data.bg_color);
                svg.appendChild( cellBackRect );
                // 表示文字列 (場所が事務所なら小文字へ変換)
                dispText = data.abbreviation
                if(data.client_visit == 2) dispText = dispText.toLowerCase()
              }
              // タスク表示
              else
              {
                dispText = data.task_full // 表示文字列
              }
              // 文字列描画
              const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              text.setAttribute('id', ("text" + "x" + x + "y" + y + "no" + no));
              text.setAttribute('text-anchor', "middle");
              text.setAttribute('dominant-baseline', "central");
              text.setAttribute('fill', foreColor);
              text.setAttribute('font-weight', 'normal');
              text.setAttribute('font-size', fontSize);
              text.setAttribute('font-family', "Roboto");
              text.setAttribute('style', "user-select:none");
              this.autoLineBreak(dispText.substr(0,4),cellBackDomRect,text,svg)
            }
          }
        }
      }
      // データ部グリッド線表示
      const borderColor = cssStyleDeclaration.getPropertyValue("--border-color")
      for(let x = 0; x < this.days.length; x++)
      {
        const xPos = headerAllColWidth + (this.width * (x + 1));  // セルの右端
        // 縦線描画
        const VerticalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        VerticalLine.setAttribute('x1',(xPos + this.adjust));
        VerticalLine.setAttribute('y1',(headerAllRowHeight + this.adjust));
        VerticalLine.setAttribute('x2',(xPos + this.adjust));
        VerticalLine.setAttribute('y2',(this.height * this.items.length * 3 + headerAllRowHeight + this.adjust));
        VerticalLine.setAttribute('stroke', borderColor);
        VerticalLine.setAttribute('stroke-width', 1);
        svg.appendChild( VerticalLine );
      }
      for (let y=0; y<this.items.length * 3; y++)
      {
        const yPos = headerAllRowHeight + (this.height * (y + 1));  // セルの底辺
        // 横線描画
        const HorizontalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        HorizontalLine.setAttribute('x1',(headerAllColWidth));
        HorizontalLine.setAttribute('y1',(yPos + this.adjust));
        HorizontalLine.setAttribute('x2',(this.width * this.days.length + headerAllColWidth + this.adjust));
        HorizontalLine.setAttribute('y2',(yPos + this.adjust));
        HorizontalLine.setAttribute('stroke', borderColor);
        HorizontalLine.setAttribute('stroke-width', 1);
        svg.appendChild( HorizontalLine );
      }
      console.log("データ描画終了 " + Math.abs(start_time - Date.now())/1000 + '秒経過');

      // ヘッダ列描画
      this.drawSvgHeaderCol()
      console.log("ヘッダ列描画終了 " + Math.abs(start_time - Date.now())/1000 + '秒経過');

      console.log("描画完了");

      // 選択中箇所があれば枠線絵画
      this.setSelData();
      // コピー選択中箇所があれば枠線絵画
      this.setCopyData();

    },
    // ヘッダ左上描画
    drawSvgLeftTop:function(){
      // fieldスタイル取得
      const field = document.getElementById( "field" ) ;
      const cssStyleDeclaration = getComputedStyle( field, null ) ;

      const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount) + 1;  // ヘッダ行全体の高さ
      const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の幅
      // サイズ
      const svgLeftTop =document.getElementById("svgLeftTop")
      svgLeftTop.setAttribute("width", (headerAllColWidth + 1));
      svgLeftTop.setAttribute("height", (headerAllRowHeight));
      
      // 子オブジェクトの削除
      while (svgLeftTop.firstChild) svgLeftTop.removeChild(svgLeftTop.firstChild);

      const borderColor = cssStyleDeclaration.getPropertyValue("--border-color")  // 線色
      // 背景色&枠線描画
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', 0 + this.adjust);
      rect.setAttribute('y', 0 + this.adjust);
      rect.setAttribute("width", headerAllColWidth);
      rect.setAttribute("height", headerAllRowHeight - 1);
      rect.setAttribute('stroke', borderColor);
      rect.setAttribute('stroke-width', 1);
      rect.setAttribute('fill', cssStyleDeclaration.getPropertyValue( "--header-col-color" ));
      svgLeftTop.appendChild( rect );
      // 文字列描画
      const fontSize = 16;
      const lineItems = ["大文字：往査","小文字：事務所"]
      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      text.setAttribute('text-anchor', "start");
      text.setAttribute('dominant-baseline', "central");
      text.setAttribute('fill', cssStyleDeclaration.getPropertyValue("--header-left-top-forecolor"));
      text.setAttribute('font-weight', 'normal');
      text.setAttribute('font-size', fontSize);
      text.setAttribute('font-family', "Roboto");
      text.setAttribute('style', "user-select:none;");
      const textDomRect = new DOMRect(0,0,headerAllColWidth,headerAllRowHeight);
      this.lineBreak(lineItems,textDomRect,text,svgLeftTop);
    },
    // ヘッダ列描画
    drawSvgHeaderCol:function()
    {
      // fieldスタイル
      const field = document.getElementById( "field" ) ;
      const cssStyleDeclaration = getComputedStyle( field, null ) ;

      const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount) + 1;  // ヘッダ行全体の高さ
      const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の幅

      //列ヘッダサイズ
      const svgHeaderCol =document.getElementById("svgHeaderCol")
      if(svgHeaderCol == null) return
      svgHeaderCol.setAttribute("width", ((this.selectedDisplayDays) * this.width + headerAllColWidth + 1));
      svgHeaderCol.setAttribute("height", (headerAllRowHeight));

      // 子オブジェクトの削除
      while (svgHeaderCol.firstChild) svgHeaderCol.removeChild(svgHeaderCol.firstChild);

      // 全体背景(白)
      const backRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      backRect.setAttribute('x', 0);
      backRect.setAttribute('y', 0);
      backRect.setAttribute('width', (this.width * this.days.length + headerAllColWidth));
      backRect.setAttribute('height', (headerAllRowHeight));
      backRect.setAttribute('fill', cssStyleDeclaration.getPropertyValue( "--standard-backcolor" ));
      svgHeaderCol.appendChild( backRect );
      // 列ヘッダ背景(青)
      const ColHeaderBackRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      ColHeaderBackRect.setAttribute('x', headerAllColWidth);
      ColHeaderBackRect.setAttribute('y', 0);
      ColHeaderBackRect.setAttribute('width', (this.width * this.days.length + headerAllColWidth));
      ColHeaderBackRect.setAttribute('height', this.monthYearHeight);
      ColHeaderBackRect.setAttribute('fill', cssStyleDeclaration.getPropertyValue( "--header-col-color" ));
      svgHeaderCol.appendChild( ColHeaderBackRect );

      // 日付文字列描画
      let beforeMonthPos = 0; // 前月位置
      let prevDispMonth = this.getYearMonth(this.days[0].date);  // 1列前の表示年月
      const standardForeColor = cssStyleDeclaration.getPropertyValue("--standard-forecolor")
      const borderColor = cssStyleDeclaration.getPropertyValue("--border-color")
      for(let day=0; day< this.days.length; day++)
      {
        const dispMonth = this.getYearMonth(this.days[day].date); // 表示年月
        // 1列前の年月から変化した場合に、前の年月を描画
        if((dispMonth != prevDispMonth) || (day == (this.days.length -1)))
        {
          // 年月文字列描画範囲
          let MonthYearX = (day - beforeMonthPos) * this.width;
          if(dispMonth == prevDispMonth) MonthYearX = (day + 1 - beforeMonthPos) * this.width;
          const MonthYearY = this.monthYearHeight;
          // 年月文字列描画
          const MonthYearFontSize = 24;
          const MonthYearText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          MonthYearText.setAttribute('text-anchor', "middle");
          MonthYearText.setAttribute('dominant-baseline', "central");
          MonthYearText.setAttribute('fill', standardForeColor);
          MonthYearText.setAttribute('font-weight', "bold");
          MonthYearText.setAttribute('font-size', MonthYearFontSize);
          MonthYearText.setAttribute('font-family', "Roboto");
          MonthYearText.setAttribute('style', "user-select:none;");
          const textDomRect = new DOMRect((beforeMonthPos * this.width) + headerAllColWidth,0,MonthYearX,MonthYearY)
          this.autoLineBreak(prevDispMonth,textDomRect,MonthYearText,svgHeaderCol)

          let gridXPos = (day * this.width) + headerAllColWidth;  // グリッド描画位置(X)
          if(day == (this.days.length -1)) gridXPos = ((day + 1) * this.width) + headerAllColWidth;  // 最終列の場合は右端を描画
          // 月区切りグリッド描画
          const MonthLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
          MonthLine.setAttribute('x1',(gridXPos + this.adjust));
          MonthLine.setAttribute('y1',(0 + this.adjust));
          MonthLine.setAttribute('x2',(gridXPos + this.adjust));
          MonthLine.setAttribute('y2',(this.monthYearHeight + this.adjust));
          MonthLine.setAttribute('stroke', borderColor);
          MonthLine.setAttribute('stroke-width', 1);
          svgHeaderCol.appendChild( MonthLine );

          beforeMonthPos = day; // 前月位置再設定
        }
        prevDispMonth = dispMonth;  // 1列前の表示年月再設定
        let isHoliday = this.days[day].isHol; // 曜日(0:日曜~6:土曜,7:祝日)
        // 日＆曜日文字色
        let weekDayforeColor = standardForeColor;
        const holidayColor = cssStyleDeclaration.getPropertyValue("--holiday-color")
        const sundayColor = cssStyleDeclaration.getPropertyValue("--sunday-color")
        const saturdayColor = cssStyleDeclaration.getPropertyValue("--saturday-color")
        // 休日の場合
        if((isHoliday == 0) || (isHoliday == 6) || (isHoliday == 7))
        {
          // 背景描画範囲
          const holidayBackRectXPos1 = ((this.width * day) + headerAllColWidth) +this.adjust;
          const holidayBackRectYPos1 = this.monthYearHeight +this.adjust;
          const holidayBackRectXPos2 = this.width;
          const holidayBackRectYPos2 = headerAllRowHeight;
          // 背景描画
          const holidayBackRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          holidayBackRect.setAttribute('x', holidayBackRectXPos1);
          holidayBackRect.setAttribute('y', holidayBackRectYPos1);
          holidayBackRect.setAttribute('width', holidayBackRectXPos2);
          holidayBackRect.setAttribute('height', holidayBackRectYPos2);
          holidayBackRect.setAttribute('fill', holidayColor);
          svgHeaderCol.appendChild( holidayBackRect );
          // 日＆曜日文字色の変更
          if(isHoliday == 6) weekDayforeColor = saturdayColor;  // 土曜：青
          if(isHoliday == 7) weekDayforeColor = sundayColor;  // 祝日：赤
          if(isHoliday == 0) weekDayforeColor = sundayColor;  // 日曜：赤
        }
        const fontSize = 16;  // 日＆曜日文字列フォントサイズ
        const dayXPos = (day * this.width) + (this.width / 2) + headerAllColWidth;  // 日＆曜日文字列描画位置(X)
        const dayYPos = this.monthYearHeight + (this.headerRowHeight / 2);  // 日文字列描画位置(Y)
        const dispDay = this.getDay(this.days[day].date); // 日文字列
        const weekYPos = this.headerRowHeight + (this.headerRowHeight / 2) + this.monthYearHeight;  // 曜日文字列描画位置(Y)
        const dispWeekDay = this.getDayOfWeek(this.days[day].date); // 曜日文字列
        // 日文字列描画
        const dayText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        dayText.textContent = dispDay;
        dayText.setAttribute('x', dayXPos);
        dayText.setAttribute('y', dayYPos);
        dayText.setAttribute('text-anchor', "middle");
        dayText.setAttribute('dominant-baseline', "central");
        dayText.setAttribute('fill', weekDayforeColor);
        dayText.setAttribute('font-weight', 'normal');
        dayText.setAttribute('font-size', fontSize);
        dayText.setAttribute('font-family', "Roboto");
        dayText.setAttribute('style', "user-select:none;");
        svgHeaderCol.appendChild(dayText);
        // 曜日描画
        const weekDaytext = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        weekDaytext.textContent = dispWeekDay;
        weekDaytext.setAttribute('x', dayXPos);
        weekDaytext.setAttribute('y', weekYPos);
        weekDaytext.setAttribute('text-anchor', "middle");
        weekDaytext.setAttribute('dominant-baseline', "central");
        weekDaytext.setAttribute('fill', weekDayforeColor);
        weekDaytext.setAttribute('font-weight', 'normal');
        weekDaytext.setAttribute('font-size', fontSize);
        weekDaytext.setAttribute('font-family', "Roboto");
        weekDaytext.setAttribute('style', "user-select:none;");
        svgHeaderCol.appendChild(weekDaytext);
      }

      for(var x = 0; x < this.days.length; x++)
      {
        const xPos = (this.width * (x + 1)) + headerAllColWidth;  // 描画位置X
        // 日区切り縦線描画
        const dayLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        dayLine.setAttribute('x1',(xPos + this.adjust));
        dayLine.setAttribute('y1',(this.monthYearHeight + this.adjust));
        dayLine.setAttribute('x2',(xPos + this.adjust));
        dayLine.setAttribute('y2',(headerAllRowHeight + this.adjust));
        dayLine.setAttribute('stroke', borderColor);
        dayLine.setAttribute('stroke-width', 1);
        svgHeaderCol.appendChild( dayLine );
      }
      // ヘッダ上端横線描画
      const HorizontalLineTop = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      HorizontalLineTop.setAttribute('x1',(headerAllColWidth));
      HorizontalLineTop.setAttribute('y1',(0 + this.adjust));
      HorizontalLineTop.setAttribute('x2',(this.width * this.days.length + headerAllColWidth + this.adjust));
      HorizontalLineTop.setAttribute('y2',(0 + this.adjust));
      HorizontalLineTop.setAttribute('stroke', borderColor);
      HorizontalLineTop.setAttribute('stroke-width', 1);
      svgHeaderCol.appendChild( HorizontalLineTop );

      for (var y=0; y< this.headerRowCount + 1; y++)
      {
        const yPos = this.headerRowHeight * y + this.monthYearHeight; // 描画位置Y
        // 横線描画
        const HorizontalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        HorizontalLine.setAttribute('x1',(headerAllColWidth));
        HorizontalLine.setAttribute('y1',(yPos + this.adjust));
        HorizontalLine.setAttribute('x2',(this.width * this.days.length + headerAllColWidth + this.adjust));
        HorizontalLine.setAttribute('y2',(yPos + this.adjust));
        HorizontalLine.setAttribute('stroke', borderColor);
        HorizontalLine.setAttribute('stroke-width', 1);
        svgHeaderCol.appendChild( HorizontalLine );
      }
    },
    // ヘッダ行描画
    drawSvgHeaderRow:function()
    {
      // fieldスタイル
      const element = document.getElementById( "field" ) ;
      const cssStyleDeclaration = getComputedStyle( element, null ) ;

      const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
      const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の幅
      // サイズ
      const svgHeaderRow =document.getElementById("svgHeaderRow")
      svgHeaderRow.setAttribute("width", (headerAllColWidth + 1));
      svgHeaderRow.setAttribute("height", ((this.items.length * 3) * this.height + headerAllRowHeight + 1));

      // 子オブジェクトの削除
      while (svgHeaderRow.firstChild) svgHeaderRow.removeChild(svgHeaderRow.firstChild);

      // 背景色(灰)
      const BackRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      BackRect.setAttribute('x', 0);
      BackRect.setAttribute('y', this.headerRowHeight);
      BackRect.setAttribute('width', headerAllColWidth);
      BackRect.setAttribute('height', (this.height * this.items.length * 3 + headerAllRowHeight));
      BackRect.setAttribute('fill', cssStyleDeclaration.getPropertyValue( "--header-row-color" ));
      svgHeaderRow.appendChild( BackRect );

      const fontSize = 16 // フォントサイズ
      const standardForeColor = cssStyleDeclaration.getPropertyValue("--standard-forecolor")  // 文字色(黒)
      // 行ヘッダ文字列描画
      for(let y = 0;y < this.items.length; y++)
      {
        const yPos = (this.nameHeight * y) + headerAllRowHeight;  // 描画位置Y
        // 所属
        const regionText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        regionText.setAttribute('text-anchor', "middle");
        regionText.setAttribute('dominant-baseline', "central");
        regionText.setAttribute('fill', standardForeColor);
        regionText.setAttribute('font-weight', 'normal');
        regionText.setAttribute('font-size', fontSize);
        regionText.setAttribute('font-family', "Roboto");
        regionText.setAttribute('style', "user-select:none;");
        const regionTextDomRect = new DOMRect(0,yPos,this.regionColWidth,this.nameHeight)
        this.autoLineBreak(this._getRegion(this.items[y].region_id),regionTextDomRect,regionText,svgHeaderRow)
        // 役職
        const positionText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        positionText.setAttribute('text-anchor', "middle");
        positionText.setAttribute('dominant-baseline', "central");
        positionText.setAttribute('fill', standardForeColor);
        positionText.setAttribute('font-weight', 'normal');
        positionText.setAttribute('font-size', fontSize);
        positionText.setAttribute('font-family', "Roboto");
        positionText.setAttribute('style', "user-select:none;");
        const positionTextDomRect = new DOMRect(this.regionColWidth,yPos,this.headerColWidth,this.nameHeight)
        this.autoLineBreak(this.items[y].position,positionTextDomRect,positionText,svgHeaderRow)
        // 名前
        const nameText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        nameText.setAttribute('text-anchor', "middle");
        nameText.setAttribute('dominant-baseline', "central");
        nameText.setAttribute('fill', standardForeColor);
        nameText.setAttribute('font-weight', 'normal');
        nameText.setAttribute('font-size', fontSize);
        nameText.setAttribute('font-family', "Roboto");
        nameText.setAttribute('style', "user-select:none;");
        const nameTextDomRect = new DOMRect((this.headerColWidth+this.regionColWidth),yPos,this.headerColWidth,this.nameHeight)
        this.autoLineBreak(this.items[y].name,nameTextDomRect,nameText,svgHeaderRow)
      }
      const borderColor = cssStyleDeclaration.getPropertyValue("--border-color") //線色
      // 初回の縦線
      const VerticalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      VerticalLine.setAttribute('x1',(0 + this.adjust));
      VerticalLine.setAttribute('y1',(headerAllRowHeight + this.adjust));
      VerticalLine.setAttribute('x2',(0 + this.adjust));
      VerticalLine.setAttribute('y2',(this.height * this.items.length * 3 + headerAllRowHeight + this.adjust));
      VerticalLine.setAttribute('stroke', borderColor);
      VerticalLine.setAttribute('stroke-width', 1);
      svgHeaderRow.appendChild( VerticalLine );
      for(let x = 0; x < this.headerColCount + 1; x++)
      {
        const xPos = (this.headerColWidth * x) + this.regionColWidth // 描画位置X
        // 縦線描画
        const VerticalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        VerticalLine.setAttribute('x1',(xPos + this.adjust));
        VerticalLine.setAttribute('y1',(headerAllRowHeight + this.adjust));
        VerticalLine.setAttribute('x2',(xPos + this.adjust));
        VerticalLine.setAttribute('y2',(this.height * this.items.length * 3 + headerAllRowHeight + this.adjust));
        VerticalLine.setAttribute('stroke', borderColor);
        VerticalLine.setAttribute('stroke-width', 1);
        svgHeaderRow.appendChild( VerticalLine );
      }
      for (let y=0; y<this.items.length + 1; y++)
      {
        const yPos = (this.nameHeight * y) + headerAllRowHeight; // 描画位置Y
        // 横線描画
        const HorizontalLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        HorizontalLine.setAttribute('x1',(0 + this.adjust));
        HorizontalLine.setAttribute('y1',(yPos + this.adjust));
        HorizontalLine.setAttribute('x2',(headerAllColWidth + this.adjust));
        HorizontalLine.setAttribute('y2',(yPos + this.adjust));
        HorizontalLine.setAttribute('stroke', borderColor);
        HorizontalLine.setAttribute('stroke-width', 1);
        svgHeaderRow.appendChild( HorizontalLine );
      }
    },
    // スクロールイベント
    svgScroll: function(){
      console.log("svgScroll")
      const svgScrollBar = document.getElementById( "svgScroll" ) ; // スクロールバー
      const svgHeaderRow = document.getElementById( "svgHeaderRow" ) ;  // ヘッダ行
      const svgHeaderCol = document.getElementById( "svgHeaderCol" ) ;  // ヘッダ列
      const svgLeftTop = document.getElementById( "svgLeftTop" ) ;  // ヘッダ左上
      // スクロール位置
      const svgScrollX = svgScrollBar.scrollLeft;
      const svgScrollY = svgScrollBar.scrollTop;
      // 水平方向にスクロールした場合
      if(this.prevScrollX != svgScrollX)
      {
        // 行ヘッダを連動
        this.prevScrollX = svgScrollX
        svgHeaderRow.style.left = svgScrollX + "px";
        svgLeftTop.style.left = svgScrollX + "px";
      }
      // 垂直方向にスクロールした場合
      if(this.prevScrollY != svgScrollY)
      {
        // 列ヘッダを連動
        this.prevScrollY = svgScrollY
        svgHeaderCol.style.top = svgScrollY + "px";
        svgLeftTop.style.top = svgScrollY + "px";
      }
    },
    // マウスダウンイベント
    svgMouseDown:function(e){ 
      if(!this._ChkAuthority())return
      if(this.dragItem != null) return

      const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
      const sortableChosenBackcolor = cssStyleDeclaration.getPropertyValue("--sortable-chosen-backcolor") // ドラッグ背景色
      const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-chosen") // 選択項目枠線色
      const svg =document.getElementById("svg") // データ描画svg

      const mousePos = this.getMousePos(e)  // マウスダウン位置取得
      const dataIdx = this.getDataIdxFromPos(mousePos.x,mousePos.y)  // マウスダウン位置から矩形情報取得

      if(dataIdx != null){
        // 矩形内データ
        const data = this.getDataFromIdx(dataIdx.x,dataIdx.y,dataIdx.no);
        if(data == null) return
        // 取得した矩形情報にデータが存在する場合
        if(data.kind != 0 && this.chkLeaderAuthority(this.items[dataIdx.y].id))
        {
          svg.setAttribute('style', 'cursor:grabbing'); // マウスカーソルをドラッグ用に変更
          // 選択フラグONのデータをOFFにする
          for(let yIdx = 0;yIdx < this.items.length;yIdx++){
            for(let xIdx = 0;xIdx < this.days.length;xIdx++){
              if(this.items[yIdx].fields[xIdx] != null){
                if(this.items[yIdx].fields[xIdx].no1.isSelected == true) this.items[yIdx].fields[xIdx].no1.isSelected = false
                if(this.items[yIdx].fields[xIdx].no2.isSelected == true) this.items[yIdx].fields[xIdx].no2.isSelected = false
                if(this.items[yIdx].fields[xIdx].no3.isSelected == true) this.items[yIdx].fields[xIdx].no3.isSelected = false
              }
            }
          }
          data.isSelected = true  // 選択フラグON
          this.dragItem = dataIdx // ドラッグデータを保持
          // ドラッグ用の背景オブジェクトを作成
          const dragRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          dragRect.setAttribute('id', "dragRect");
          dragRect.setAttribute('x', dataIdx.domRect.x + this.padding);
          dragRect.setAttribute('y', dataIdx.domRect.y + this.padding);
          dragRect.setAttribute('width', dataIdx.domRect.width - (this.padding * 2) + 1);
          dragRect.setAttribute('height', dataIdx.domRect.height - (this.padding * 2) + 1);
          dragRect.setAttribute('fill', sortableChosenBackcolor);
          svg.appendChild( dragRect );
          // ドラッグ元の背景オブジェクトは一時非表示にする
          const rect = document.getElementById('rect' + 'x' + dataIdx.x + 'y' + dataIdx.y + 'no' + dataIdx.no)
          if(rect != null)rect.setAttribute('visibility',"hidden") 

          const itemText = document.getElementById('text' + 'x' + dataIdx.x + 'y' + dataIdx.y + 'no' + dataIdx.no)  // 選択セルのテキスト取得
          if(itemText != null)
          {
            // ドラッグ用のテキストオブジェクトを作成
            let dragText = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            dragText = itemText.cloneNode(true);  // 選択セルのテキストをコピー
            dragText.setAttribute('id', "dragText");
            svg.appendChild( dragText );
            // ドラッグ元のテキストオブジェクトは一時非表示にする
            itemText.setAttribute('visibility',"hidden") 
          }
          this.addEditMode = 1; // 編集モード
        }
        else
        {
          // データが未設定の場合、追加モード
          this.addEditMode = 0;
        }
        // 編集データを取得
        console.log("dataIdx")
        console.log(dataIdx)
        this.sendAddEdit.date = this.days[dataIdx.x].date;
        this.sendAddEdit.user_id = this.items[dataIdx.y].id;
        this.sendAddEdit.assign_time = data.time;
        this.sendAddEdit.kind = data.kind;
        this.sendAddEdit.cstm_id = data.cstm_id;
        this.sendAddEdit.location = data.client_visit;
        this.sendAddEdit.temp_flg = data.tentative_regist;
        this.sendAddEdit.koma = dataIdx.no + 1;
        this.sendAddEdit.free_task = data.task_full;
        this.sendAddEdit.work_text = data.work_text;
        this.sendAddEdit.assign_cstm_id = data.assign_cstm_id;
        this.sendAddEdit.task_assign_id = data.task_assign_id;
        this.$store.dispatch("selStaffClient", {
          staff: this.items[dataIdx.y].id,
          client: data.cstm_id,
          date: this.days[dataIdx.x].date,
        });

        // 選択項目枠線が既に存在する場合は削除する
        if(document.getElementById("svgSelectRect") != null) svg.removeChild(document.getElementById("svgSelectRect"));
        // 選択項目枠線を描画
        const svgSelectRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        svgSelectRect.setAttribute('id', "svgSelectRect");
        svgSelectRect.setAttribute('x', dataIdx.domRect.x + 2);
        svgSelectRect.setAttribute('y', dataIdx.domRect.y + 2);
        svgSelectRect.setAttribute("width", dataIdx.domRect.width - 3);
        svgSelectRect.setAttribute("height", dataIdx.domRect.height - 3);
        svgSelectRect.setAttribute('stroke', sortableChosen);
        svgSelectRect.setAttribute('stroke-width', 2);
        svgSelectRect.setAttribute('fill-opacity', 0);
        svgSelectRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
        svg.appendChild( svgSelectRect );

        // ボタン有効無効状態更新
        this.chgIsReadOnly(!this.chkLeaderAuthority(this.sendAddEdit.user_id))
      }
    },
    // マウスアップイベント
    svgMouseUp:function(){
      // if(!this._ChkAuthority())return
      if(this.dragItem == null) return
      const svg =document.getElementById("svg")
      svg.setAttribute('style', 'cursor:grab'); //ドラッグ可

      // ドラッグテキスト位置取得
      const dragText =document.getElementById('dragText')
      if(dragText == null) return
      const textPosX = dragText.getAttribute('x')
      const textPosY = dragText.getAttribute('y')
      // テキスト位置から矩形情報取得
      const dataIdx = this.getDataIdxFromPos(textPosX,textPosY)
      if(dataIdx != null)
      { 
        // 移動元データ
        let fromData = this.getDataFromIdx(this.dragItem.x,this.dragItem.y,this.dragItem.no);
        if(fromData == null) return
        // 移動先データ
        let data = this.getDataFromIdx(dataIdx.x,dataIdx.y,dataIdx.no);
        if(data == null) return
        
        const dragRect = document.getElementById("dragRect")
        const rectItem = document.getElementById('rect' + 'x' + this.dragItem.x + 'y' + this.dragItem.y + 'no' + this.dragItem.no)
        const dragText = document.getElementById("dragText")
        const textItem = document.getElementById('text' + 'x' + this.dragItem.x + 'y' + this.dragItem.y + 'no' + this.dragItem.no)
        if(!this.chkLeaderAuthority(this.items[dataIdx.y].id)){
          // 編集不可セルの場合
          // ドラッグ元の背景オブジェクト表示
          if(rectItem != null) rectItem.setAttribute('visibility',"visible")
          // ドラッグ用背景を削除
          if(dragRect != null) svg.removeChild(dragRect);
          // ドラッグ元のテキストオブジェクトを表示
          if(textItem != null) textItem.setAttribute('visibility',"visible")
          // ドラッグ用テキストを削除
          if(dragText != null) svg.removeChild(dragText);
        }
        else{
          // ドラッグ元の背景オブジェクトをドラッグ位置へ移動し、表示
          if((rectItem != null) && (dragRect != null)) {
            rectItem.setAttribute('x',dragRect.getAttribute('x'))
            rectItem.setAttribute('y',dragRect.getAttribute('y'))
            rectItem.setAttribute('width',dragRect.getAttribute('width'))
            rectItem.setAttribute('height',dragRect.getAttribute('height'))
            rectItem.setAttribute('visibility',"visible")  
          }
          // ドラッグ用背景を削除
          if(dragRect != null) svg.removeChild(dragRect);
          // ドラッグ元のテキストオブジェクトをドラッグ位置へ移動し、表示
          if((textItem != null) && (dragText != null)){
            textItem.setAttribute('x',dragText.getAttribute('x'))
            textItem.setAttribute('y',dragText.getAttribute('y'))
            textItem.setAttribute('visibility',"visible")
          }
          // ドラッグ用テキストを削除
          if(dragText != null) svg.removeChild(dragText);
          // ドラッグ元データとドラッグ先データが異なる場合
          if(fromData != data)
          {
            // データ変更
            var dSendData = {
              token: this.$store.state.token,
              userId: this.$store.state.user.id,
              from: {
                drag_user_id: this.items[this.dragItem.y].id,
                cstm_id: fromData.cstm_id,
                assign_cstm_id: fromData.assign_cstm_id,
                task_assign_id: fromData.task_assign_id,
                koma: this.dragItem.no + 1,
                date: this.days[this.dragItem.x].date,
                temp_flg: fromData.tentative_regist,
                work_text: fromData.work_text,
              },
              to: {
                drag_user_id: this.items[dataIdx.y].id,
                koma: dataIdx.no + 1,
                date: this.days[dataIdx.x].date,
              },
            }
            console.log(dSendData);
            // 実行
            axios
              .post(this.URL +'DragDrop', dSendData,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
              )
              .then((res) => {
                // console.log(res);
                if(res.data.ret){
                  // 成功処理
                  console.log("OK");
                  // 再描画
                  this.GetTop();
                } else {
                  // エラー処理
                  console.log("NG");
                  if(res.data.locked == 1){
                    // ロック中の場合、編集不可ダイアログ表示
                    this.lockMsgBar = true;
                  }
                  else if(res.data.errCode == 2){
                    alert("既にデータが存在するコマへは移動できません");
                    this.items.splice(0, this.items.length);
                  }
                  else{
                    // 編集失敗ダイアログ表示
                    this.errMsgBar = true;
                  }
                  this.GetTop();
                  // トークンエラーチェック
                  this._chkToken(res.data.errToken);
                }
              }
            );
            // 編集データをリセット
            this.sendAddEdit = {
              date: "",
              id: "",
              no: "",
              abbreviation: "",
              tentativeRegist: "",
              place: "",
              workContent: "",
              task: "",
            },
            this.$store.dispatch("selStaffClient", {
              staff: "",
              client: "",
              date: "",
            });
            this.copyItem = null; // コピー選択中データ削除
            this.addEditMode = 0; // 追加モード
            // 選択枠線を削除
            const svgSelectRect = document.getElementById("svgSelectRect")
            if(svgSelectRect != null) svg.removeChild(svgSelectRect);
            // 選択フラグONのデータをOFFにする
            for(let yIdx = 0;yIdx < this.items.length;yIdx++){
              for(let xIdx = 0;xIdx < this.days.length;xIdx++){
                if(this.items[yIdx].fields[xIdx] != null){
                  if(this.items[yIdx].fields[xIdx].no1.isSelected == true) this.items[yIdx].fields[xIdx].no1.isSelected = false
                  if(this.items[yIdx].fields[xIdx].no2.isSelected == true) this.items[yIdx].fields[xIdx].no2.isSelected = false
                  if(this.items[yIdx].fields[xIdx].no3.isSelected == true) this.items[yIdx].fields[xIdx].no3.isSelected = false
                }
              }
            }
          }
        }
      }
      // ドラッグデータ削除
      this.dragItem = null;
    },
    // マウス移動時イベント
    svgMouseMove:function(e){
      // if(!this._ChkAuthority())return
      const svg =document.getElementById("svg") // データ描画svg
      const mousePos = this.getMousePos(e)  // マウス位置取得
      const dataIdx = this.getDataIdxFromPos(mousePos.x,mousePos.y) // マウス位置からデータ取得
      if(dataIdx != null){
        // 矩形内データ
        const data = this.getDataFromIdx(dataIdx.x,dataIdx.y,dataIdx.no);
        if(data == null) return
        // ツールチップ表示中の場合は削除する
        if(document.getElementById("svgToolTip") != null) document.getElementById("svgToolTip").remove();
        // ドラッグ中でない場合
        if(this.dragItem == null)
        {
          // 取得データが設定されている場合
          if(data.kind != 0)
          {
            if(this.chkLeaderAuthority(this.items[dataIdx.y].id)) 
            {
              svg.setAttribute('style', 'cursor:grab'); // マウスカーソル変更：ドラッグ可
            }
            
            // ツールチップ文字
            let text = data.fullname
            if(data.kind == 1) 
            {
              text = data.fullname
              if(data.client_visit == 2) text = text.toLowerCase() 
            }
            if(data.kind == 2) text = data.task_full
            // 文字サイズ
            const textHeight = 13 // 高さ
            // ツールチップパディング
            const toolTipRectPaddingX = 11
            const toolTipRectPaddingY = 9
            // ツールチップ矩形サイズ
            const ctxCal = document.createElement("canvas").getContext("2d");
            ctxCal.font = 'normal' + " " + 13 + "px" + " " + "Roboto";
            const metrics = ctxCal.measureText(String(text));  // 文字サイズ
            const rectWidth = metrics.width + (toolTipRectPaddingX * 2) // 幅
            const rectHeight = textHeight + (toolTipRectPaddingY * 2) // 高さ
            // ツールチップ文字列
            const toolTipText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            toolTipText.textContent = text
            toolTipText.setAttribute('id', "toolTipText");
            toolTipText.setAttribute('x', rectWidth / 2);
            toolTipText.setAttribute('y', rectHeight / 2);
            toolTipText.setAttribute('text-anchor', "middle");
            toolTipText.setAttribute('dominant-baseline', "central");
            toolTipText.setAttribute('fill', '#fff');
            toolTipText.setAttribute('font-weight', 'normal');
            toolTipText.setAttribute('font-size', 13);
            toolTipText.setAttribute('font-family', "Roboto");
            toolTipText.setAttribute('style', "user-select:none;");
            // ツールチップ矩形
            const toolTipRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            toolTipRect.setAttribute('id', "toolTipRect");
            toolTipRect.setAttribute('x', 0);
            toolTipRect.setAttribute('y', 0);
            toolTipRect.setAttribute('width', rectWidth);
            toolTipRect.setAttribute('height', rectHeight);
            toolTipRect.setAttribute('rx', 3);
            toolTipRect.setAttribute('ry', 3);
            toolTipRect.setAttribute('fill', '#888');
            // ツールチップを表示
            const svgToolTip = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svgToolTip.setAttribute('id', 'svgToolTip');
            svgToolTip.setAttribute('width', rectWidth);
            svgToolTip.setAttribute('height', rectHeight);
            svgToolTip.style.top = Math.floor(Math.floor(dataIdx.domRect.y + this.padding - 1 + dataIdx.domRect.height / 2 - dataIdx.domRect.height - dataIdx.domRect.height / 4) - rectHeight / 2) + "px"
            svgToolTip.style.left = Math.floor(Math.floor(dataIdx.domRect.x + dataIdx.domRect.width / 2) + 1 - rectWidth / 2) + "px"
            svgToolTip.style.position = "absolute"
            svgToolTip.style.zIndex = 5
            svgToolTip.appendChild( toolTipRect );
            svgToolTip.appendChild( toolTipText );
            document.getElementById("svgScroll").appendChild( svgToolTip );
          }
          // データ未設定の場合
          else  svg.setAttribute('style', 'cursor:default'); // マウスカーソル変更：デフォルト
        }
        // ドラッグ中の場合
        else
        {
          // 取得したデータが未設定か元の位置の場合はドラッグする
          if(data.kind == 0 || (this.dragItem.domRect.x == dataIdx.domRect.x && this.dragItem.domRect.y == dataIdx.domRect.y)) {
            // テキスト位置移動
            const text =document.getElementById('dragText')
            if(text != null){
              text.setAttribute('x', dataIdx.domRect.x + dataIdx.domRect.width / 2 + 0.5);
              text.setAttribute('y', dataIdx.domRect.y + dataIdx.domRect.height / 2 + 1);
            }
            // 背景移動
            const rect =document.getElementById('dragRect')
            if(rect != null){
              rect.setAttribute('x', dataIdx.domRect.x + this.padding);
              rect.setAttribute('y', dataIdx.domRect.y + this.padding);
              rect.setAttribute('width', dataIdx.domRect.width - (this.padding * 2) + 1);
              rect.setAttribute('height', dataIdx.domRect.height - (this.padding * 2) + 1);
            }
          }
          // ボタン有効無効状態更新
          this.chgIsReadOnly(!this.chkLeaderAuthority(this.items[dataIdx.y].id))
        }
      }
    },
    // 全体のマウスアップイベント(svg領域外でのドロップ処理用)
    documentMouseUp:function(e)
    {
      if(this.dragItem == null) return
      const svgHeaderCol =document.getElementById("svgHeaderCol") // ヘッダ列
      const svgHeaderRow =document.getElementById("svgHeaderRow") // ヘッダ行
      const svgScrollBar = document.getElementById("svgScroll");  // スクロールバー
      const svg =document.getElementById("svg")

      const HeaderColRect = svgHeaderCol.getBoundingClientRect();
      const HeaderRowRect = svgHeaderRow.getBoundingClientRect();

      // svg領域外の場合のみ処理
      if(e.clientX < HeaderRowRect.right || svgScrollBar.clientLeft + svgScrollBar.clientWidth < e.clientX ||
      e.clientY < HeaderColRect.bottom || svgScrollBar.clientTop + svgScrollBar.clientHeight < e.clientY)
      {
        svg.setAttribute('style', 'cursor:default'); // マウスカーソルをデフォルトに戻す
        // ドラッグ用背景を削除
        const dragRect = document.getElementById("dragRect")
        if(dragRect != null) svg.removeChild(dragRect);
        // ドラッグ用テキストを削除
        const dragText = document.getElementById("dragText")
        if(dragText != null) svg.removeChild(dragText);
        // ドラッグ元背景表示
        const rectItem = document.getElementById('rect' + 'x' + this.dragItem.x + 'y' + this.dragItem.y + 'no' + this.dragItem.no)
        if((rectItem != null) && (dragRect != null)) rectItem.setAttribute('visibility',"visible")
        // ドラッグ元テキスト表示
        const textItem = document.getElementById('text' + 'x' + this.dragItem.x + 'y' + this.dragItem.y + 'no' + this.dragItem.no)
        if((textItem != null) && (dragText != null)) textItem.setAttribute('visibility',"visible")
        // ドラッグデータ削除
        this.dragItem = null
      }
    },
    // コピーボタン押下処理
    Copy: function(){
      console.log("Copy");
      if(!this._ChkAuthority())return
      const svgSelectRect = document.getElementById("svgSelectRect");
      if (svgSelectRect==null) return;
      const dataIdx = this.getDataIdxFromPos(svgSelectRect.getAttribute('x'),svgSelectRect.getAttribute('y'))  // 選択位置から矩形情報取得
      if(dataIdx == null) return;
      if(!this.chkLeaderAuthority(this.items[dataIdx.y].id))return
      // 矩形内データ
      const data = this.getDataFromIdx(dataIdx.x,dataIdx.y,dataIdx.no);
      if(data == null) return
      // 取得した矩形情報にデータが存在する場合
      if(data.kind == 0)
      {
        console.log("データなし");
        return;
      } 

      // 選択中データをコピーデータとして記憶
      this.copyItem = null;
      this.copyItem = JSON.parse(JSON.stringify(this.sendAddEdit))

      const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
      const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-copy") // コピー選択項目枠線色
      const svg =document.getElementById("svg") // データ描画svg

      // コピー選択項目枠線が既に存在する場合は削除する
      if(document.getElementById("svgCopyRect") != null) svg.removeChild(document.getElementById("svgCopyRect"));
      // コピー選択項目枠線を描画
      const svgCopyRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      svgCopyRect.setAttribute('id', "svgCopyRect");
      svgCopyRect.setAttribute('x', svgSelectRect.getAttribute('x'));
      svgCopyRect.setAttribute('y', svgSelectRect.getAttribute('y'));
      svgCopyRect.setAttribute("width", svgSelectRect.getAttribute('width'));
      svgCopyRect.setAttribute("height", svgSelectRect.getAttribute('height'));
      svgCopyRect.setAttribute('stroke', sortableChosen);
      svgCopyRect.setAttribute('stroke-width', 2);
      svgCopyRect.setAttribute('fill-opacity', 0);
      svgCopyRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
      svg.appendChild( svgCopyRect );
    },
    // 貼付ボタン押下処理
    Paste: function(){
      console.log("Paste");
      // if(!this._ChkAuthority())return
      if(this.copyItem == null) return

      const svgSelectRect = document.getElementById("svgSelectRect");
      const dataIdx = this.getDataIdxFromPos(svgSelectRect.getAttribute('x'),svgSelectRect.getAttribute('y'))  // 選択位置から矩形情報取得
      if(dataIdx == null) return;
      if(!this.chkLeaderAuthority(this.items[dataIdx.y].id))return
      // 矩形内データ
      const data = this.getDataFromIdx(dataIdx.x,dataIdx.y,dataIdx.no);
      if(data == null) return
      // 取得した矩形情報にデータが存在する場合
      if(data.kind != 0)
      {
        console.log("データがあるため貼付禁止");
        return;
      }

      // コピーデータと選択中位置のデータが違う場合のみ貼付実行
      if(this.copyItem != this.sendAddEdit)
      {
        let sendTempFlg = false
        if (this.copyItem.temp_flg == 1) sendTempFlg = true
        // データ変更
        var dsendData = {
        mode: "add",
        token: this.$store.state.token,
        // 入力データ
        target_date: this.sendAddEdit.date,
        user_id: this.sendAddEdit.user_id,
        assign_time: this.copyItem.assign_time,
        kind: this.copyItem.kind,
        cstm_id: this.copyItem.cstm_id,
        location: this.copyItem.location,
        temp_flg: sendTempFlg,
        koma: this.sendAddEdit.koma,
        free_task: this.copyItem.free_task,
        work_text: this.copyItem.work_text,
        assign_cstm_id: this.copyItem.assign_cstm_id,
        task_assign_id: this.copyItem.task_assign_id,
        org_date: this.copyItem.date
      }
      console.log("dsendData");
      console.log(dsendData);
      axios
        .post(this.URL +'EditPlan', dsendData,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // // 成功処理
            // this.sendAddEdit = JSON.parse(JSON.stringify(this.copyItem))
            // this.sendAddEdit.user_id = copyItem.user_id;
            // this.sendAddEdit.koma =  copyItem.koma;
            // this.sendAddEdit.date =  copyItem.target_date;
            this.copyItem=null;
            // 選択枠線を削除
            const svg =document.getElementById("svg")
            const svgCopyRect = document.getElementById("svgCopyRect")
            if(svgCopyRect != null) svg.removeChild(svgCopyRect);
            // 再描画
            this.GetTop();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログ表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
            if(res.data.errMsg){ this.errors.push(res.data.errMsg); }
            // トークンエラーチェック
            this._chkToken(res.data.errToken);
          }
        }
      );
      }
    },
    // エクスポートボタン押下処理
    Export: function(){
      console.log("Export");
      console.log(this.$store.state.token);
      console.log(this.startDate);
      console.log(this.selectedDisplayDays);

      axios
          .post(this.URL +'GetTop2', {
              startDate: this.startDate,
              selectedDisplayDays: this.selectedDisplayDays,
              token: this.$store.state.token,
              cstmId: this.selClient,
            },
            { headers: {'Content-Type': "Export/xlsx"},
              responseType:'blob',
              dataType:'binary', }
          )
          .then((res) => {
            console.log(res);

            const blob = new Blob([res.data], {type: res.data.type});
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const dateTime = moment(new Date()).format("YYYYMMDDHHmmss")
            link.setAttribute("download", "アサイン管理_" + dateTime + ".xlsx");
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
          }
          );
    },
    // インポートボタン押下処理
    Import: function(){
      console.log("Import");
      this.$refs.input.click();
    },
    // インポートファイル選択
    selectedImportFile() {
      const file = this.$refs.input.files[0]
      if (!file) {
        return;
      }
        const vm = this
        var reader = new FileReader();
        reader.onload = function() {   
            let arrayBuffer = new Uint8Array(reader.result);
            let array = []
            for(let i = 0; i < arrayBuffer.length;i++){ array.push(arrayBuffer[i]) }
            // インポートデータ取得
            axios.post('Import', {
              file: array,
              token: vm.$store.state.token, 
            },
              { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
            )
            .then((res) => {
              console.log(res);
              if(!res.data.ret){
                return;
              }
              let modalImport = vm.$refs.ModalImport;
              // 取得データ
              vm.$set(modalImport.item, "data", res.data.data);
              // エラーをリセット
              vm.$set(modalImport.item, "errors", []);
              // 日付範囲
              modalImport.min_date = res.data.days[0].date;
              modalImport.max_date = res.data.days[res.data.days.length - 1].date;

              // ユーザデータリスト
              let importStaff= [];
              for(let itemDataIdx = 0;itemDataIdx< modalImport.item.data.length;itemDataIdx++){
                let canEdit = false;
                if (vm.$store.state.user.authority==2){
                  // 権限がリーダーの場合、同じ所属のユーザ以外は対象にできないようにする
                  canEdit = (vm.$store.state.user.region_id == modalImport.item.data[itemDataIdx].rid)
                }else{
                  // 権限がリーダー以外の場合、全ユーザを対象にできるようにする
                  canEdit = true
                }
                if(canEdit){
                  // 編集可能ユーザデータを追加
                  const itemData = modalImport.item.data[itemDataIdx]
                  const id = itemData.id
                  let name = itemData.nm
                  importStaff.push({id:id,name:name})
                }
              }
              modalImport.importStaff = importStaff;

              // モーダルウィンドウ(インポート用)表示
              vm.$refs.ModalImport.modalShow = true;
              // ファイル情報をクリア
              vm.$refs.input.value = null
            }
            );
        }
        reader.readAsArrayBuffer(file);
    },
    // ロック有無編集ボタン押下処理
    UpdateLocked: function(){
      console.log("UpdateLocked");
      console.log("token =" + this.$store.state.token)
      console.log("locked =" + this.$store.state.locked)

      var locked = 0;
      if (this.$store.state.locked == 0 ){
        locked = 1
      }
      axios.post(this.URL +'EditLocked', {
          token: this.$store.state.token,
          locked: locked,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        console.log(res);
        this.GetTop();
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    // リーダー編集OKか
    chkLeaderAuthority: function(user_id){
      let ret = true
      // 権限がリーダーの場合のみ処理
      if (this.$store.state.user.authority==2)
      {
        // 対象の所属ID取得
        let targetRegionId = 0
        for(let i = 0; i< this.items.length;i++){
          if(user_id == this.items[i].id){
            targetRegionId = this.items[i].region_id
            break
          }
        }
        // ログインユーザの所属と対象の所属が不一致の場合Falseを返す
        if(this.$store.state.user.region_id != targetRegionId){
          ret = false
        }
      }
      return ret;
    },
    onKeyDown (e) {
      console.log("onKeyDown")
      console.log(e.key)
      console.log(e.code)

      if (e.code == 'KeyC' && e.ctrlKey) {
        console.log("コピー")
          this.Copy()
      }
      else if (e.code == 'KeyV' && e.ctrlKey) {
        console.log("貼付")
        this.Paste()
      }
      else {
        if(this.$refs.ModalAddEdit.modalShow == true
        || this.$refs.ModalImport.modalShow == true){
          return
        }
        switch(e.key)
        {
          case 'ArrowUp':
          case 'ArrowDown':
          case 'ArrowRight':
          case 'ArrowLeft': 
            e.preventDefault();
            break;
          default: return;
        }
        if(document.getElementById("svgSelectRect") == null) {
          const data = this.getDataFromIdx(0,0,0); // 表示データ
          if(data == null) return

          if (data.kind != 0) {
            this.addEditMode = 1; // 編集モード
          }
          else {
            this.addEditMode = 0; // 追加モード
          }

          // 描画範囲
          const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
          const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-chosen") // 選択項目枠線色
          const svg =document.getElementById("svg") // データ描画svg
          const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の高さ
          const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
          const rectXPos1 = headerAllColWidth;
          const rectYPos1 = headerAllRowHeight
          const rectXPos2 = (this.width);
          const rectYPos2 = (this.height);
          const domRect = new DOMRect(rectXPos1,rectYPos1,rectXPos2,rectYPos2)
          // 選択項目枠線を描画
          const svgSelectRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
          svgSelectRect.setAttribute('id', "svgSelectRect");
          svgSelectRect.setAttribute('x', domRect.x + 2);
          svgSelectRect.setAttribute('y', domRect.y + 2);
          svgSelectRect.setAttribute("width", domRect.width - 3);
          svgSelectRect.setAttribute("height", domRect.height - 3);
          svgSelectRect.setAttribute('stroke', sortableChosen);
          svgSelectRect.setAttribute('stroke-width', 2);
          svgSelectRect.setAttribute('fill-opacity', 0);
          svgSelectRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
          svg.appendChild( svgSelectRect );

          // 編集データを取得
          this.sendAddEdit.date = this.days[0].date;
          this.sendAddEdit.user_id = this.items[0].id;
          this.sendAddEdit.assign_time = data.time;
          this.sendAddEdit.kind = data.kind;
          this.sendAddEdit.cstm_id = data.cstm_id;
          this.sendAddEdit.location = data.client_visit;
          this.sendAddEdit.temp_flg = data.tentative_regist;
          this.sendAddEdit.koma = 1;
          this.sendAddEdit.free_task = data.task_full;
          this.sendAddEdit.work_text = data.work_text;
          this.sendAddEdit.assign_cstm_id = data.assign_cstm_id;
          this.sendAddEdit.task_assign_id = data.task_assign_id;
          this.$store.dispatch("selStaffClient", {
            staff: this.items[0].id,
            client: data.cstm_id,
            date: this.days[0].date,
          });
          return
        }
        else
        {
          if (this.sendAddEdit.data != "") {
            for (let i=0; i<this.items.length; i++) {
              if (this.items[i].id == this.sendAddEdit.user_id) {
                for (let j=0; j<this.items[i].fields.length; j++) {
                  if (this.items[i].fields[j].date == this.sendAddEdit.date) {
                    var x=j, y=i;
                    var koma = this.sendAddEdit.koma;
                    if (e.key == 'ArrowUp') {
                      if (koma <=1) {
                        if (i<=0) {
                          return;
                        }
                        else {
                          y=i-1;
                        }
                        koma=3;
                      }
                      else {
                        koma--;
                      }
                    }
                    else if (e.key == 'ArrowDown') {
                      if (3 <= koma) {
                        if ((this.items.length-1) <= i) {
                          return;
                        }
                        else {
                          y=i+1;
                        }
                        koma=1;
                      }
                      else {
                        koma++;
                      }
                    }
                    else if (e.key == 'ArrowRight') {
                      if ((this.items[i].fields.length-1) <= j) {
                        return;
                      }
                      else {
                        x=j+1;
                      }
                    }
                    else if (e.key == 'ArrowLeft') {
                      if (j<=0) {
                        return;
                      }
                      else {
                        x=j-1;
                      }
                    }
                    else {
                      return;
                    }
                    const data = this.getDataFromIdx(x,y,koma-1); // 表示データ
                    if(data == null) return

                    if (data.kind != 0) {
                      this.addEditMode = 1; // 編集モード
                    }
                    else {
                      this.addEditMode = 0; // 追加モード
                    }

                    // 描画範囲
                    const cssStyleDeclaration = getComputedStyle( document.getElementById( "field" ), null ); // fieldスタイル
                    const sortableChosen = cssStyleDeclaration.getPropertyValue("--sortable-chosen") // 選択項目枠線色
                    const svg =document.getElementById("svg") // データ描画svg
                    const headerAllColWidth = (this.headerColWidth * this.headerColCount) + this.regionColWidth;  // ヘッダ列全体の高さ
                    const headerAllRowHeight = this.monthYearHeight + (this.headerRowHeight * this.headerRowCount);  // ヘッダ行全体の高さ
                    const rectXPos1 = ((this.width * x) + headerAllColWidth);
                    const rectYPos1 = ((this.height * (y * 3))+ (this.height * (koma-1)) + headerAllRowHeight)
                    const rectXPos2 = (this.width);
                    const rectYPos2 = (this.height);
                    const domRect = new DOMRect(rectXPos1,rectYPos1,rectXPos2,rectYPos2)
                    // 選択項目枠線が既に存在する場合は削除する
                    if(document.getElementById("svgSelectRect") != null) svg.removeChild(document.getElementById("svgSelectRect"));
                    // 選択項目枠線を描画
                    const svgSelectRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    svgSelectRect.setAttribute('id', "svgSelectRect");
                    svgSelectRect.setAttribute('x', domRect.x + 2);
                    svgSelectRect.setAttribute('y', domRect.y + 2);
                    svgSelectRect.setAttribute("width", domRect.width - 3);
                    svgSelectRect.setAttribute("height", domRect.height - 3);
                    svgSelectRect.setAttribute('stroke', sortableChosen);
                    svgSelectRect.setAttribute('stroke-width', 2);
                    svgSelectRect.setAttribute('fill-opacity', 0);
                    svgSelectRect.setAttribute('style', "z-index:1;position:absolute;user-select:none;");
                    svg.appendChild( svgSelectRect );
                    // 表示範囲外の場合はスクロール
                    this.selectCellScroll()

                    // 編集データを取得
                    this.sendAddEdit.date = this.days[x].date;
                    this.sendAddEdit.user_id = this.items[y].id;
                    this.sendAddEdit.assign_time = data.time;
                    this.sendAddEdit.kind = data.kind;
                    this.sendAddEdit.cstm_id = data.cstm_id;
                    this.sendAddEdit.location = data.client_visit;
                    this.sendAddEdit.temp_flg = data.tentative_regist;
                    this.sendAddEdit.koma = koma;
                    this.sendAddEdit.free_task = data.task_full;
                    this.sendAddEdit.work_text = data.work_text;
                    this.sendAddEdit.assign_cstm_id = data.assign_cstm_id;
                    this.sendAddEdit.task_assign_id = data.task_assign_id;
                    this.$store.dispatch("selStaffClient", {
                    staff: this.items[y].id,
                    client: data.cstm_id,
                    date: this.days[x].date,
                    });
                    // ボタン有効無効状態更新
                    this.chgIsReadOnly(!this.chkLeaderAuthority(this.sendAddEdit.user_id))
                    return
                  }
                }
              }
            }
          }
        }
      }
    },
    chgIsReadOnly: function(disabled) {
      this.isReadOnly = disabled;
    },
  },
}
</script>

<style scoped>
  #control .locked{
    background-color: #f00 !important;
  }
  #control ul > li{
    padding: 2px 4px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }
  #control > div:nth-child(2) {
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 7px;
  }
  #control .selectScail
  {
    width:130px;
  }
  #control .boldfont
  {
    font-weight: 1000;
  }
  #svgScroll{
    grid-area: content;
    overflow: auto;
    max-height: 75vh;
    position:relative;
  }
  #field > div.short{
    display: inline-block;
  }
  #field{
    --standard-backcolor:white;
    --standard-forecolor: #000;
    --border-color: #999;
    --header-col-color: #c9daff;
    --header-row-color: #f2f2f2;
    --header-left-top-forecolor:#00f;
    --holiday-color:#eee;
    --sunday-color:#f00;
    --saturday-color:#00f;
    --is-over-color:#f00;
    --tentative-regist-color:#00f;
    --sortable-chosen:#2ad530;
    --sortable-chosen-backcolor:#4caf50;
    --sortable-copy:#f00;
  }
  #svgHeaderCol
  {
    top:0px;
    left:0px;
    position:absolute;
    z-index:1;
  }
  #svgHeaderRow
  {
    top:0px;
    left:0px;
    position:absolute;
    z-index:2;
  }
  #svgLeftTop
  {
    top:0px;
    left:0px;
    position:absolute;
    z-index:3;
  }
  #svgToolTip
  {
    top:0px;
    left:0px;
    position:absolute;
    z-index:4;
  }
  #field table{
    width: 100%;
    min-width: 1480px;
    table-layout: fixed;
    border-collapse: separate;
    box-sizing: border-box;
  }
  #field > div.short table{
    width: auto;
    min-width: auto;
  }
  #field table th{
    position: sticky;
    top: 0;
    background-color: white;
    /* color: white; */
  }
  #field table thead tr:nth-child(1) th:not(:first-child){
    font-weight: bold;
    font-size: 1.5em;
    background: #c9daff;
    white-space: nowrap;
    overflow: hidden;
  }
  #field table th,
  #field table td
  {
    /* min-width: 30px; */
    /* width: 35px; */
    /* height: 30px; */
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #999 !important;
    border-bottom: 1px solid #999;
    padding: 2px;
  }

  #field table tr:nth-child(1) th:nth-child(1){
    border-left: 1px solid #999;
  }

  #field table colgroup col:nth-child(1){ width: 90px !important; }
  #field table colgroup col:nth-child(2){ width: 90px !important; }
  #field table colgroup col:nth-child(n+3){ min-width: 40px !important; }

  /* #field table .bgBlue{
    background-color: ;
  } */

  #field table .sun,
  #field table .sat,
  #field table .hol
  {
    background-color: #eee;
  }
  #field table th.sun,
  #field table th.hol{
    color: #f00;
  }
  #field table th.sat{
    color: #00f;
  }

  #field table thead tr:nth-child(1) th:nth-child(1),
  #field table tbody tr:nth-child(1) th:nth-child(1)
  {
    position: sticky;
    left: 0;
    z-index: 3;
    /* min-width: 90px; */
  }
  #field table tbody tr:nth-child(1) th:nth-child(1){
    z-index: 2;
  }
  #field table tbody tr:nth-child(1) th:nth-child(2)
  {
    position: sticky;
    left: 90px;
    z-index: 2;
    /* min-width: 90px; */
  }
  #field table thead tr:nth-child(1) th:not(:first-child)
  {
    position: sticky;
    z-index: 1;
    top: 0;
    height: 20px;
    /* min-width: 20px; */
  }
  #field table thead tr:nth-child(2) th
  {
    position: sticky;
    z-index: 1;
    top: 42px;
  }
  #field table thead tr:nth-child(3) th
  {
    position: sticky;
    z-index: 1;
    top: 71px;
  }
  #field table tbody th{
    background: #f2f2f2;
  }
  #field table tbody td{
    /* min-width: 20px; */
    height: 29px;
  }
  #field .handle:hover{
    cursor: grab;
  }
  #field .handle:active{
    cursor: grabbing;
  }
  #field .sortable-chosen {
    background-color: #4caf50 !important;
  }

  #field .fixed01{
    border-top: 1px solid #999;
    background: #c9daff;
  }

  .tentative_regist{
    color: #00f;
  }
  .selClient{
    width: 500px;
  }
  .selRegion{
    width: 200px;
  }
</style>
