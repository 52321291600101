<template>
  <div class="team" :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="チーム管理画面"></Header>
    
    <div id="contents">
      <div id="main">
        <h2 class="title">チーム管理画面</h2>
        <v-card
          id="control"
          class="d-flex justify-end"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul>
              <li><BtnAdd @add="add"/></li>
            </ul>
          </v-card>
        </v-card>

        <div id="field">
          <div>
            <table class="dataList">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
              </colgroup>
              <thead>
                <tr class="color">
                  <th>&nbsp;</th>
                  <th colspan="3">チーム名</th>
                </tr>
              </thead>
              <draggable v-model="items" tag="tbody" :options="{ handle: '.handle', animation: 150 }" @end="onDragEnd">
                <tr v-for="item, index in items" :key="item.id">
                  <td><v-icon class="handle">mdi-menu</v-icon></td>
                  <td class="tL">{{ item.text }}</td>
                  <td><BtnEdit @edit="edit(item)"/></td>
                  <td><BtnDel @del="del(item, index)"/></td>
                </tr>
              </draggable>
            </table>
          </div>
        </div><!-- /#field -->
      </div>

       <!-- モーダルウィンドウ  -->
      <ModalTeamAddEdit ref="ModalTeamAddEdit" :pntItems="items" @GetTeam="GetTeam"></ModalTeamAddEdit>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalTeamAddEdit from '@/components/management/ModalTeamAddEdit.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
import draggable from 'vuedraggable';
import axios from 'axios';

// データ読み込み（仮）
// import teams from '/public/data/team';

export default {
  name: 'Team',
  components:{
    Header,
    ModalTeamAddEdit,
    BtnAdd,
    BtnEdit,
    BtnDel,
    draggable,
  },

  // サインインチェック
  beforeMount() {
    // if(this.$route.query.auth !== 'authenticated'){
    //   this.$route.push({ name: 'Login', query: { next: 'Client' } });
    // }
  },
  
  data() {
    return {
      items: [],
      sendData: {},
    }
  },
  mounted() {
    this.GetTeam();
  },
  methods: {
    GetTeam: function(){
      // ロック判定
      axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
      
      // this.items = teams;
      // データ取得
      console.log(this.$store.state.user.id);
      axios.post(this.URL +'management/GetTeam', {
          token: this.$store.state.token,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);
        
        //デバッグ用にconsoleに出力
        console.log(res);
        this.items = res.data.data;
        // console.log(this.items);
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    add: function(){
      console.log("add");
      this.$set(this.$refs.ModalTeamAddEdit.item, 'id', null);
      this.$set(this.$refs.ModalTeamAddEdit.item, 'text', "");
      this.$refs.ModalTeamAddEdit.modalShow = true;
    },
    edit: function(item){
      console.log("edit");
      this.$set(this.$refs.ModalTeamAddEdit.item, 'id', item.id);
      this.$set(this.$refs.ModalTeamAddEdit.item, 'text', item.text);
      this.$refs.ModalTeamAddEdit.modalShow = true;
    },
    del: function(item, index){
      console.log("del");
      console.log(item);
      if(window.confirm("削除しますか？")){
        axios
          .post(this.URL +'management/EditTeam', {
              mode: "del",
              userId: this.$store.state.user.id,
              item: item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              this.items.splice(index, 1);
            } else {
              // エラー処理
            }
          }
        );
      }
    },
    // ドラッグ並び替え
    onDragEnd: function($event){
      // console.log(this.items);
      // console.log($event);

      // ドラッグ先が同じ場合は処理しない
      if($event.newIndex != $event.oldIndex){
        console.log($event);
        // 入れ替えの配列の最初と最後を設定
        // var idxStart = this.items[$event.newIndex].display_order;
        // var idxEnd = this.items[$event.oldIndex].display_order;
        var idxStart = null;
        var idxEnd = null;
        if($event.newIndex > $event.oldIndex){
          idxStart = this.items[$event.newIndex].display_order;
          idxEnd = this.items[$event.newIndex-1].display_order;
        } else {
          idxStart = this.items[$event.newIndex].display_order;
          idxEnd = this.items[$event.newIndex+1].display_order;
        }

        console.log(this.items[0]);
        console.log(this.items[0].display_order);
        console.log(this.items[$event.newIndex]);
        console.log(this.items[$event.newIndex].display_order);

        console.log("Drag");
        console.log(this.items);
        console.log("Start:"+ idxStart);
        console.log("End:"+ idxEnd);

        // 送るデータを生成
        var sendData = [];
        for(var i=idxStart; i<=idxEnd; i++){
          sendData.push(this.items[i]);
        }
        // console.log(sendData);
        axios
          .post(this.URL +'management/EditTeam', {
              mode: "change_display",
              userId: this.$store.state.user.id,
              start: idxStart,
              end: idxEnd,
              items: sendData,
              token: this.$store.state.token,
              // item: item,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              axios.post(this.URL +'management/GetTeam', {
                  token: this.$store.state.token,
                },
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
              )
              .then(function(res){
                // ログインチェック
                // this._loginChk(res.data, this.$store.state.token);
                
                //デバッグ用にconsoleに出力
                console.log(res);
                // this.items.splice(0, this.items.length);
                // this.items.push(res.data.data);
                // for(var i=0; i<res.data.data.length; i++){
                //   this.$set(this.items[i], 'display_order', res.data.data[i].display_order);
                // }
                this.GetTeam();
                // console.log(this.items);
              }.bind(this))
              .catch(function(error){
                  console.log(error);
              });
              // this.items.splice(index, 1);
            } else {
              // エラー処理
            }
          }
        );
      }

      // console.log(idxStart, idxEnd);
    }
  },
  // components: {
  //   Login
  // }
}
</script>

<style scoped>
  #main{
    width: 600px;
  }
  #control ul > li{
    padding: 2px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }

  #field table.dataList{
    width: auto;
    min-width: 100%;
    /* table.dataList-layout: fixed; */
    border-collapse: collapse;
    box-sizing: border-box;
    margin-top: 0.5em;
  }
  #field table.dataList colgroup col:nth-child(1){ width: 3.0em; }
  #field table.dataList colgroup col:nth-child(3),
  #field table.dataList colgroup col:nth-child(4){ width: 5.0em; }
  #field table.dataList th{
    /* position: sticky;
    top: 0;
    background-color: black;
    color: white; */
  }
  #field table th,
  #field table td
  {
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #ccc;
    padding: 3px 10px;
  }

  #field .handle:hover{
    cursor: grab;
  }
  #field .handle:active{
    cursor: grabbing;
  }
  #field .sortable-chosen {
    background-color: #4caf50;
  }
  #field table thead th{
    font-weight: 900 !important;
  }
</style>
