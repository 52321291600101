<template>
  <div id="modal" v-show="modalShow">
    <div id="overlay">
      <div class="modal-content addedit">
        <div v-if="errors.length" class="errorList">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>

        <div class="flex jcSB date">
          <div>
            <label>日付</label><br>
            <v-menu
              v-model="selDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.date"
                @input="selDate = false"
                locale="jp-ja"
                :day-format="date => new Date(date).getDate()"
              ></v-date-picker>
            </v-menu>
          </div>
          <div>
            <label>時間</label><br>
            <v-select
              v-model="item.time"
              :items="optTime"
              item-text="text"
              item-value="id"
              dense
              outlined
            >
            </v-select>
          </div>
          <div>
            <label>ユーザ</label><br>
            <v-select
              v-model="item.id"
              :items="optStaff"
              item-text="name"
              item-value="id"
              dense
              outlined
            >
            </v-select>
          </div>
        </div>
        <div class="detail">
          <dl>
            <dt>●クライアント</dt>
            <dd>
              <div class="flex jcFS twoColumn">
                <div>
                  <v-select
                    v-model="item.abbreviation"
                    :items="optClient"
                    item-text="abbreviation"
                    item-value="id"
                    class="select-input-none mb-n7"
                    dense
                    outlined
                    hide-details="false"
                    @change="onChgClient"
                  >
                  </v-select>
                </div>
                <div>
                  <v-radio-group
                    v-model="item.place"
                    row
                    class="mt-1"
                  >
                    <v-radio
                      v-for="radio in rdoClientVisit" :key="radio.id"
                      :label="radio.text"
                      :value="radio.id"
                    >
                    </v-radio>
                  </v-radio-group>
                </div>
              </div>
            </dd>
            <dt>作業内容</dt>
            <dd class="mb-4">
              <v-text-field
                v-model="item.workContent"
                name="iptWorkContent"
                id="iptWorkContent"
                class="input"
                :class="{disabled: this.item.kind != 1}"
                :readonly="this.item.kind != 1"
                outlined
                dense
                hide-details="false"
              ></v-text-field>
            </dd>

            <dd>
                <v-row>
                  <v-col class="col-auto">
                    <v-switch
                      v-model="item.tentativeRegist"
                      class="v-input--reverse"
                      dense
                    >
                      <template #label>
                        仮設定：
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
            </dd>

            <dt>〇任意入力タスク</dt>
            <dd>
              <v-text-field
                v-model="item.task"
                name="iptTask"
                id="iptTask"
                class="input"
                :class="{disabled: this.item.kind == 1}"
                :readonly="this.item.kind == 1"
                area
                outlined
                dense
              ></v-text-field>
            </dd>
          </dl>
        </div>
        <div class="flex jcSB">
          <v-btn depressed color="success" width="100" @click="submitAddEdit">OK</v-btn>
          <v-btn depressed color="primary" width="100" @click="modalClose">CANCEL</v-btn>
        </div>
      </div>

      <!-- ロック時の編集不可ダイアログ -->
      <v-snackbar
        v-model="lockMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        ロック中のため、編集できません。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="lockMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- 編集失敗ダイアログ -->
      <v-snackbar
        v-model="errMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        編集に失敗しました。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="errMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "childAddEdit",
  props:{
    pntItems: {
      type: Array,
      required: false,
    },
    pntMethodName: String,
  },
  data() {
    return {
      mode: "",
      item: {
        "date": "",
        "time": null,
        "id": null,
        "no": null,
        "abbreviation": null,
        "place": null,
        "workContent": "",
        "task": "",
        "tentativeRegist": false,
        "kind": null,
        "assign_cstm_id": null,
        "task_assign_id": null,
      },

      // モーダル
      modalShow: false,
      // selDate: false,
      date: "",
      selDate: false,
      orgDate: "",
      optTime: [
        {id: 2, text: "終日"},
        {id: 1, text: "半日"},
      ],
      optStaff: [],
      optClient: [],
      rdoClientVisit: [
        {id: 1, text: "住査"},
        {id: 2, text: "事務所"},
      ],
      iptWorkContent: "",
      iptTask: "",
      clientFlg: false,
      errors: [],

      // ロック時の編集不可ダイアログ
      lockMsgBar: false,
      // 編集失敗ダイアログ
      errMsgBar: false,
    };
  },
  mounted() {
    axios.post(this.URL +'GetUserList', {
        staffId: this.$store.state.user.id,
        isEditableOnly: true,
        token: this.$store.state.token,
      },
      { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
    )
    .then(function(res){
      // ログインチェック
      // this._loginChk(res.data, this.$store.state.token);

      //デバッグ用にconsoleに出力
      // console.log(res);

      this.optStaff = res.data.data;
      console.log("this.optStaff");
      console.log(this.optStaff);
    }.bind(this))
    .catch(function(error){
        console.log(error);
    });

    axios.post(this.URL +'management/GetClient', {
        token: this.$store.state.token,
      },
      { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
    )
    .then(function(res){
      // ログインチェック
      // this._loginChk(res.data, this.$store.state.token);

      //デバッグ用にconsoleに出力
      // console.log(res);

      // this.optClient = res.data.data;
      this.optClient.splice(0, this.optClient.length);
      this.optClient.push({ id: -1, abbreviation: "" });
      for(var i=0; i<res.data.data.length; i++){
        this.optClient.push({ id: res.data.data[i].id, abbreviation: res.data.data[i].abbreviation });
      }
      console.log("this.optClient");
      console.log(this.optClient);
    }.bind(this))
    .catch(function(error){
        console.log(error);
    });
  },
  methods: {
    onChgClient: function(){
      if(this.item.abbreviation == -1){
        this.item.kind = 2;
      } else {
        this.item.kind = 1;
      }

      console.log(this.item.kind);
      console.log(this.optClient);
    },
    submitAddEdit: function(){
      // this.item.id = 0;

      // エラー処理
      this.errors = [];
      if(!this.item.date){ this.errors.push("日付を入力してください。"); }
      if(!this.item.time){ this.errors.push("時間を選択してください。"); }
      if(!this.item.id){ this.errors.push("ユーザを選択してください。"); }
      // if(!this.item.place){ this.errors.push("場所を選択してください。"); }
      if(this.item.kind == 1 && !this.item.place){ this.errors.push("場所を選択してください。"); }
      if(this.item.kind != 1 && !this.item.task){ this.errors.push("任意入力タスクを入力してください。"); }

      if(this.errors.length > 0){
        return false;
      }

      if(this.item.kind != 1){
        this.item.kind = 2;
      }

      if(!this.item.tentativeRegist){
        this.item.tentativeRegist = 0;
      }

      // 実行
      console.log("追加実行");
      var sendData = {
        mode: this.mode,
        token: this.$store.state.token,
        // 入力データ
        target_date: this.item.date,
        user_id: this.item.id,
        assign_time: this.item.time,
        kind: this.item.kind,
        cstm_id: this.item.abbreviation,
        location: this.item.place,
        temp_flg: this.item.tentativeRegist,
        koma: this.item.no,
        free_task: this.item.task,
        work_text: this.item.workContent,
        assign_cstm_id: this.item.assign_cstm_id,
        task_assign_id: this.item.task_assign_id,
        org_date: this.orgDate
      }

      console.log("sendData");
      console.log(sendData);

      axios
        .post(this.URL +'EditPlan', sendData,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // 成功処理
            console.log("OK");
            // 再描画
            this.$emit(this.pntMethodName);
            this.modalClose();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログを表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
            if(res.data.errMsg){ this.errors.push(res.data.errMsg); }
            // トークンエラーチェック
            this._chkToken(res.data.errToken);
          }
        }
      );

      // pntItemsの中の一番大きなidを取得して１を足す
      // if(this.pntItems.concat().length > 0){
      //   var newId = Math.max.apply(null,this.pntItems.concat().map(function(item){return item.id;} )) + 1;
      // }
      // this.item.id = newId;

      // // ディープコピー
      // deepCopy = JSON.parse(JSON.stringify(this.item));
      // // pntItemsにアイテムを追加
      // this.pntItems.push(deepCopy);

      // console.log("ok");
      // console.log(this.pntItems);

    },

    // モーダルウィンドウ
    modalClose: function(){
      this.modalShow = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* モーダルウィンドウ */
  #overlay{
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content{
    z-index:11;
    width:500px;
    padding: 2.0em;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* モーダル編集・追加 */
  .addedit .date{
    text-align: left;
    /* margin-bottom: 1.5em; */
  }
  .addedit .date > div{
    width: calc(100% / 3);
  }
  .addedit .date > div:nth-child(2){
    padding: 0 10px;
  }
  .addedit .date label{
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .addedit .detail{
    text-align: left;
  }
  .addedit .detail .twoColumn > div{
    width: calc(100% / 2);
  }
  .addedit .detail .twoColumn > div:first-child{
    padding-right: 10px;
  }
  .addedit .detail dl > dd{
    margin-top: 0.5em;
    /* margin-bottom: 1.5em; */
  }
</style>

<style>
  .v-input--reverse{
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .v-input--reverse .v-input__control .v-input__slot {
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
  }
  .v-input--reverse .v-input__slot .v-label {
    flex: none !important;
  }
  .v-input--reverse .v-input__slot .v-input--selection-controls__input {
    margin-right: 0 !important;
    margin-left: 8px !important;
  }
</style>
