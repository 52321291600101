<template>
  <header>
    <v-card
      class="d-flex justify-space-between transparent"
      flat
      tile
    >
     <v-card
        class="transparent"
        flat
        tile
      >
        <router-link to="/Top">
          <v-btn v-if="isMaster" text class="topPage" @click="toTop">全体画面へ戻る</v-btn>
          <v-btn v-else text class="page" @click="_historyBack">&lt;</v-btn>
        </router-link>
      </v-card>
      <v-card
        class="transparent d-flex"
        flat
        tile
        style="align-items: center; color: #fff; font-size: 125%; font-weight: bold;"
      >
        <div v-if="$store.state.locked == 1">現在、編集を禁止しています。</div>
      </v-card>
      <v-card
        class="transparent"
        flat
        tile
      >
        <ul class="flex jcFE menu">
          <li v-if="$store.state.user.authority==9" class="selMaster">
            <v-select
              v-model="selMaster"
              :items="optMaster"
              class="select-input-none mb-n7 master"
              dense
              outlined
              background-color="white"
              placeholder="▼マスタ"
              return-object
              @input="chgMaster"
            >
            </v-select>
          </li>
          <li class="user pr-3 pl-3">ユーザ：{{ $store.state.user.name }}</li>
          <li class="selMaster pt-1.2">
              <v-btn outlined color="white" @click="signout" height="41" class="signout"><span class="material-icons signouticon">login</span>サインアウト</v-btn>
          </li>
        </ul>
      </v-card>
    </v-card>
  </header>
</template>

<script>
export default {
  props: ["postTitle"],
  data() {
    return {
      optMaster: [
        { val: "/management/Client", text: "クライアント" },
        { val: "/management/User", text: "ユーザ" },
        { val: "/management/Team", text: "チーム" },
      ],
      selMaster: "",
    };
  },
  computed: {
    name(){
      return this.$store.getters.userId;
    },
    isMaster(){
      if (location.href.match("/management"))
      {
        return true;
      }
      return false;
    },
  },
  methods: {
    signout() {
      // alert("サインアウト");
      this.$store.dispatch("authReset");
      this.$router.push("/");
    },
    chgMaster: function(){
      // console.log(this.selMaster.val);
      // 現在表示しているマスタ管理画面が選択されている場合は遷移しない(コンソールエラーが表示されるため)
      if (this.selMaster.val != this.$route.path) {
          this.$router.push(this.selMaster.val)
      }
    },
    toTop(){
      this.$router.push("/Top");
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lockedDiv header{
  background-color: #f00 !important;
}

header{
	background-color: #006fe9;
	/* margin-bottom: 2em; */
	margin: 0 auto 1em;
  padding-top: 0.2em;
}
header > div{
	color: #fff;
	width: 1500px;
	height: 58px;
	margin: 0 auto;
	padding: 7px 0;
}
header .menu{
  align-items: center;
}
header .menu > li{
  margin-left: 10px;
}
header .menu > li.selMaster{
  width: 160px;
}
/* 未使用クラス*/
/* header .signout{
	color: #fff;
	background-color: #fff;
}
header .signout > div{
	padding: 5px;
	text-align: center;
} */
.topPage
{
  font-size: 15pt;
  color:#fff;
}
.page
{
  font-size: 40pt;
  color:#fff;
}
.user
{
  color: #fff;
  font-size: 1em;
  font-weight:650
}
.v-select__selections input { display: none }
/* .master{
  border-color: #fff !important;
} */
.signouticon
{
  margin-right: 5px;
	font-size: 28px;
}
.signout
{
  font-weight: 700;
  padding:0 12px !important;
}
</style>
