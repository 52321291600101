<template>
  <div id="modal" v-show="modalShow">
    <div id="overlay">
      <div class="modal-content addedit">
        <div v-if="errors.length" class="errorList">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
        
        <dl>
          <dt>チーム名</dt>
          <dd>
            <v-text-field
              v-model="item.text"
              name="iptName"
              id="iptName"
              class="input"
              outlined
              dense
            ></v-text-field>
          </dd>
        </dl>
        
        <div class="flex jcSB">
          <v-btn depressed color="success" width="100" @click="submitAddEdit">OK</v-btn>
          <v-btn depressed color="primary" width="100" @click="modalClose">CANCEL</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "child",
  props: {
    pntItems: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      // モーダル
      modalShow: false,
      iptName: "",
      item: {
        "id": null,
        "text": "",
      },
      errors: [],
      sendData: {},
    };
  },
  methods: {
    submitAddEdit: function(){
      var deepCopy = null;

      // エラー処理
      this.errors = [];
      if(!this.item.text){ this.errors.push("チーム名を入力してください。"); }
      if(this.pntItems.some((u) => u.text === this.item.text)){ this.errors.push("このチーム名は登録されています。"); }

      if(this.errors.length > 0){
        return false;
      }

      if(this.item.id != null){
        // alert("編集OK");
        // ディープコピー
        deepCopy = JSON.parse(JSON.stringify(this.item));

        axios
          .post(this.URL +'management/EditTeam',  {
              mode: "edit",
              userId: this.$store.state.user.id,
              item: deepCopy,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // 画面更新
              this.$emit("GetTeam");
              this.modalClose();
            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
            // モーダル削除
          }
        );
      } else {
        // alert("追加OK");
        // pntItemsの中の一番大きなidを取得して１を足す
        // if(this.pntItems.concat().length > 0){
        //   var newId = Math.max.apply(null,this.pntItems.concat().map(function(item){return item.id;} )) + 1;
        // }
        // this.item.id = newId;
        // ディープコピー
        // deepCopy = JSON.parse(JSON.stringify(this.item));

        axios
          .post(this.URL +'management/EditTeam', {
              mode: "add",
              userId: this.$store.state.user.id,
              item: this.item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // 画面更新
              this.$emit("GetTeam");
              // モーダル削除
              this.modalClose();
            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }
    },

    // モーダルウィンドウ
    modalClose: function(){
      this.modalShow = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* モーダルウィンドウ */
  #overlay{
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content{
    z-index:11;
    width:500px;
    padding: 2.0em;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* モーダル編集・追加 */
  .addedit .date{
    text-align: left;
    margin-bottom: 1.5em;
  }
  .addedit .date label{
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .addedit dl{
    text-align: left;
  }

  .addedit dl .col{
    padding-left: 0;
    padding-right: 0;
  }
</style>
