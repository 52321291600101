import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

function initialState(){
  return {
    user: {
      id: "",
      name: "",
      user_id: "",
      authority: "",
      team: "",
      region_id: "",
    },
    staff: "",
    client: "",
    token: "",
    locked: 0,
  }
}

export default new Vuex.Store({
  state: initialState(),
  mutations: {
    reset(state){
      Object.assign(state, initialState());
    },
    updateUser(state, user){
      state.user.id = user.id;
      state.user.name = user.name;
      state.user.user_id = user.user_id;
      state.user.authority = user.authority;
      state.user.region_id = user.region_id;
      state.user.team = user.team;
      state.token = user.token;
    },
    updateSelect(state, item){
      state.staff = item.staff;
      state.client = item.client;
      state.date = item.date;
    },
    updateLocked(state, locked){
      state.locked = locked.locked;
    },
  },
  actions: {
    authReset(context){
      context.commit('reset');
    },
    auth(context, user){
      context.commit('updateUser', user);
    },
    selStaffClient(context, item){
      context.commit('updateSelect', item);
    },
    updateLocked(context, locked){
      context.commit('updateLocked', locked);
    },
  },
  modules: {
  },
  // 'createPersistedState()'でインスタンス作成。引数に設定を書く
  plugins: [createPersistedState(
    { // ストレージのキーを指定。デフォルトではvuex
      key: 'assignManagementSystem',
      // 管理対象のステートを指定。pathsを書かないときは'modules'に書いたモジュールに含まれるステート全て
      // paths:[],
      // ストレージのsh類を指定する。デフォルトではローカルストレージ
      storage: window.sessionStorage
    }
  )]
})
