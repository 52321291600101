// グローバルミックスイン
export default {
  data() {
    return {
      URL: process.env.VUE_APP_API_BASE_URL,
      MONTHS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    }
  },
  methods: {
    // ログインチェック
    _loginChk: function(data){
      // トークンがエラーの場合はログイン画面に飛ばす
      if(data.tokenErr){
        this.$store.dispatch("authReset");
        this.$router.push("/");
        return false;
      } else {
        // console.log(data);
        // console.log(token);
        // this.$store.dispatch("auth", {
        //   id: data.loginUser.id,
        //   name: data.loginUser.name,
        //   userId: data.loginUser.userId,
        //   authority: data.loginUser.authority,
        //   team: data.loginUser.team,
        //   token: token,
        // });
        return true;
      }
    },
    // 権限チェック
    _ChkAuthority: function(){
      // スタッフの場合、閲覧のみのためドラッグなどの処理を停止
      // console.log("ChkAuthority");
      return this.$store.state.user.authority == 3 ? false : true;
    },
    // ひとつ前の画面に戻る
    _historyBack: function(){
      history.back();
    },
    // 日付を返すメソッド
    _formatDate: function(dt, mode) {
      var yyyy = dt.getFullYear();
      var mm = ('00' + (dt.getMonth()+1)).slice(-2);
      var dd = ('00' + dt.getDate()).slice(-2);
      if(mode == "yyyymm"){
          return (yyyy +'-'+ mm);
      } else {
          return (yyyy +'-'+ mm +'-'+ dd);
      }
    },
    // トークンエラーチェック
    _chkToken: function(errToken){
      if(errToken){
        this.$store.dispatch("authReset");
        this.$router.push("/Login");
      }
    },
    // 西暦一覧取得
    _getYears: function(){
      let _years = []
      const currentYear = new Date().getFullYear()
      for (let i = 2020; i <= currentYear + 3; i++) {
        _years.push(i)
      }
      return _years;
    },
    // 所属名取得
    _getRegion: function(region_id){
      var region = "";
      switch(region_id)
      {
        case 1: region = "京都"; break;
        case 2: region = "東京"; break;
        case 3: region = "福岡"; break;
        default: region = ""; break;
      }
      return region;
    },
  },
}
