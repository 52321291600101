<template>
  <div>
    <div class="outBox">
      <h1><img src="/img/favicon.svg" width="80">アサイン管理システム</h1>
      <div class="login">
        <form v-on:submit.prevent="doLogin">
          <div class="inBox">
            <div>
              <label>ユーザID</label>
              <input v-model="user.userId" type="text">
            </div>
            <div>
              <label>パスワード</label>
              <input v-model="user.password" type="password">
            </div>
            <div class="btn">
              <button type="submit">サインイン</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      color="error"
      timeout="6000"
      top
    >
      ユーザIDまたはパスワードが正しくありません。
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          color=""
          @click.native="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// 元に戻す
import axios from 'axios';

export default {
  data() {
    return {
      user: {},
      snackbar: false,
      error: "",
      sendData: {},
    };
  },
  methods: {
    doLogin() {
      console.log(this.user);

      axios
        .post(this.URL +'Login', 
          this.user, 
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res.data);
          if(res.data.ret){
            console.log("OK");
            this.$store.dispatch("auth", {
              id: res.data.id,
              name: res.data.name,
              userId: res.data.userId,
              authority: res.data.authority,
              team: res.data.team,
              region_id: res.data.region_id,
              token: res.data.token,
            });
            console.log(this.$store.state);
            if (this.$store.state.user.authority == 3) {
              // スタッフはクライアント画面へ
              this.$router.push("/Staff");
            }
            else {
              this.$router.push("/Top");
            }
            // this.$router.push(this.$route.query.redirect);
          } else {
            // エラー処理
            console.log("error");
            this.snackbar = true;
          }
        }
      );
    }
  },
}
</script>

<style scoped>

.outBox{
  width: 650px;
  margin: 50px auto 0;
  background: #dcebff;
  border: 2px solid #2e3191;
  border-radius: 10px;
 }
.outBox h1{
  text-align: center;
  font-size: 30pt;
  margin-bottom: 0.5em;
  margin-top: 1em;
}
.outBox h1 img{
  vertical-align: -17px;
  margin-right: 0.5em;
}
.login{
  width: 400px;
  margin: 30px auto 0;
}
.login .inBox{
  background: #dcebff;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 3.5em;
}
.login .inBox > div:not(:first-child){
  margin-top: 1.5em;
}
.login .inBox label{
  font-size: 15pt;
  color: #989898;
  display: block;
  margin-bottom: 0.2em;
}
.login .inBox input{
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  border: 2px solid #989898;
  border-radius: 5px;
  font-size: 17pt;
}
.login .inBox .btn{
  text-align: center;
}
.login .inBox .btn button{
  background: #0ab5ff;
  color: #fff;
  width: 100%;
  padding: 15px;
  border: 0;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  transition: all 0.3 ease;
  margin-top: 3em;
  }
.login .inBox .btn button:hover{
  background: #0ab5ff;
}
</style>
