import Vue from 'vue'
import VueRouter from 'vue-router'

// compornent
import Login from '../views/Login.vue'
import Top from '../views/Top.vue'
import Client from '../views/Client.vue'
import Staff from '../views/Staff.vue'
import MgmtClient from '../views/management/Client.vue'
import MgmtUser from '../views/management/User.vue'
import MgmtTeam from '../views/management/Team.vue'
// import NotFound from '../views/NotFound.vue'
// import Home from '../views/Home.vue'
// import TopTest from '../views/TopTest.vue'

// store
import Store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Login
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Top',
    name: 'Top',
    component: Top,
    meta: { requiresAuth: true }
  },
  {
    path: '/Client',
    name: 'Client',
    component: Client,
    meta: { requiresAuth: true }
  },
  {
    path: '/Staff',
    name: 'Staff',
    component: Staff,
    meta: { requiresAuth: true }
  },

  // 管理画面
  {
    path: '/management/Client',
    name: 'MgmtClient',
    component: MgmtClient,
    meta: { requiresAuth: true }
  },
  {
    path: '/management/User',
    name: 'MgmtUser',
    component: MgmtUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/management/Team',
    name: 'MgmtTeam',
    component: MgmtTeam,
    meta: { requiresAuth: true }
  },

  // {
  //   path: '/TopTest',
  //   name: 'TopTest',
  //   component: TopTest,
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '*',
  //   component: NotFound
  // }
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !Store.state.token) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router
