<template>
  <v-btn class="smole-padding" depressed color="editcolor" @click="edit" v-bind:disabled="disabled"><span class="material-icons smole-size">edit</span>編集</v-btn>
</template>

<script>
export default {
  methods: {
    edit: function() {
      this.$emit('edit');
    },
  },
  props:{
    disabled:{
      type: Boolean,
      default: false,
    },
  }
  // props:['is-disabled']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-icons.smole-size {
	font-size: 22px;
  margin-right: 5px;
}
.smole-padding {
  padding: 0 13px !important;
  color: white;
  font-weight: 1000;
}
</style>
