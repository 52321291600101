<template>
  <div id="modal" v-show="modalShow">
    <div id="overlay">
      <div class="modal-content addedit">
        <div v-if="errors.length" class="errorList">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="formDl"
        >
          <dl>
            <div>
              <dt>ユーザ名</dt>
              <dd>
                <v-text-field
                  v-model="item.d_name"
                  name="iptUser"
                  id="iptUser"
                  class="input"
                  outlined
                  dense
                ></v-text-field>
              </dd>
            </div>
            <div>
              <dt>ユーザID</dt>
              <dd>
                <v-text-field
                  v-model="item.d_login_id"
                  name="iptUserId"
                  id="iptUserId"
                  class="input"
                  maxlength="20"
                  outlined
                  dense
                ></v-text-field>
              </dd>
            </div>
          </dl>
          <dl>
            <div class="w100">
              <dt>パスワード</dt>
              <dd>
                <span v-show="item.d_id">
                  <label><input type="checkbox" name="item.d_chk_password" v-model="item.d_chk_password" @click="chgChkPassword()"> パスワードを変更する</label>
                </span>
                <v-text-field
                  v-model="item.d_password"
                  type="password"
                  name="iptPassword"
                  class="input"
                  :class="{disabled: isChkPassword}"
                  :disabled="isChkPassword"
                  outlined
                  dense
              ></v-text-field>
              </dd>
            </div>
          </dl>
          <dl>
            <div>
              <dt>権限</dt>
              <dd>
                <v-select
                  v-model="item.d_authority"
                  :items="optAuthority"
                  :value="optAuthority.find(option => option.id == item.d_authority) || ''"
                  item-value="id"
                  dense
                  outlined
                  background-color="white"
                >
                </v-select>
              </dd>
            </div>
            <div>
              <dt>役職</dt>
              <dd>
                <v-text-field
                  v-model="item.d_position"
                  name="iptPosition"
                  id="iptPosition"
                  class="input"
                  outlined
                  dense
                ></v-text-field>
              </dd>
            </div>
            <div>
              <dt>所属</dt>
              <dd>
                <v-select
                  v-model="item.d_region_id"
                  :items="optRegion"
                  :value="optRegion.find(option => option.id == item.d_region_id) || ''"
                  item-value="id"
                  dense
                  outlined
                  background-color="white"
                >
                </v-select>
              </dd>
            </div>
          </dl>
          <dl>
            <div class="w100">
              <dt>チーム</dt>
              <dd class="team">
                <div>
                  <table class="mb-2">
                    <colgroup>
                      <col>
                      <col>
                    </colgroup>
                    <tbody>
                      <tr v-for="(team, index) in item.d_team" :key="team">
                        <td>
                          <v-select
                            v-model="item.d_team[index]"
                            :items="optTeamItem"
                            class="select-input-none mb-n7"
                            dense
                            outlined
                            background-color="white"
                            return-object
                          >
                          </v-select>
                        </td>
                        <td>
                          <v-btn 
                            @click="delTeam(index)"
                            depressed 
                            color="error"
                          >
                            削除
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="tR">
                  <v-btn 
                    @click="addBtnTeam"
                    depressed 
                    color="success">追加</v-btn>
                </div>
              </dd>
            </div>
          </dl>
        </v-form>
        
        <div class="flex jcSB mt-10">
          <v-btn depressed color="success" width="100" @click="submitAddEdit">OK</v-btn>
          <v-btn depressed color="primary" width="100" @click="modalClose">CANCEL</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "childUser",
  props: {
    pntItems: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      // モーダル
      modalShow: false,
      modalShowAddTeam: false,
      // selDate: false,
      valid: true,
      iptUser: "",
      iptPassword: "",
      iptAuthority: "",
      selAuthority: [], // m_authorityから取得＋設定

      show1: false,
      // rules: {
      //     required: value => !!value || 'Required.',
      //     min: v => v.length >= 8 || 'Min 8 characters',
      //     emailMatch: () => (`The email and password you entered don't match`),
      // },

      item: {
        "d_id": null,
        "d_name": "",
        "d_login_id": "",
        "d_password": "",
        "d_chk_password": false,
        "d_authority": "",
        "d_position": "",
        "d_team": [],
        "d_region_id": "",
        "m_id": "",
        "m_text": ""
      },
      selTeamItem: "",
      optTeamItem: [],
      errors: [],
      optAuthority: [
        { id: 9, text: "管理者" },
        { id: 1, text: "マネージャー" },
        { id: 2, text: "リーダー" },
        { id: 3, text: "スタッフ" },
      ],
      tmpLoginId: "",
      isChkPassword: false,
      optRegion: [
        { id: 1, text: "京都" },
        { id: 2, text: "東京" },
        { id: 3, text: "福岡" },
      ],
    };
  },
  mounted() {
    // m_authorityから取得＋設定
    axios.post(this.URL +'management/GetTeam', {
        token: this.$store.state.token,
      },
      { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
    )
    .then(function(res){
      // ログインチェック
      // this._loginChk(res.data, this.$store.state.token);
      
      //デバッグ用にconsoleに出力
      console.log(res);
      this.optTeamItem = res.data.data;
      // console.log(this.items);
    }.bind(this))
    .catch(function(error){
        console.log(error);
    });
  },
  methods: {
    submitAddEdit: function(){
      var deepCopy = null;

      // if(!this.item.name){
      //   alert("チーム名が空です。");
      //   return false;
      // }


      // this.item.d_authority = this.iptAuthority.id;
      this.item.m_id = this.iptAuthority.val;
      this.item.m_text = this.iptAuthority.text;

      console.log(this.item);
      console.log("pntItems");
      console.log(this.pntItems);

      // エラー処理
      this.errors = [];
      if(!this.item.d_name){ this.errors.push("ユーザ名を入力してください。"); }
      if(!this.item.d_login_id){ this.errors.push("ユーザIDを入力してください。"); }
      if(this.tmpLoginId !== this.item.d_login_id && this.pntItems.some((u) => u.d_user_id === this.item.d_login_id)){ this.errors.push("このユーザIDは登録されています。"); }
      if(this.item.d_chk_password && !this.item.d_password){ this.errors.push("パスワードを入力してください。"); }
      if(this.item.d_chk_password && this.item.d_password && this.item.d_password.length < 8){ this.errors.push("パスワードは8文字以上入力してください。"); }
      if(!this.item.d_authority){ this.errors.push("権限を選択してください。"); }
      if(!this.item.d_position){ this.errors.push("役職を入力してください。"); }
      if(this.item.d_team == null || !this.item.d_team.length){ this.errors.push("チームを選択してください。"); }
      if(!this.item.d_region_id){ this.errors.push("所属を選択してください。"); }

      if(this.errors.length > 0){
        return false;
      }

      if(this.item.d_id != null){
        // alert("編集OK");
        // ディープコピー
        deepCopy = JSON.parse(JSON.stringify(this.item));

        console.log("edit");
        console.log(deepCopy);

        axios
          .post(this.URL +'management/EditUser', {
              mode: "edit",
              userId: this.$store.state.user.id,
              item: deepCopy,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // // インデックス取得
              // var idx = this.pntItems.findIndex((object) => {
              //   return object.d_id === this.item.d_id;
              // });
              // // 親の値反映
              // this.$parent.items.splice(idx, 1, deepCopy);
              // 画面更新
              this.$emit("GetUser");
              // モーダル削除
              this.modalClose();
            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      } else {
        // alert("追加OK");
        // pntItemsの中の一番大きなidを取得して１を足す
        // if(this.pntItems.concat().length > 0){
        //   var newId = Math.max.apply(null,this.pntItems.concat().map(function(item){return item.id;} )) + 1;
        // }
        // this.item.id = newId;

        // // ディープコピー
        // deepCopy = JSON.parse(JSON.stringify(this.item));
        // // pntItemsにアイテムを追加
        // this.pntItems.push(deepCopy);

        console.log("add");
        console.log(this.item);

        axios
          .post(this.URL +'management/EditUser', {
              mode: "add",
              userId: this.$store.state.user.id,
              item: this.item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // // newID設定
              // this.item.d_id = res.data.data;
              // // ディープコピー
              // deepCopy = JSON.parse(JSON.stringify(this.item));
              // // pntItemsにアイテムを追加
              // this.pntItems.push(deepCopy);
              // 画面更新
              this.$emit("GetUser");
              // モーダル削除
              this.modalClose();
            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }
    },
    delTeam: function(index){
      console.log("delTeam");
      this.item.d_team.splice(index, 1);
    },
    addBtnTeam: function(){
      // console.log(this.pntItems);
      console.log("addTeam");
      if(this.item.d_team == null){
        this.item.d_team = [];
      }
      this.item.d_team.push({id: "", text: ""});
      console.log(this.item.d_team);
    },

    chgChkPassword: function() {
      this.isChkPassword = !this.isChkPassword;
    },
    
    // モーダルウィンドウ
    modalClose: function(){
      this.modalShow = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* モーダルウィンドウ */
  #overlay{
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content{
    z-index:11;
    width:900px;
    padding: 2.0em;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* モーダル編集・追加 */
  .addedit .date{
    text-align: left;
    margin-bottom: 1.5em;
  }
  .addedit .date label{
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .addedit dl{
    text-align: left;
  }

  .addedit dl .col{
    padding-left: 0;
    padding-right: 0;
  }

  table{
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  table th,
  table td
  {
    padding: 5px;
    border: 1px solid #ccc;
  }
  table th{
    /* background: #1976d2;
    color: #fff; */
    font-weight: normal;
  }
  table colgroup col:nth-child(2){ width: 50px; }

  .formDl > dl{
    display: flex;
    justify-content: space-between;
  }
  .formDl > dl > div{
    width: 50%;
    padding: 0 10px;
  }

  .formDl .team{
    height: 400px;
    overflow-y: scroll;
  }

  .w100{
    width: 100% !important;
  }
</style>
