<template>
  <div id="modal" v-show="modalShow">
    <div id="overlay">
      <div class="modal-content import">
        <div v-if="errors.length" class="errorList">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>

        <dl>
          <dt>空白予定</dt>
          <dd>
              <div>
                <v-radio-group
                  v-model="item.blank"
                  row
                  class="mt-1"
                >
                  <v-radio
                    v-for="radio in rdoIsTakeBlank" :key="radio.id"
                    :label="radio.text"
                    :value="radio.id"
                  >
                  </v-radio>
                </v-radio-group>
              </div>
          </dd>
          <dt>期間</dt>
          <dd>
            <v-container pt-0>
              <v-row>
                <v-col>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.start_date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.start_date"
                      locale="jp-ja"
                      :allowed-dates="getDateRange"
                      :day-format="date => new Date(date).getDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1" style="padding-left: 0.5em; padding-top: 1.4em;">
                  ～
                </v-col>
                <v-col>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.end_date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.end_date"
                      locale="jp-ja"
                      :allowed-dates="getDateRange"
                      :day-format="date => new Date(date).getDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </dd>
          <dt>
            <v-container pl-0 pt-0 pb-0>
              <v-row dense>
                <v-col>
                  <label>対象ユーザ</label>
                </v-col>
                <v-col>
                  <input type="checkbox" v-model="allSelected" @change="selectedAll()">
                  <span style="margin-left:10px;">全選択/全解除</span>
                </v-col>
              </v-row>
            </v-container>
          </dt>
          <dd class="user">
            <table>
              <colgroup>
                <col>
              </colgroup>
              <tbody>
                <tr v-for="(staff , index) in importStaff" :key="staff.name">
                  <td>
                    <input type="checkbox"     
                    v-model="item.select_users"
                    :value="staff.name"
                    :id="'check_' + index"
                    v-bind:disabled="!isExistStaffInDB(staff)"
                      name="item.select_users" @change="selected(staff.id)">
                    <span style="margin-left:10px;">{{ getDispStaffName(staff) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </dl>
        <div class="flex jcSB" style="margin-top:20px;">
          <v-btn depressed color="success" width="100" :disabled="this.isSaving" @click="submitAddEdit">OK</v-btn>
          <v-btn depressed color="primary" width="100" :disabled="this.isSaving" @click="modalClose">CANCEL</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from "moment"
export default {
  name: "childAddEdit",
  props:{
    pntItems: {
      type: Array,
      required: false,
    },
    pntMethodName: String,
  },
  data() {
    return {
      item: {
        "data":[],
        "blank": 2,
        "start_date": null,
        "end_date": null,
        "select_users": [],
      },

      // モーダル
      modalShow: false,
      rdoIsTakeBlank: [
        {id: 1, text: "取り込む"},
        {id: 2, text: "取り込まない"},
      ],
      errors: [],
      // 全選択フラグ
      allSelected: true,
      // インポートした日時データの最小値
      min_date: null,
      // インポートした日時データの最大値
      max_date: null,
      
      // インポートしたユーザデータ
      importStaff: [{
        id: 0,    // ユーザID(DBに存在しないユーザの場合は0となる)
        name:"",  // ユーザ名
      }],

      // マスタ保存中フラグ
      isSaving: false
    };
  },
  watch:{
    // ウインドウ表示時の処理
    modalShow: function(){
      if(this.modalShow == true){
        // 空白データ取込選択は「取り込まない」をデフォルトとする
        this.item.blank = 2;
        // 開始日時はインポートデータの最小の日時をデフォルトとする
        this.item.start_date = this.min_date
        // 終了日時はインポートデータの最大の日時をデフォルトとする
        this.item.end_date = this.max_date
        // 全選択状態をデフォルトとする
        this.allSelected = true
        this.selectedAll()
      } 
    }
  },
  methods: {
    selected(id) {
       let select = false
       this.item.select_users.forEach(r => {
         if(r == id) {
           select = true
         }
       })
       console.log(this.item.select_users)
      return select
    },
    selectedAll() {
      if(this.allSelected) {
        this.item.select_users = []
        this.importStaff.forEach(r => {
          if(this.isExistStaffInDB(r)){
            // DBに存在するユーザのみ選択可能とする
            this.item.select_users.push(r.name)
          }
        })
      } else {
        this.item.select_users = []
      }
      console.log(this.item.select_users)
    },
    // 表示するユーザ名の取得
    getDispStaffName: function(staff)
    {
      let ret = staff.name
      // DBに存在しない場合は、その旨を表記
      if(!this.isExistStaffInDB(staff)) ret += "（先にユーザを登録して下さい）"
      return ret
    },
    // 指定のユーザがDBに存在するか否か
    isExistStaffInDB: function(staff){
      // IDが0ならばDBに存在しない
      return (staff.id != 0)
    },
    // インポートデータの日時範囲取得
    getDateRange: function(val) {
      return new Date(this.min_date) <= new Date(val) && new Date(val) <= new Date(this.max_date)
    },
    submitAddEdit: function(){
      // エラー処理
      this.errors = [];
      if(!this.item.blank){ this.errors.push("空白期間を選択してください。"); }
      if(!this.item.start_date){ this.errors.push("開始日時を入力してください。"); }
      if(!this.item.end_date){ this.errors.push("終了日時を入力してください。"); }
      if(!this.item.select_users.length > 0){ this.errors.push("対象ユーザを選択してください。"); }

      const parsedStartDate = new Date(this.item.start_date);
      const parsedEndDate = new Date(this.item.end_date);
      const dispDays = (parsedEndDate.getTime() - parsedStartDate.getTime()) / (24 * 60 * 60 * 1000)  // 日数
      if((this.item.end_date != null) && (this.item.end_date != null) && dispDays < 0){ this.errors.push("開始日時が終了日時を超えないように入力してください。"); }

      if(this.errors.length > 0){
        return false;
      }

      // 実行
      console.log("インポート範囲のバックアップ実行");
      let backupRes = true
      axios
          .post(this.URL +'GetTop2', {
              startDate: this.item.start_date,
              selectedDisplayDays: dispDays,
              token: this.$store.state.token,
              cstmId: 0,
            },
            { headers: {'Content-Type': "Export/xlsx"},
              responseType:'blob',
              dataType:'binary', }
          )
          .then((res) => {
            console.log(res);
            if(res.data != null){
              const blob = new Blob([res.data], {type: res.data.type});
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              const dateTime = moment(new Date()).format("YYYYMMDDHHmmss")
              link.setAttribute("download", "アサイン管理_Backup_" + dateTime + ".xlsx");
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
              link.parentNode.removeChild(link);
            } else {
              // エラー処理
              console.log("NG");
              backupRes = false
            }
          }
          );

      if(backupRes)
      {
        console.log("インポートデータのマスタ保存実行");
        this.isSaving = true;
        // 選択ユーザのID配列
        let users = []
        for(let selectUserIdx = 0 ;selectUserIdx < this.item.select_users.length;selectUserIdx++){
          for(let importStaffIdx = 0 ;importStaffIdx < this.importStaff.length;importStaffIdx++){
            if(this.item.select_users[selectUserIdx] == this.importStaff[importStaffIdx].name){
              users.push(this.importStaff[importStaffIdx].id)
            }
          } 
        }
        var sendData = {
          data: this.item.data,
          token: this.$store.state.token,
          // 入力データ
          blank: this.item.blank,
          start_date: this.item.start_date,
          end_date: this.item.end_date,
          select_users: users,
        }
  
        console.log("sendData");
        console.log(sendData);
        axios
          .post(this.URL +'ImportDataSet', sendData,
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // 成功処理
              console.log("OK");
              // 再描画
              this.$emit(this.pntMethodName);
              this.isSaving = false;
              this.modalClose();
            } else {
              // エラー処理
              console.log("NG");
              if(res.data.errMsg){ this.errors.push(res.data.errMsg); }
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }
    },

    // モーダルウィンドウ
    modalClose: function(){
      this.modalShow = false;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* モーダルウィンドウ */
  #overlay{
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content{
    z-index:11;
    width:500px;
    padding: 2.0em;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* モーダル編集・追加 */
  .import .date{
    text-align: left;
    /* margin-bottom: 1.5em; */
  }
  .import .date > div{
    width: calc(100% / 3);
  }
  .import .date > div:nth-child(2){
    padding: 0 10px;
  }
  .import .date label{
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .import .detail{
    text-align: left;
  }
  .import .detail .twoColumn > div{
    width: calc(100% / 2);
  }
  .import .detail .twoColumn > div:first-child{
    padding-right: 10px;
  }
  .import .detail dl > dd{
    margin-top: 0.5em;
    /* margin-bottom: 1.5em; */
  }
  .import dl{
    text-align: left;
  }

  .import dl .col{
    padding-left: 0;
    padding-right: 0;
  }

  .user{
    height: 200px;
    overflow-y: scroll;
  }
  #overlay ul > li{
    padding: 2px 4px;
  }
  #overlay ul > li:first-child{
    padding-left: 0 !important;
  }
  #overlay ul > li:last-child{
    padding-right: 0 !important;
  }
  table{
    width: 100%;
    box-sizing: border-box;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  table th,
  table td
  {
    padding: 5px;
    border: 1px solid #ccc;
  }
  table th{
    /* background: #1976d2;
    color: #fff; */
    font-weight: normal;
  }
  table colgroup col:nth-child(2){ width: 50px; }
</style>

<style>
  .v-input--reverse{
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .v-input--reverse .v-input__control .v-input__slot {
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
  }
  .v-input--reverse .v-input__slot .v-label {
    flex: none !important;
  }
  .v-input--reverse .v-input__slot .v-input--selection-controls__input {
    margin-right: 0 !important;
    margin-left: 8px !important;
  }
</style>
