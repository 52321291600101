<template>
  <div>
    <div class="contractOver" v-if="items && items.length > 0">
      <ul>
        <li v-for="(item, index) in items" :key="index">
          計画日数が超過しています。超過日数：{{ item.over_days }}&nbsp;&nbsp;{{ item.full_name }} 契約期間：{{ item.ctr_start_date }}～{{ item.ctr_end_date }}（{{ item.ctr_days }}日）
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContractOver",
  data() {
    return {
      items: [],
    };
  },
  methods: {
    GetContractOver: async function(){
      console.log("GetContractOver");
      console.log("user_id:"+ this.$store.state.user.id);
      // 契約期間オーバー情報の取得
      await axios.post(this.URL +'GetContractOver', {
          token: this.$store.state.token,
          user_id: this.$store.state.user.id,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        // this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        this.items = res.data.data;
      }.bind(this))
      .catch(function(error){
        console.log(error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .contractOver{
    color: #f00;
    padding: 1.0em;
    margin-top: 1.0em;
    margin-bottom: 1.0em;
    border: 1px solid #f00;
  }
  .contractOver ul{
    padding-left: 0;
  }
</style>
