<template>
  <v-btn class="smole-padding" depressed color="addcolor" @click="add" v-bind:disabled="disabled"><span class="material-icons smole-size">add_circle_outline</span>追加</v-btn>
</template>

<script>
export default {
  methods: {
    add: function() {
      this.$emit('add');
    }
  },
  props:{
    disabled:{
      type: Boolean,
      default: false,
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-icons.smole-size {
	font-size: 23px;
  margin-right: 3px;
}
.smole-padding {
  padding: 0 13px !important;
  color: white;
  font-weight: 1000;
}
</style>
