<template>
  <div id="modal" v-show="modalShow">
    <div id="overlay">
      <div class="modal-content addedit">
        <div v-if="errors.length" class="errorList">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </div>

        <dl>
          <dt>略称</dt>
          <dd>
            <v-text-field
              v-model="item.abbreviation"
              name="iptAbbreviation"
              id="iptAbbreviation"
              class="input"
              outlined
              dense
            ></v-text-field>
          </dd>
          <dt>会社名</dt>
          <dd>
            <v-text-field
              v-model="item.company_name"
              name="iptCompany"
              id="iptCompany"
              class="input"
              outlined
              dense
            ></v-text-field>
          </dd>
          <dt>契約期間</dt>
          <dd>
            <v-container pt-0>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="dateStart"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.contract_period_start"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.contract_period_start"
                      @input="dateStart = false"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="1" style="padding-left: 0.5em; padding-top: 1.4em;">
                  ～
                </v-col>
                <v-col>
                  <v-menu
                    v-model="dateEnd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="item.contract_period_end"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.contract_period_end"
                      @input="dateEnd = false"
                      locale="jp-ja"
                      :day-format="date => new Date(date).getDate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </dd>
          <dd>
            <v-container pt-0>
              <v-row>
                <v-col>
                  <div>計画日数</div>
                  <v-text-field
                    v-model="item.contract_days"
                    name="item.contract_days"
                    id="item.contract_days"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  &nbsp;
                </v-col>
                <v-col>
                  <div>背景色</div>
                  <v-text-field
                    v-model="item.bg_color"
                    class="ma-0 pa-0"
                    dense
                    outlined
                    background-color="white"
                  >
                    <template v-slot:append>
                      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="item.bg_color"
                              hide-mode-switch
                              flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </dd>
        </dl>
        
        <div class="flex jcSB">
          <v-btn depressed color="success" width="100" @click="submitAddEdit">OK</v-btn>
          <v-btn depressed color="primary" width="100" @click="modalClose">CANCEL</v-btn>
        </div>

        <!-- <div v-for="pntItem in pntItems" :key="pntItem.id">
          {{ pntItem.id }} : {{ pntItem.abbreviation }}
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "childClient",
  props: {
    pntItems: {
      type: Array,
      required: false,
    }
  },
  data() {
    return {
      // モーダル
      modalShow: false,
      // selDate: false,
      iptAbbreviation: "",
      iptCompany: "",
      dateStart: false,
      dateEnd: false,

      // 
      item: {
        id: null,
        abbreviation: "",
        company_name: "",
        contract_period_start: "",
        contract_period_end: "",
        contract_days: "",
        bg_color: ""
      },
      errors: [],
      sendData: {},

      // カラーピッカーの表示フラグ
      menu: false,
    };
  },
  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.item.bg_color,
        cursor: 'pointer',
        height: '26px',
        width: '168px',
        borderRadius: '4px',
        marginRight: '4px'
      }
    }
  },
  methods: {
    submitAddEdit: function(){
      var deepCopy = null;

      // エラー処理
      this.errors = [];
      if(!this.item.abbreviation){ this.errors.push("略称を入力してください。"); }
      if(this.item.abbreviation.length > 3){ this.errors.push("略称は3文字以内で入力してください。"); }
      if(!this.item.company_name){ this.errors.push("会社名を入力してください。"); }
      if(!this.item.contract_period_start){ this.errors.push("startを入力してください。"); }
      if(!this.item.contract_period_end){ this.errors.push("endを入力してください。"); }
      if(!this.item.contract_days){ this.errors.push("計画日数を入力してください。"); }

      if(this.errors.length > 0){
        return false;
      }

      if(this.item.id != null){
        // alert("編集OK");
        // ディープコピー
        deepCopy = JSON.parse(JSON.stringify(this.item));

        console.log(deepCopy);

        axios
          .post(this.URL +'management/EditClient', {
              mode: "edit",
              userId: this.$store.state.user.id,
              item: deepCopy,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // // インデックス取得
              // var idx = this.pntItems.findIndex((object) => {
              //   return object.id === this.item.id;
              // });
              // // 親の値反映
              // this.$parent.items.splice(idx, 1, deepCopy);
              // 画面更新
              this.$emit("GetClient");
              // モーダル削除
              this.modalClose();

            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      } else {
        // alert("追加OK");
        // pntItemsの中の一番大きなidを取得して１を足す
        // if(this.pntItems.concat().length > 0){
        //   var newId = Math.max.apply(null,this.pntItems.concat().map(function(item){return item.id;} )) + 1;
        // }
        // this.item.id = newId;

        console.log(this.item);

        axios
          .post(this.URL +'management/EditClient', {
              mode: "add",
              userId: this.$store.state.user.id,
              item: this.item,
              token: this.$store.state.token,
            },
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // // newID設定
              // this.item.id = res.data.id;
              // // ディープコピー
              // deepCopy = JSON.parse(JSON.stringify(this.item));
              // // pntItemsにアイテムを追加
              // this.pntItems.unshift(deepCopy);
              // 画面更新
              this.$emit("GetClient");
              // モーダル削除
              this.modalClose();
            } else {
              // エラー処理
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }
      // console.log(this.$parent.items);
      // this.modalClose();
    },

    // モーダルウィンドウ
    modalClose: function(){
      this.modalShow = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* モーダルウィンドウ */
  #overlay{
    z-index:10;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content{
    z-index:11;
    width:500px;
    padding: 2.0em;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
  }

  /* モーダル編集・追加 */
  .addedit .date{
    text-align: left;
    margin-bottom: 1.5em;
  }
  .addedit .date label{
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .addedit dl{
    text-align: left;
  }

  .addedit dl .col{
    padding-left: 0;
    padding-right: 0;
  }
</style>
