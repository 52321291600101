<template>
  <div class="staff" :class="{'lockedDiv': $store.state.locked == 1}">
    <Header post-title="スタッフ別"></Header>
    
    <div id="contents">
      <div id="main" class="mb-10">
        <h2 class="title">スタッフ別</h2>
        
        <v-card
          id="control"
          class="d-flex justify-space-between mb-4"
          flat
          tile
        >
          <v-card
            flat
            tile
          >
            <ul class="flex jcFE aiC pl-0">
              <li><v-btn depressed color="primary white--text" @click="chgYear(-1)">前年</v-btn></li>
              <li><v-select class="select-input-none year_select" v-model="dateY" :items="_getYears()"  @change="GetStaff()" dense outlined></v-select></li>
              <li><v-btn depressed color="primary white--text" @click="chgYear(1)">翌年</v-btn></li>
            </ul>
          </v-card>
          <v-card
            flat
            tile
          >
            <ul class="flex jcFE">
              <li v-if="$store.state.user.authority!=3"><router-link to="/Top"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">全体</v-btn></router-link></li>
              <li><router-link to="/Client"><v-btn depressed outlined color="makeBlue" width="130" class="font-weight-bold">クライアント別</v-btn></router-link></li>
              <li><BtnAdd @add="add" :disabled="(isReadOnly || (this.addEditMode == 1) || ($store.state.locked == 1))"/></li>
              <li><BtnEdit @edit="edit" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
              <li><BtnDel @del="del" :disabled="(isReadOnly || (this.addEditMode == 0) || ($store.state.locked == 1))"/></li>
            </ul>
            <ul class="flex jcFE">
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="Copy()" :disabled="(isReadOnly || ($store.state.locked == 1))">コピー</v-btn></li>
              <li><v-btn class="boldfont" depressed color="primary white--text" @click="Paste()" :disabled="(isReadOnly || ($store.state.locked == 1))">貼付</v-btn></li>
            </ul>
          </v-card>
        </v-card>

        <div id="field">
          <v-card
            id="control"
            class="d-flex justify-space-between"
            flat
            tile
          >
            <v-card
              class="d-flex justify-start"
              flat
              tile
            >
              <v-row>
                <v-col cols="auto">
                    <v-select
                      v-model="selectedStaff"
                      :items="optStaff"
                      item-value="id"
                      item-text="name"
                      class="select-input-none selStaff"
                      dense
                      outlined
                      hide-details="false"
                      @change="chgStaff"
                    >
                    </v-select>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              flat
              tile
            >
              <v-row>
                <v-col>
                    <v-select
                      v-if="$store.state.user.authority!=3"
                      v-model="selectedRegion"
                      :items="optRegion"
                      item-value="id"
                      class="selRegion"
                      dense
                      outlined
                      hide-details="false"
                      @change="chgRegion"
                    >
                    </v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
          <div class="fieldList">
            <table class="dateList">
              <colgroup>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
                <col>
              </colgroup>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>1月</th>
                  <th>2月</th>
                  <th>3月</th>
                  <th>4月</th>
                  <th>5月</th>
                  <th>6月</th>
                  <th>7月</th>
                  <th>8月</th>
                  <th>9月</th>
                  <th>10月</th>
                  <th>11月</th>
                  <th>12月</th>
                </tr>
              </thead>
              <tbody v-for="(item, index) in items.dayList" :key="index">
                <tr v-if="selectedStaff!=0">
                  <th rowspan="3">{{ index+1 }}日</th>
                  <td
                    v-for="(detail, fieldIdx) in item.monthList" :key="detail.month" 
                    :id="'td_'+ index +'_field'+ fieldIdx +'_no1'" 
                    :class="[{noDate: (detail.targetDate == '')}, {tgtSelected: detail.no1.isSelected}, {tgtCopySelected: detail.no1.isCopySelected}]"
                    data-no="no1" 
                    :data-item_idx="index"
                    :data-field_idx="fieldIdx"
                    @mousedown="onTgtClick(item, detail, 'no1')" 
                  >
                    <template v-if="chkStaffLeaderAuthority(items.id)">
                      <draggable 
                        v-if="showDrag"
                        :id="'td_'+ index +'_field'+ fieldIdx +'_no1'" 
                        data-no="no1" 
                        :data-item_idx="index"
                        :data-field_idx="fieldIdx"
                        :options="{group: detail.no1.dragGroup, handle: '.handle'}"
                        @start="onDragStart(item, $event)"
                        @end="onDragEnd(fieldIdx, item, $event)"
                      >
                        <div 
                          v-if="detail.no1.kind != ''" 
                          :class="{handle: isHandle, tRed: (detail.no1.isOver == 1), tentative_regist: (detail.no1.tentative_regist == 1)}"
                          :style="{backgroundColor: (detail.no1.kind == 1 ? detail.no1.bg_color : '') }"
                          class="handle"
                        >
                          <!-- <span v-if="detail.no1.kind == 1">{{ detail.no1.abbreviation }}</span>
                          <span v-if="detail.no1.kind == 2">{{ detail.no1.task_abb }}</span> -->
                          <TopDetail :pntItem="detail.no1" pntPage="staff"></TopDetail>
                        </div>
                      </draggable>
                    </template> 
                    <template v-else>
                        <div 
                          v-if="detail.no1.kind != ''" 
                          :class="{handle: isHandle, tRed: (detail.no1.isOver == 1), tentative_regist: (detail.no1.tentative_regist == 1)}"
                          :style="{backgroundColor: (detail.no1.kind == 1 ? detail.no1.bg_color : '') }"
                          class="handle"
                        >
                          <!-- <span v-if="detail.no1.kind == 1">{{ detail.no1.abbreviation }}</span>
                          <span v-if="detail.no1.kind == 2">{{ detail.no1.task_abb }}</span> -->
                          <TopDetail :pntItem="detail.no1" pntPage="staff"></TopDetail>
                        </div>
                    </template>
                  </td>
                </tr>
                <tr v-if="selectedStaff!=0">
                  <td
                    v-for="(detail, fieldIdx) in item.monthList" :key="detail.month" 
                    :id="'td_'+ index +'_field'+ fieldIdx +'_no2'" 
                    :class="[{noDate: (detail.targetDate == '')}, {tgtSelected: detail.no2.isSelected}, {tgtCopySelected: detail.no2.isCopySelected}]"
                    data-no="no2" 
                    :data-item_idx="index"
                    :data-field_idx="fieldIdx"
                    @mousedown="onTgtClick(item, detail, 'no2')" 
                  >
                    <draggable
                      v-if="showDrag"
                      :id="'td_'+ index +'_field'+ fieldIdx +'_no2'" 
                      data-no="no2" 
                      :data-item_idx="index"
                      :data-field_idx="fieldIdx"
                      :options="{group: detail.no2.dragGroup, handle: '.handle'}"
                      @start="onDragStart(item, $event)"
                      @end="onDragEnd(fieldIdx, item, $event)"
                    >
                      <div 
                        v-if="detail.no2.kind != ''" 
                        :class="{handle: isHandle, tRed: (detail.no2.isOver == 1), tentative_regist: (detail.no2.tentative_regist == 1)}"
                        :style="{backgroundColor: (detail.no2.kind == 1 ? detail.no2.bg_color : '') }"
                        class="handle"
                      >
                        <TopDetail :pntItem="detail.no2" pntPage="staff"></TopDetail>
                      </div>
                    </draggable>
                  </td>
                </tr>
                <tr v-if="selectedStaff!=0">
                  <td
                    v-for="(detail, fieldIdx) in item.monthList" :key="detail.month" 
                    :id="'td_'+ index +'_field'+ fieldIdx +'_no3'" 
                    :class="[{noDate: (detail.targetDate == '')}, {tgtSelected: detail.no3.isSelected}, {tgtCopySelected: detail.no3.isCopySelected}]"
                    data-no="no3" 
                    :data-item_idx="index"
                    :data-field_idx="fieldIdx"
                    @mousedown="onTgtClick(item, detail, 'no3')" 
                  >
                    <draggable
                      v-if="showDrag"
                      :id="'td_'+ index +'_field'+ fieldIdx +'_no3'" 
                      data-no="no3" 
                      :data-item_idx="index"
                      :data-field_idx="fieldIdx"
                      :options="{group: detail.no3.dragGroup, handle: '.handle'}"
                      @start="onDragStart(item, $event)"
                      @end="onDragEnd(fieldIdx, item, $event)"
                    >
                      <div 
                        v-if="detail.no3.kind != ''" 
                        :class="{handle: isHandle, tRed: (detail.no3.isOver == 1), tentative_regist: (detail.no3.tentative_regist == 1)}"
                        :style="{backgroundColor: (detail.no3.kind == 1 ? detail.no3.bg_color : '') }"
                        class="handle"
                      >
                        <TopDetail :pntItem="detail.no3" pntPage="staff"></TopDetail>
                      </div>
                    </draggable>
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
        </div><!-- /#field -->

        <ContractOver ref="ContractOver"></ContractOver>

      </div>

      <!-- モーダルウィンドウ  -->
      <ModalAddEdit ref="ModalAddEdit" pntMethodName="pntMethod" @pntMethod="GetStaff"></ModalAddEdit>
      <!-- ロック時の編集不可ダイアログ -->
      <v-snackbar
        v-model="lockMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        ロック中のため、編集できません。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="lockMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <!-- 編集失敗ダイアログ -->
      <v-snackbar
        v-model="errMsgBar"
        color="error"
        timeout="6000"
        bottom
      >
        編集に失敗しました。
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            color=""
            @click.native="errMsgBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ModalAddEdit from '@/components/ModalAddEdit.vue';
import BtnAdd from '@/components/button/add.vue';
import BtnEdit from '@/components/button/edit.vue';
import BtnDel from '@/components/button/del.vue';
import TopDetail from '@/components/TopDetail.vue';
import ContractOver from '@/components/ContractOver.vue';
import draggable from 'vuedraggable';
import axios from 'axios';

export default {
  name: 'Client',
  components:{
    Header,
    ModalAddEdit,
    TopDetail,
    ContractOver,
    BtnAdd,
    BtnEdit,
    BtnDel,
    draggable,
  },

  // サインインチェック
  beforeMount() {
    // if(this.$route.query.auth !== 'authenticated'){
    //   this.$route.push({ name: 'Login', query: { next: 'Client' } });
    // }
  },

  data() {
    return {
      items: [],
      selectedStaff: 0,
      dateY: "",
      optStaff:[
        // {id: 0, text: "高屋" },
        // {id: 1, text: "阿部" },
        // {id: 2, text: "矢倉" },
      ],
      // 所属プルダウン用
      selectedRegion: 0,
      optRegion: [
        { id: 0, text: "全ての所属" },
        { id: 1, text: "京都" },
        { id: 2, text: "東京" },
        { id: 3, text: "福岡" },
      ],

      // 0：追加, 1：編集
      addEditMode: 0,
      // 削除用
      assign_cstm_id: null,
      task_assign_id: null,

      // クリア設定
      clear: {
        "dragGroup": "",
        "abbreviation": "",
        "company": ""
      },

      sendAddEdit: {
        date: "",
        time: "",
        id: "",
        no: "",
        abbreviation: null,
        tentativeRegist: null,
        place: "",
        workContent: "",
        task: "",
      },
      isHandle: true,
      // コピー用
      lastSel: null,
      copySel: null,
      copyItem: null,
      
      // ボタン有効無効制御用
      isReadOnly:true,

      // ロック時の編集不可ダイアログ
      lockMsgBar: false,
      // 編集失敗ダイアログ
      errMsgBar: false,
      //
      showDrag: false,
    }
  },
  async mounted() {
    // 年設定
    var date = new Date();
    this.dateY = date.getFullYear();

    // if(this.selectedStaff == 0){
    //     this.selectedStaff = 1;
    // }

    this.GetUsers();

    // 画面遷移した際にスタッフが選択されていれば一覧表示
    if (this.$store.state.staff){
      this.GetStaff();
    } else {
      this.GetLocked();
    }

    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy: function(){
    // イベントをクリア
    document.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    GetUsers: async function(){
      // スタッフプルダウン生成用
      // データ取得
      await axios.post(this.URL +'GetUserList', {
          staffId: this.$store.state.user.id,
          token: this.$store.state.token,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        // ログインチェック
        // this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        console.log(res);

        this.optStaff = res.data.data;
        // 所属全選択以外の場合
        if(this.selectedRegion != 0){
          // 選択している所属のデータのみ抽出
          let tmpStaffs = []
          let selRegion = this.selectedRegion
          this.optStaff.forEach(function(staff){
            if(staff.region_id == selRegion){
              tmpStaffs.push(staff)
            }
          })
          this.optStaff.splice(0,this.optStaff.length)
          this.optStaff = tmpStaffs
        }
        console.log(this.optStaff);
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    GetLocked: async function(){
      // ロック判定
      await axios.post(this.URL +'GetLocked', {
        token: this.$store.state.token,
      },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(function(res){
        if (res.data.ret == true) {
          // ロック有無フラグ取得
          this.locked = res.data.locked;
          console.log("locked = " + this.locked);
          this.$store.dispatch("updateLocked", {
            locked: this.locked,
          });
          console.log("this.$store.state.locked = " + this.$store.state.locked);
        }
      }.bind(this))
      .catch(function(error){
          console.log(error);
      });
    },
    // 一覧取得
    GetStaff: async function(){
      console.log("GetStaff");

      // ロック判定
      this.GetLocked();

      this.selectedStaff = this.$store.state.staff;
      this.$store.dispatch("selStaffClient", {
        staff: this.$store.state.staff,
        client: "",
        date: "",
      });

      this.$refs.ContractOver.GetContractOver();
      // データ取得
      console.log("dateY:"+ this.dateY);
      console.log("selectedStaff:"+ this.selectedStaff);

      await axios.post(this.URL +'GetStaff', {
          dateY: this.dateY,
          selectedStaff: this.selectedStaff,
          token: this.$store.state.token,
        },
        { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
      )
      .then(async function(res){
        // ログインチェック
        this._loginChk(res.data, this.$store.state.token);

        //デバッグ用にconsoleに出力
        // console.log(res);
        this.items = res.data.data;

        console.log(this.$store.state.user.authority);

        if(!this._ChkAuthority()){
          this.isHandle = false;
        }

        console.log("items");
        console.log(this.items);
        
        // dragGroupに代入（ドラッグできる個所を設定）
        var self = this;
        this.items.dayList.forEach(function(elm){
          for(var i=0; i<elm.monthList.length; i++){
            if(elm.monthList[i].targetDate == ""){
              elm.monthList[i].no1.dragGroup = "fixed";
              elm.monthList[i].no2.dragGroup = "fixed";
              elm.monthList[i].no3.dragGroup = "fixed";
            } else {
              elm.monthList[i].no1.dragGroup = elm.monthList[i].no1.assign_cstm_id || elm.monthList[i].no1.task_assign_id ? "fixed" : "noFixed";
              elm.monthList[i].no2.dragGroup = elm.monthList[i].no2.assign_cstm_id || elm.monthList[i].no2.task_assign_id ? "fixed" : "noFixed";
              elm.monthList[i].no3.dragGroup = elm.monthList[i].no3.assign_cstm_id || elm.monthList[i].no3.task_assign_id ? "fixed" : "noFixed";
            }
            self.$set(elm.monthList[i].no1, 'isSelected', false);
            self.$set(elm.monthList[i].no2, 'isSelected', false);
            self.$set(elm.monthList[i].no3, 'isSelected', false);
            self.$set(elm.monthList[i].no1, 'isCopySelected', false);
            self.$set(elm.monthList[i].no2, 'isCopySelected', false);
            self.$set(elm.monthList[i].no3, 'isCopySelected', false);
          }
        });
        console.log("items_after");
        console.log(this.items);
        this.setSelData();
        this.showDrag = false;
        await this.$nextTick();
        this.showDrag = true;
        return true;
      }.bind(this))
      .catch(function(error){
        console.log(error);
        return false;
      });
    },
    chgYear: function(num){
      this.dateY = this.dateY + num;
      this.lastSel=null;
      this.copySel=null;
      // 編集データをリセット
      this.sendAddEdit = {
        date: "",
        id: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },
      this.copyItem=null;
      this.GetStaff();
    },
    add: function(){
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 1){ return false; }
      
      console.log("add");
      this.$set(this.$refs.ModalAddEdit, "mode", "add");
      this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
      this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
      this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
      this.$set(this.$refs.ModalAddEdit.item, "no", 0);
      this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", this.sendAddEdit.temp_flg);
      this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
      this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
      this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
      this.$set(this.$refs.ModalAddEdit.item, "kind", parseInt(this.sendAddEdit.kind));
      this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
      this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", this.sendAddEdit.task_assign_id);
      this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
      // エラーをリセット
      this.$set(this.$refs.ModalAddEdit.item, "errors", []);
      // this.$refs.ModalAddEdit.$refs.form.resetValidation();
      // モーダルウィンドウ表示
      this.$refs.ModalAddEdit.modalShow = true;
    },
    edit: function(){
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 0){ return false; }

      console.log("edit");
      // console.log(Object.keys(objDt).length);
      if(this.sendAddEdit.date){
        console.log("sendAddEdit.cstm_id:"+ this.sendAddEdit.cstm_id);

        // 実行
        let sendTempFlg = false
        if (this.sendAddEdit.temp_flg == 1) sendTempFlg = true
        this.$set(this.$refs.ModalAddEdit, "mode", "edit");
        this.$set(this.$refs.ModalAddEdit.item, "date", this.sendAddEdit.date);
        this.$set(this.$refs.ModalAddEdit.item, "time", this.sendAddEdit.assign_time);
        this.$set(this.$refs.ModalAddEdit.item, "id", this.sendAddEdit.user_id);
        this.$set(this.$refs.ModalAddEdit.item, "no", this.sendAddEdit.koma);
        this.$set(this.$refs.ModalAddEdit.item, "abbreviation", this.sendAddEdit.cstm_id);
        this.$set(this.$refs.ModalAddEdit.item, "tentativeRegist", sendTempFlg);
        this.$set(this.$refs.ModalAddEdit.item, "place", this.sendAddEdit.location);
        this.$set(this.$refs.ModalAddEdit.item, "workContent", this.sendAddEdit.work_text);
        this.$set(this.$refs.ModalAddEdit.item, "task", this.sendAddEdit.free_task);
        this.$set(this.$refs.ModalAddEdit.item, "kind", parseInt(this.sendAddEdit.kind));
        this.$set(this.$refs.ModalAddEdit.item, "assign_cstm_id", this.sendAddEdit.assign_cstm_id);
        this.$set(this.$refs.ModalAddEdit.item, "task_assign_id", this.sendAddEdit.task_assign_id);
        this.$refs.ModalAddEdit.orgDate = this.sendAddEdit.date;
        // エラーをリセット
        this.$set(this.$refs.ModalAddEdit.item, "errors", []);
        // モーダルウィンドウ表示
        this.$refs.ModalAddEdit.modalShow = true;
      }
    },
    del: function(){
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id)){ return false; }
      if(this.addEditMode == 0){ return false; }

      console.log("del");
      console.log("assign_cstm_id:"+ this.sendAddEdit.assign_cstm_id);
      console.log("task_assign_id:"+ this.sendAddEdit.task_assign_id);
      axios
        .post(this.URL +'DelPlan', {
            mode: "del",
            assign_cstm_id: this.sendAddEdit.assign_cstm_id,
            task_assign_id: parseInt(this.sendAddEdit.task_assign_id),
            token: this.$store.state.token,
          },
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // 成功処理
            console.log("OK");
            // 再読み込み
            this.GetStaff();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログ表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
          }
        }
      );
    },
    chgStaff: function(){
      console.log(this.selectedStaff);
      this.$store.state.staff = this.selectedStaff;
      this.lastSel=null;
      this.copySel=null;
      // 編集データをリセット
      this.sendAddEdit = {
        date: "",
        id: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },
      this.copyItem=null;
      this.GetStaff();
      // ボタン有効無効状態更新
      this.isReadOnly = true
    },

    // 所属絞り込み変更
    chgRegion: async function(){
      // スタッフプルダウン更新
      await this.GetUsers();
      // 更新後のスタッフプルダウン内に選択していたスタッフが存在するか確認
      let find = false
      for(let i = 0;i< this.optStaff.length;i++){
        if(this.optStaff[i].id == this.selectedStaff){
          find = true
          break
        }
      }
      // 選択していたスタッフがプルダウン内に存在しない場合
      if(find == false){
        // 未選択状態へ変更
        this.selectedStaff = 0;
        this.chgStaff()
      }
    },
    // ドラッグ系
    onTgtClick: function(item, detail, tgt){
      if(!this.chkStaffLeaderAuthority(this.items.id)){ return false; }

      console.log("ontgtClick");
      console.log(item);

      // 存在しない日付のセルをクリックした場合、選択しない
      if(detail.targetDate == "") return

      var self = this;
      this.items.dayList.map(function(day){
        day.monthList.map(function(elm){
          self.$set(elm.no1, 'isSelected', false);
          self.$set(elm.no2, 'isSelected', false);
          self.$set(elm.no3, 'isSelected', false);
        });
      });
      this.$set(detail[tgt], 'isSelected', true);
      // コピー機能用に記憶する
      this.lastSel = detail[tgt];
      
      this.addEditMode = detail[tgt].dragGroup == "noFixed" ? 0 : 1;

      this.sendAddEdit.date = detail.targetDate;
      this.sendAddEdit.assign_time = detail[tgt].time;
      this.sendAddEdit.user_id = this.items.id;
      this.sendAddEdit.kind = detail[tgt].kind;
      this.sendAddEdit.cstm_id = detail[tgt].cstm_id;
      this.sendAddEdit.location = detail[tgt].client_visit;
      this.sendAddEdit.temp_flg = detail[tgt].tentative_regist;
      this.sendAddEdit.koma = parseInt(tgt.split("no")[1]);
      this.sendAddEdit.free_task = detail[tgt].task_full;
      this.sendAddEdit.work_text = detail[tgt].work_text;

      this.sendAddEdit.assign_cstm_id = detail[tgt].assign_cstm_id;
      this.sendAddEdit.task_assign_id = detail[tgt].task_assign_id;
      
      console.log("sendAddEdit");
      console.log(this.sendAddEdit);

      this.$store.dispatch("selStaffClient", {
        staff: this.items.id,
        client: detail[tgt].cstm_id,
        date: detail.targetDate,
      });
      // ボタン有効無効状態更新
      this.chgIsReadOnly(!this.chkStaffLeaderAuthority(this.selectedStaff))
    },
    onDragStart: function(item, $event){
      if(!this.chkStaffLeaderAuthority(this.items.id)){ return false; }

      console.log("start");
      // console.log(index);
      // console.log(item);
      // console.log($event);

      this.$set(item.monthList[$event.from.dataset.field_idx][$event.from.dataset.no], "dragGroup", "noFixed");
      // this.$set(item[0]["no1"], "dragGroup", "noFixed");
    },
    onDragEnd: function(index, item, $event){
      // console.log("end");
      console.log(index);
      console.log(item);
      console.log($event);

      // 同じ場所以外にDragした場合に実行
      if($event.from.id != $event.to.id){
        // 移動後へ反映
        // ディープコピー
        // var deepCopy = JSON.parse(JSON.stringify(item[$event.from.dataset.field_idx][$event.target.dataset.no]));
        // console.log(deepCopy);
        // this.$set(this.items.dayList[$event.to.dataset.item_idx][$event.to.dataset.field_idx], $event.to.dataset.no, deepCopy);

        // 移動前の値を削除、dragGroupをnoFixedにする
        // console.log($event.to.dataset.item_idx, $event.to.dataset.field_idx, $event.to.dataset.no);
        // console.log($event.from.dataset.item_idx, $event.from.dataset.field_idx, $event.from.dataset.no);
        // this.$set(item[$event.from.dataset.field_idx], $event.from.dataset.no, this.clear);
        // this.$set(item[$event.from.dataset.field_idx][$event.from.dataset.no], "dragGroup", "noFixed");

        // console.log("fromAssignCstmId:"+ this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].assign_cstm_id);
        // console.log("fromTaskAssignId:"+ this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].task_assign_id);
        // console.log("fromKoma:"+ $event.from.dataset.no.split("no")[1]);
        // console.log("fromDate:"+ this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx].targetDate);
        // console.log("toKoma:"+ $event.to.dataset.no.split("no")[1]);
        // console.log("toDate:"+ this.items.dayList[$event.to.dataset.item_idx].monthList[$event.to.dataset.field_idx].targetDate);

        var dSendData = {
          token: this.$store.state.token,
          userId: this.$store.state.user.id,
          from: {
            drag_user_id: this.items.id,
            cstm_id: this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].cstm_id,
            assign_cstm_id: this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].assign_cstm_id,
            task_assign_id: parseInt(this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].task_assign_id),
            koma: parseInt($event.from.dataset.no.split("no")[1]),
            date: this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx].targetDate,
            temp_flg: parseInt(this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].tentative_regist),
            work_text: this.items.dayList[$event.from.dataset.item_idx].monthList[$event.from.dataset.field_idx][$event.from.dataset.no].work_text,
          },
          to: {
            drag_user_id: this.items.id,
            koma: parseInt($event.to.dataset.no.split("no")[1]),
            date: this.items.dayList[$event.to.dataset.item_idx].monthList[$event.to.dataset.field_idx].targetDate,
          },
        }

        console.log(dSendData);

        // 実行
        axios
          .post(this.URL +'DragDrop', dSendData,
            { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
          )
          .then((res) => {
            console.log(res);
            if(res.data.ret){
              // 成功処理
              console.log("OK");
              // 編集データをリセット
              this.sendAddEdit = {
                date: "",
                id: "",
                no: "",
                abbreviation: "",
                tentativeRegist: "",
                place: "",
                workContent: "",
                task: "",
              },
              this.$store.dispatch("selStaffClient", {
                staff: this.$store.state.staff,
                client: "",
                date: "",
              });
              // 再描画
              this.GetStaff();
            } else {
              // エラー処理
              console.log("NG");
               if(res.data.locked == 1){
                // ロック中の場合、編集不可ダイアログ表示
                this.lockMsgBar = true;
              }
              else if(res.data.errCode == 2){
                alert("既にデータが存在するコマへは移動できません");
                this.items.splice(0, this.items.length);
              }
              else{
                // 編集失敗ダイアログ表示
                this.errMsgBar = true;
              }
              this.GetStaff();
              // トークンエラーチェック
              this._chkToken(res.data.errToken);
            }
          }
        );
      }

      // 移動後のにする
      // ドラッグ後の場所を固定するためdragGroupをfixedに設定 = 他の要素からドラッグされないために設定
      // console.log($event.to.dataset.item_idx, $event.to.dataset.field_idx, $event.to.dataset.no);
      // this.$set(this.items.dayList[$event.to.dataset.item_idx][$event.to.dataset.field_idx][$event.to.dataset.no], "dragGroup", "fixed");
      // this.$set(this.items[$event.to.dataset.field_idx].fields[$event.to.dataset.item_idx][$event.to.dataset.no], "dragGroup", "fixed");
      // this.$set(this.items[0].fields[0][$event.to.dataset.no], "dragGroup", "fixed");

      // // this.$forceUpdate();
      // console.log(this.items);
    },
        // コピーボタン押下処理
    Copy: function(){
      console.log("Copy");
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id))return
      if(this.lastSel == null) return;

      if(this.sendAddEdit.kind == 0)
      {
        console.log("データなし");
        return;
      }

      // 選択中データをコピーデータとして記憶
      this.copyItem = null;
      this.copyItem = JSON.parse(JSON.stringify(this.sendAddEdit))

      // コピー選択項目枠線を描画
      this.$set(this.lastSel, 'isCopySelected', true);
      this.copySel = this.lastSel;
    },
    // 貼付ボタン押下処理
    Paste: function(){
      console.log("Paste");
      if(!this.chkStaffLeaderAuthority(this.sendAddEdit.user_id))return
      if(this.copyItem == null) return

      if(this.sendAddEdit.kind != 0)
      {
        console.log("データがあるため貼付禁止");
        return;
      }

      // コピーデータと選択中位置のデータが違う場合のみ貼付実行
      if(this.copyItem != this.sendAddEdit)
      {
        let sendTempFlg = false
        if (this.copyItem.temp_flg == 1) sendTempFlg = true
        // データ変更
        var dsendData = {
        mode: "add",
        token: this.$store.state.token,
        // 入力データ
        target_date: this.sendAddEdit.date,
        user_id: this.sendAddEdit.user_id,
        assign_time: this.copyItem.assign_time,
        kind: parseInt(this.copyItem.kind),
        cstm_id: this.copyItem.cstm_id,
        location: this.copyItem.location,
        temp_flg: sendTempFlg,
        koma: this.sendAddEdit.koma,
        free_task: this.copyItem.free_task,
        work_text: this.copyItem.work_text,
        assign_cstm_id: this.copyItem.assign_cstm_id,
        task_assign_id: this.copyItem.task_assign_id,
        org_date: this.copyItem.date
      }
      console.log("dsendData");
      console.log(dsendData);
      axios
        .post(this.URL +'EditPlan', dsendData,
          { headers: {'Content-Type': 'application/x-www-form-urlencoded'} }
        )
        .then((res) => {
          console.log(res);
          if(res.data.ret){
            // // 成功処理
            this.copyItem=null;
            // 再描画
            this.GetStaff();
          } else {
            // エラー処理
            console.log("NG");
            if(res.data.locked == 1){
              // ロック中の場合、編集不可ダイアログ表示
              this.lockMsgBar = true;
            }
            else{
              // 編集失敗ダイアログ表示
              this.errMsgBar = true;
            }
            if(res.data.errMsg){ this.errors.push(res.data.errMsg); }
            // トークンエラーチェック
            this._chkToken(res.data.errToken);
          }
        }
      );
      }
    },
    // スタッフ、リーダー編集OKか
    chkStaffLeaderAuthority: function(user_id){
      let ret = true
      // 権限がリーダーの場合
      if (this.$store.state.user.authority==2)
      {
        // 対象の所属ID取得
        let targetRegionId = 0
          for(let i = 0;i< this.optStaff.length;i++)
          {
            if(user_id == this.optStaff[i].id){
              targetRegionId = this.optStaff[i].region_id
              break
            }
          }
        // ログインユーザの所属と対象の所属が不一致の場合Falseを返す
        if(this.$store.state.user.region_id != targetRegionId){
          ret = false
        }
      }
      // 権限がスタッフの場合
      if ((this.$store.state.user.authority==3) && (this.$store.state.user.id != user_id))
      {
        ret = false
      }
      return ret;
    },
    onKeyDown (e) {
      console.log("onKeyDown")
      console.log(e.key)
      console.log(e.code)

      if (e.code == 'KeyC' && e.ctrlKey) {
        console.log("コピー")
        this.Copy()
      }
      else if (e.code == 'KeyV' && e.ctrlKey) {
        console.log("貼付")
        this.Paste()
      }
      else {
        switch(e.key)
        {
          case 'ArrowUp':
          case 'ArrowDown':
          case 'ArrowRight':
          case 'ArrowLeft': 
            e.preventDefault();
          break;
          default: return;
        }
        var self = this;
        if(this.lastSel == null) {
            
          const data = this.items.dayList[0].monthList[0];
          if(data == null) return
          this.items.dayList.map(function(day){
            day.monthList.map(function(elm){
              self.$set(elm.no1, 'isSelected', false);
              self.$set(elm.no2, 'isSelected', false);
              self.$set(elm.no3, 'isSelected', false);
            });
          });

          this.$set(data['no1'], 'isSelected', true);
          // コピー機能用に記憶する
          this.lastSel = data['no1'];
          
          this.addEditMode = data['no1'].dragGroup == "noFixed" ? 0 : 1;

          // 編集データを取得
          this.sendAddEdit.date = data.targetDate;
          this.sendAddEdit.assign_time = data['no1'].time;
          this.sendAddEdit.user_id = this.items.id;
          this.sendAddEdit.kind = data['no1'].kind;
          this.sendAddEdit.cstm_id = data['no1'].cstm_id;
          this.sendAddEdit.location = data['no1'].client_visit;
          this.sendAddEdit.temp_flg = data['no1'].tentative_regist;
          this.sendAddEdit.koma = parseInt('no1'.split("no")[1]);
          this.sendAddEdit.free_task = data['no1'].task_full;
          this.sendAddEdit.work_text = data['no1'].work_text;
          this.sendAddEdit.assign_cstm_id = data['no1'].assign_cstm_id;
          this.sendAddEdit.task_assign_id = data['no1'].task_assign_id;

          this.$store.dispatch("selStaffClient", {
            staff: this.items.id,
            client: data['no1'].cstm_id,
            date: data.targetDate,
          });
          return
        }
        else
        {
          console.log("位置変更")
          for (let i=0; i<this.items.dayList.length; i++) {
            for (let j=0; j<this.items.dayList[i].monthList.length; j++) {
              if (this.items.dayList[i].monthList[j].targetDate == this.sendAddEdit.date) {
                var mon=j, day=i;
                var koma = 1;
                if (this.items.dayList[i].monthList[j]['no1'].isSelected == true) koma = 1
                if (this.items.dayList[i].monthList[j]['no2'].isSelected == true) koma = 2
                if (this.items.dayList[i].monthList[j]['no3'].isSelected == true) koma = 3
                if (e.key == 'ArrowUp') {
                  if (koma <=1) {
                    if (day<=0) {
                      return;
                    }
                    else {
                      day--;
                    }
                    koma=3;
                  }
                  else {
                    koma--;
                  }
                }
                else if (e.key == 'ArrowDown') {
                  if (3 <= koma) {
                    if ((this.items.dayList.length-1) <= i) {
                      return;
                    }
                    else {
                      day++;
                    }
                    koma=1;
                    // 移動先が存在しない日付の場合、移動しない
                    if(this.items.dayList[day].monthList[mon].targetDate == "") return;
                  }
                  else {
                    koma++;
                  }
                }
                else if (e.key == 'ArrowRight') {
                  if ((this.items.dayList[i].monthList.length-1) <= mon) {
                    return;
                  }
                  else {
                    mon++;
                    // 移動先が存在しない日付の場合、スキップ
                    for(mon; mon<this.items.dayList[i].monthList.length; mon++) {
                      if(this.items.dayList[day].monthList[mon].targetDate != "") break;
                      if(mon+1 >= this.items.dayList[i].monthList.length) return;
                    }
                  }
                }
                else if (e.key == 'ArrowLeft') {
                  if (mon<=0) {
                    return;
                  }
                  else {
                    mon--;
                    // 移動先が存在しない日付の場合、スキップ
                    for(mon; mon >= 0; mon--) {
                      if(this.items.dayList[day].monthList[mon].targetDate != "") break;
                      if(mon-1 < 0) return;
                    }
                  }
                }
                else {
                  return;
                }
                const data = this.items.dayList[day].monthList[mon];
                if(data == null) return
                this.items.dayList.map(function(day){
                  day.monthList.map(function(elm){
                    self.$set(elm.no1, 'isSelected', false);
                    self.$set(elm.no2, 'isSelected', false);
                    self.$set(elm.no3, 'isSelected', false);
                  });
                });

                this.$set(data['no' + String(koma)], 'isSelected', true);
                // コピー機能用に記憶する
                this.lastSel = data['no' + koma];
                
                this.addEditMode = data['no' + koma].dragGroup == "noFixed" ? 0 : 1;

                // 編集データを取得
                this.sendAddEdit.date = data.targetDate;
                this.sendAddEdit.assign_time = data['no' + koma].time;
                this.sendAddEdit.user_id = this.items.id;
                this.sendAddEdit.kind = data['no' + koma].kind;
                this.sendAddEdit.cstm_id = data['no' + koma].cstm_id;
                this.sendAddEdit.location = data['no' + koma].client_visit;
                this.sendAddEdit.temp_flg = data['no' + koma].tentative_regist;
                this.sendAddEdit.koma = koma;
                this.sendAddEdit.free_task = data['no' + koma].task_full;
                this.sendAddEdit.work_text = data['no' + koma].work_text;
                this.sendAddEdit.assign_cstm_id = data['no' + koma].assign_cstm_id;
                this.sendAddEdit.task_assign_id = data['no' + koma].task_assign_id;

                this.$store.dispatch("selStaffClient", {
                  staff: this.items.id,
                  client: data['no' + koma].cstm_id,
                  date: data.targetDate,
                });

                this.selectCellScroll()
                return
              }
            }
          }
        }
      }
    },
    // 選択中データの枠線絵画
    setSelData:function()
    {
      console.log("setSelData")
      console.log(this.sendAddEdit.date)
      if (this.sendAddEdit.date) {
        console.log("if (this.sendAddEdit.date) {")
        for (let i=0; i<this.items.dayList.length; i++) {
          for (let j=0; j<this.items.dayList[i].monthList.length; j++) {
            if (this.items.dayList[i].monthList[j].targetDate == this.sendAddEdit.date) {

              var koma = this.sendAddEdit.koma;

              const data = this.items.dayList[i].monthList[j];
              if(data == null) return
              var self = this;
              this.items.dayList.map(function(day){
                day.monthList.map(function(elm){
                  self.$set(elm.no1, 'isSelected', false);
                  self.$set(elm.no2, 'isSelected', false);
                  self.$set(elm.no3, 'isSelected', false);
                });
              });
              this.$set(data['no' + String(koma)], 'isSelected', true);                
              this.addEditMode = data['no' + koma].dragGroup == "noFixed" ? 0 : 1;

              // DBデータが更新されている場合があるため入れ直し
              this.sendAddEdit.date = data.targetDate;
              this.sendAddEdit.assign_time = data['no' + koma].time;
              this.sendAddEdit.user_id = this.items.id;
              this.sendAddEdit.kind = data['no' + koma].kind;
              this.sendAddEdit.cstm_id = data['no' + koma].cstm_id;
              this.sendAddEdit.location = data['no' + koma].client_visit;
              this.sendAddEdit.temp_flg = data['no' + koma].tentative_regist;
              this.sendAddEdit.koma = koma;
              this.sendAddEdit.free_task = data['no' + koma].task_full;
              this.sendAddEdit.work_text = data['no' + koma].work_text;
              this.sendAddEdit.assign_cstm_id = data['no' + koma].assign_cstm_id;
              this.sendAddEdit.task_assign_id = data['no' + koma].task_assign_id;
              
              this.$store.dispatch("selStaffClient", {
                staff: this.items.id,
                client: data['no' + koma].cstm_id,
                date: data.targetDate,
              });
              return
            }
          }
        }
      }
      // 編集データをリセット
      this.sendAddEdit = {
        date: "",
        id: "",
        no: "",
        abbreviation: "",
        tentativeRegist: "",
        place: "",
        workContent: "",
        task: "",
      },
      this.$store.dispatch("selStaffClient", {
        staff: this.$store.state.staff,
        client: "",
        date: "",
      });
      console.log("選択中データなし");
    },
    // 領域外へ選択セルがはみ出している場合スクロール
    selectCellScroll: function()
    {
      const fieldList = document.getElementsByClassName("fieldList");  // スクロールバー
      const scrollRect = fieldList[0].getBoundingClientRect()

      let cell  // 選択セル
      let selectRect; // 選択セル矩形情報
      // 移動先セル取得
      for (let i=0; i<this.items.dayList.length; i++) {
        for (let j=0; j<this.items.dayList[i].monthList.length; j++) {
          if(this.items.dayList[i].monthList[j].targetDate == this.sendAddEdit.date){
            cell =document.getElementById('td_'+ i +'_field'+ j +'_no' + this.sendAddEdit.koma)
            selectRect = cell.getBoundingClientRect();
          }
        }
      }
      
      // svg領域外(上)の場合のみ処理
      if(selectRect.top < scrollRect.top + selectRect.height)
      {
        // 上へスクロール
        fieldList[0].scrollTop -=  (scrollRect.top + selectRect.height) - selectRect.top;
      }
      // svg領域外(下)の場合のみ処理
      if(selectRect.bottom  > scrollRect.bottom - (scrollRect.height - fieldList[0].clientHeight))
      {
        // 下へスクロール
        fieldList[0].scrollTop += selectRect.bottom - (scrollRect.bottom - (scrollRect.height - fieldList[0].clientHeight));
      }
    },
    chgIsReadOnly: function(disabled) {
      // ボタン有効無効更新
      this.isReadOnly = disabled || this.sendAddEdit == null;
    },
  },
  // components: {
  //   Login
  // }
}
</script>

<style scoped>
  #control ul > li{
    padding: 2px 4px;
  }
  #control ul > li:first-child{
    padding-left: 0 !important;
  }
  #control ul > li:last-child{
    padding-right: 0 !important;
  }

  #control > div:nth-child(2) {
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 7px;
  }
  #control .boldfont
  {
    font-weight: 1000;
  }
  #field div.fieldList{
    overflow: scroll;
    height: 73vh;
    margin-top: 0.5em;
  }
  #field table{
    width: 100%;
    /* min-width: 100%; */
    table-layout: fixed;
    border-collapse: collapse;
    box-sizing: border-box;
    border-collapse: separate;
    border-top: 1px solid #ccc;
  }
  /* #field table colgroup col:nth-child(1){ width: 4.0em; } */
  /* #field table colgroup col{ width: calc(100% / 13); } */
  #field table colgroup col{ width: 100px; }
  #field table colgroup col:nth-child(1){ width: 50px; }

  /* #field table tr th:nth-child(1),
  #field table tr td:nth-child(1)
  {
    /* text-align: left; */
  /* } */
  #field table th{
    background-color: #BBDEFB !important;
    font-weight: 900;
    position: sticky;
    top: 0;
  }
  #field table th,
  #field table td
  {
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
    /* border: 1px solid #ccc; */
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 3px;
  }
  #field table thead tr th:nth-child(1),
  #field table tbody tr th:nth-child(1)
  {
    border-left: 1px solid #ccc;
  }
  #field table tbody td{
    /* min-width: 20px; */
    height: 31px;
  }
  #field .handle:hover{
    cursor: grab;
  }
  #field .handle:active{
    cursor: grabbing;
  }
  #field .sortable-chosen {
    background-color: #4caf50 !important;
  }

  /* #field table td > div{
    height: 1.0em;
  } */

  .noDate{
    background-color: #eee;
  }

  .tentative_regist{
    color: #00f;
  }
  .year_select{
    margin-bottom: -23px;
  }
  .selStaff{
    width: 230px;
  }
  .selRegion{
    width: 200px;
  }
</style>
