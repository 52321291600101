<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="showDetail"
      >
        {{ showDetailSetting }}
      </span>
    </template>
    <div class="showTooltip">{{ showPlaceSetting }}</div>
  </v-tooltip>
</template>

<script>
export default {
  name: "ClientDetail",
  props: {
    pntItem: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    showDetailSetting: function(){
      var ret = this.pntItem.abbreviation;
      if(this.pntItem.client_visit == 2){
        ret = ret.toLowerCase();
      }
      return ret;
    },
    showPlaceSetting: function(){
      var ret = this.pntItem.fullname;
      if(this.pntItem.client_visit == 2){
        ret = ret.toLowerCase();
      }
      return ret;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.showTooltip{
  white-space: pre-wrap;
}
.showDetail{
  /* font-size: 75%; */
  display: block;
  padding: 1px 0;
}
</style>
